<template>
    <div class="bg-image--6 d-flex flex-column justify-content-center" style="height:auto; padding:130px 0;">
        <div style="background-color:rgba(255 ,255,255,0.8);width:250px; padding: 20px 0;">
            <h2 style="color:#b71c1c;" class="pl-3 text-center text-uppercase">Shopping</h2>
        </div>
    </div>
</template>
<style scoped>
@media (max-width: 575.98px) {
    .bg-image--6{
        padding : 50px 0;
    }
}
</style>

