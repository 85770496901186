<template>
  <div>
    <!-- Choix du mode -->

    <page-choice-mode-component v-if="step == 1" @go-to-next="goToNext">
    </page-choice-mode-component>

    <!-- Fin du Choix du mode -->

    <!-- Mode Design -->

    <message-builder-component
      v-if="step == 2"
      :channel="channel"
      @return-to-page-choice="returnToSitewebMaker"
      @return-to-design-maker="returnToDesignMaker"
      @return-to-channel-choice="returnToChannelChoice"
      @go-to-design-maker="goToDesignMaker"
      @go-to-target-choice="goToTargetChoice"
    />
    <design-maker-component
      v-if="step == 3"
      :message="message"
      @return-to-message-builder="returnToMessageBuilder"
      @go-to-target-choice="goToTargetChoice"
      @go-to-message-summary="goToMessageSummary"
      @go-to-message-builder="goToMessageBuilder"
      @go-to-channel-choice="goToChannelChoice"
    />

    <!-- Fin du Mode Design -->

    <!-- Mode Site Web -->

    <site-web-choice-component
      v-if="step == 2.5"
      @return-to-choice-mode="returnToChoiceMode"
      @go-to-site-web-maker="goToSitewebMaker"
    />
    <site-web-maker-component
      v-if="step == 3.5"
      :caracteristic_user_template="caracteristic_user_template"
      @return-to-page-choice="returnToSitewebChoice"
      @go-to-view-site-web="goToSitewebViewer"
      :modelTemplate="modelTemplate"
      :configTemplate="configTemplate"
      :nor="nav"
    />
    <site-web-viewer-component
      v-if="step == 4.5"
      :caracteristic_user_template="caracteristic_user_template"
      :configTemplate="configTemplate"
      :nav="nav"
      :modelTemplate="modelTemplate"
      @return-to-page-choice="returnToSitewebMaker"
      @go-to-channel-choice="goToChannelChoice"
      @save-carac="saveCarac"
      viewer="true"
      isMaking="true"
    />

    <!-- Fin mode site web -->


    <channel-choice-component
      v-if="step == 10"
      :message="message"
      @go-to-payment="goToPayment"
      @return-to-site-web-viewer="returnToSitewebViewer"
      @go-to-target-choice="goToTargetChoice"
    />

    <target-choice-component
      v-if="step == 11"
      :message="message"
      @return-to-design-maker="returnToDesignMaker"
      @return-to-message-builder="returnToMessageBuilder"
      @go-to-message-summary="goToMessageSummary"
      @return-to-channel-choice="returnToChannelChoice"
      @go-to-payment="goToPayment"
    />


    <!-- Mode E-Card Summary -->

    <message-summary-component
      v-if="step == 4"
      :message="message"
      :targets="targets"
      @return-to-target-choice="returnToTargetChoice"
      @go-to-target-choice="goToTargetChoice"
      @go-to-channel-choice="goToChannelChoice"
      @go-to-payment="goToPayment"
    />

    <!-- Fin Mode E-Card Summary -->


    <payment-component
      v-if="step == 12"
      :message="message"
      @return-to-message-summary="returnToMessageSummary"
      @message-sent="messageSent"
    />
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import { URL_SITE_WEB } from "@/config";
export default {
  name: "Create",
  setup() {
    useMeta({
      title: "Création d’une Joy Card, Faire-Parts Numériques de Mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Commander des faire-parts en Côte d’Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, commander des faire-parts en Côte d’Ivoire, prestataire de mariage, comment choisir le design de ses faire-parts, envoyer ses faire-parts par mail, faire-parts digital, invitation de mariage",
        },
      ],
    });
  },

  data: function () {
    return {
      step: 1,
      channel: null,
      message: null,
      customerChannel: null,
      targets: null,
      messageSend: {},
      modelTemplate: {},
      mode: {},
      nav: 1,
      configTemplate: null,
      caracteristic_user_template: {
        category:null,
        photo_celebrations: [],
        photo_story: [],
        photo_brides_maids: [],
        photo_fiance: "",
        photo_fiancee: "",
        photo_fiances: [],
        man: null,
        woman: null,
        story: null,
        // celebrations_1: {
        //   name: "",
        //   date: [],
        //   place: "",
        // },
        // celebrations_2: {
        //   name: "",
        //   date: [],
        //   place: "",
        //   time: "",
        // },
        // celebrations_3: {
        //   name: "",
        //   date: [],
        //   place: "",
        //   time: "",
        // },
        albumphotos: [],
        items: [0, 1, 2, 3, 4],
        dateComponents: {},
        thoughts_husband: null,
        thoughts_wife: null,
        images: [],
      },
    };
  },
  mounted() {},
  methods: {
    returnToSitewebViewer:function(){
      let app = this;
      app.step = 4.5
    },
    returnToSitewebChoice: function () {
      let app = this;
      app.step = 2.5;
    },
    returnToSitewebMaker: function () {
      let app = this;
      app.step = 3.5;
      this.$emit("site-web-viewer-end", false);
    },
    returnToChoiceMode: function () {
      let app = this;
      app.step = 1;
    },
    goToSitewebMaker: function (currentPage, configTemplate) {
      let app = this;
      app.modelTemplate = currentPage;
      app.configTemplate = configTemplate;
      app.step = 3.5;
      // console.log('CONFIG TEMPLATE ',app.configTemplate);
    },
    goToSitewebViewer: function (payload,nav) {
      let app = this;
      app.step = 4.5;
      app.caracteristic_user_template = payload;
      console.log("CARACTERISTIC USER TEMPLATE");
      app.nav = nav;
      this.$emit("site-web-viewer", true);
    },saveCarac:function(payload){
      this.caracteristic_user_template = payload
    },
    goToNext: function (payload) {
      let app = this;
      if (payload.name === "Carte digitale") {
        app.step = 2;
        app.mode = payload;
      } else {
        app.mode = payload;
        app.step = 2.5;
      }
    },
    returnToChannelChoice: function () {
      let app = this;
      app.step = 10;
    },
    goToMessageBuilder: function (channel) {
      let app = this;
      app.channel = channel;
      app.step = 2;
    },
    returnToMessageBuilder: function () {
      let app = this;
      app.step = 2;
    },
    goToDesignMaker: function (message) {
      let app = this;
      app.message = message;
      app.step = 3;
    },
    returnToDesignMaker: function () {
      let app = this;
      app.step = 3;
    },
    goToChannelChoice: function (message,carac) {
      let app = this;
      if(app.mode.name==="Site web"){
        app.message = {
          channel : null,
          mode : app.mode,
          url_site_web : URL_SITE_WEB+message.url_site_web,
          url : URL_SITE_WEB+message.url_site_web,
          lorem : message.lorem,
          ipsum : message.ipsum,
          channel: null,
          label: "Annonce de mariage !",
          category: message.category,
          category_id:message.category.id,
          model: null,
          layout: null,
          from: "joursdejoie.com",
          subject:
            "Pour plus d'informations, veuillez cliquer sur le lien suivant :" +
            message.url_site_web,
          body: `C'est avec un immense bonheur que nous vous annonçons notre mariage ! Nous serions heureux de partager ce moment unique avec vous et de célébrer notre amour entourés de nos proches. Cliquez sur ce lien pour plus d'informations ${message.url_site_web}`,
          targets: [],
          tab: [],
          displayMessage: `C'est avec un immense bonheur que nous vous annonçons notre mariage ! Nous serions heureux de partager ce moment unique avec vous et de célébrer notre amour entourés de nos proches. Cliquez sur ce lien pour plus d'informations ${message.url_site_web}`,
          messageLength:null,
          messageNumber:null,
          peopleCount: null,
          customerChannel: null,
        }

      }else{
        app.message = message;
        app.message.mode = app.mode;
        // if (app.messagePerso) {
        //   message_r = {
        //     channel: app.channel,
        //     label: app.message.label,
        //     category: app.message.category,
        //     model: app.message.model,
        //     layout: null,
        //     mode: app.message.mode,
        //     from: app.message.from,
        //     subject: app.message.subject,
        //     body: app.message.body,
        //     targets: [],
        //     tab: [],
        //     displayMessage: app.messagePerso,
        //     messageLength: app.channel.id === 3 ? app.message.messageLength : 1,
        //     messageNumber: app.channel.id === 3 ? app.message.messageNumber : 1,
        //     peopleCount: null,
        //     customerChannel: app.customerChannel,
        //   };
        // } else {
          
        // app.message.channel: null,
        //     app.message.label: app.message.label,
        //     category: app.message.category,
        //     model: app.message.model,
        //     layout: app.message.layout,
        //     from: app.message.from,
        //     mode: app.message.mode,
        //     subject: app.message.subject,
        //     body: app.message.body,
        //     targets: [],
        //     tab: [],
        //     displayMessage: app.message.displayMessage,
        //     messageLength: app.channel.id === 3 ? app.message.messageLength : 1,
        //     messageNumber: app.channel.id === 3 ? app.message.messageNumber : 1,
        //     peopleCount: null,
        //     customerChannel: app.customerChannel,
          // };
        // }

      }
      

      // this.targets = targets;
      app.step = 10;
    },
    goToTargetChoice: function (message,channel) {
      let app = this;
      app.channel = channel;
      if(app.channel.id===2 || app.channel.id===1){
        app.step=4;
      }else{
        app.step = 11;
      }
      app.message = message;
    },
    returnToTargetChoice: function () {
      let app = this;
      app.step = 4;
      app.message = app.message;
    },
    goToMessageSummary: function (message, targets) {
      let app = this;
      app.step = 4;
      app.message = message;
      this.targets = targets;
    },
    returnToMessageSummary: function () {
      this.step = 5;
    },
    goToPayment: function (message) {
      let app = this;
      this.message = message;
      app.step = 12;
    },
    messageSent: function (message) {
      let app = this;
      this.message = message;
      this.step = 6;
      this.message = message;
      app.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
      );
    },
  },
};
</script>
