<template>
  <div class="bg-container-marrieds">
    <div class="container p-3" style="padding: 25px">
      <big-title label="CONSTRUISEZ VOTRE SITE WEB !" />

      <CoupleThoughtsComponent
        @set-model="saveModel"
        @set-loading="DisplayLoading"
        v-if="nav === 1"
        :caracteristic_user_template="caracteristic_user_template"
        @save-thoughts-wife="SaveThoughtsWife"
        @save-thoughts-husband="SaveThoughtsHusband"
        :configTemplate="configTemplate"
      />

      <CelebrationsComponent
        :caracteristic_user_template="caracteristic_user_template"
        @save-img="saveImg"
        @delete-img="deleteImg"
        @set-loading="DisplayLoading"
        @mounted="renderMounted"
        @celebrationsChanged="changingCelebrations"
        @imagesChanged="changingImages"
        @save-celebrations="saveCelebrations"
        :configTemplate="configTemplate"
        v-if="nav === 2"
      />

      <ImagesComponent
        :caracteristic_user_template="caracteristic_user_template"
        @delete-img="deleteImg"
        :configTemplate="configTemplate"
        @save-img="saveImg"
        @set-loading="DisplayLoading"
        v-if="nav === 3"
      />

      <StoryComponent
        :caracteristic_user_template="caracteristic_user_template"
        @save-img="saveImg"
        @delete-img="deleteImg"
        @set-loading="DisplayLoading"
        v-if="nav === 4"
        @save-story="updateStory"
        :configTemplate="configTemplate"
      />

      <div
        class="col-md-12 d-flex justify-content-between"
        style="padding-bottom: 100px"
      >
        <button
          class="secondary-button pull-left"
          @click="prevStep"
          v-if="nav > 1"
        >
          <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
        </button>

        <button
          class="secondary-button pull-left"
          @click="goToPre"
          v-if="nav === 1"
        >
          <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
        </button>

        <button
          class="primary-button pull-right"
          @click="goToView"
          v-if="nav > 1 && nav < 5"
        >
          APERCU
        </button>

        <button
          class="primary-button pull-right"
          @click="nextStep"
          v-if="nav >= 1 && nav < 4"
        >
          SUIVANT <i class="fa fa-chevron-right" style="color: #fff"></i>
        </button>

        <button
          class="primary-button pull-right"
          @click="recordingPage"
          v-if="nav === 4"
        >
          SUIVANT <i class="fa fa-chevron-right" style="color: #fff"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-container-marrieds {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-marrieds .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-marrieds::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/the-married-ones.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import { Form } from "vee-validate";
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import Bar from "@/components/nuptial-notebook/Bar.vue";
import MarriedComponent from "@/components/nuptial-notebook/MarriedComponent";
import CelebrationsComponent from "@/components/communication/sitewebmakercomponents/CelebrationsComponent.vue";
import ImagesComponent from "@/components/communication/sitewebmakercomponents/ImagesComponent";
import CoupleThoughtsComponent from "@/components/communication/sitewebmakercomponents/CoupleThoughtsComponent.vue";
import StoryComponent from "@/components/communication/sitewebmakercomponents/StoryComponent.vue";

import axios from "axios";
// import spinner from '@/components/common/ui/spinner'
import store from "@/store";

import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "SitewebMakerComponent",
  setup() {},
  components: {
    Form,
    MarriedComponent,
    Bar,
    CelebrationsComponent,
    ImagesComponent,
    CoupleThoughtsComponent,
    StoryComponent,
  },
  mounted() {
    // console.log("STORY COMPONENT ", this.configTemplate);
  },
  props: [
    "nor",
    "caracteristic_user_template",
    "modelTemplate",
    "configTemplate",
  ],
  data: function () {
    return {
      showModal: false,
      loading: true,
      marrieds: null,
      marriedForms: null,
      user: JSON.parse(store.state.user),
      user_id: null,
      customer: { dob: null },
      textFullDisplay: false,
      celebrations: [],
      pack: {},
      thoughts: {},
      marrieds: null,
      nav: this.nor,
    };
  },
  beforeMount: function () {
    console.log('CARACTERISTIC USER TEMPLATE IN SITE WEB MAKER',this.caracteristic_user_template)

    this.show();
    this.getCustomerInfo()
      .then((customer) => {
        this.getMarrieds(customer.event.id);
      })
      .catch(() => {});
  },
  methods: {
    saveModel: function (payload) {
      this.caracteristic_user_template.category = payload;
    },
    saveImg: function (payload) {
      // console.log('PAYLOAD ',payload);
      try {
        if (payload.name in this.caracteristic_user_template) {
          this.caracteristic_user_template[payload.name] = payload.value;
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name === "photo_fiance") {
          this.caracteristic_user_template.photo_fiance = payload.value;
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name === "photo_fiancee") {
          this.caracteristic_user_template.photo_fiancee = payload.value;
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name.includes === "photo_fiances_") {
          this.caracteristic_user_template.photo_fiances.push(payload.value);
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name.includes("albumphoto") === true) {
          this.caracteristic_user_template["albumphotos"].push(payload);
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name.includes("photo_celebrations_") === true) {
          this.caracteristic_user_template["photo_celebrations"].push(payload);
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name.includes("photo_story_") === true) {
          this.caracteristic_user_template["photo_story"].push(payload);
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
        if (payload.name.includes("photo_celebrations_") === true) {
          this.caracteristic_user_template["photo_celebrations"];
          // console.log('PAYLOAD ENREGISTRE!',payload);
        }
      } catch (e) {
        // console.log(e);
      }

      // console.log("IMAGES ", this.caracteristic_user_template);
    },
    deleteProperty: function (obj, propName) {
      if (propName in obj) {
        delete obj[propName];
      }
    },
    deleteImg: function (payload) {
      try {
        if (payload.name in this.caracteristic_user_template) {
          this.caracteristic_user_template[payload.name] = "";
        } else {
          // console.log("PAYLOAD ", payload);
          if (payload.name.includes("albumphoto") === true) {
            let index_;
            this.caracteristic_user_template.albumphotos.forEach(
              (element, index) => {
                if (payload.value === element.value) {
                  index_ = index;
                }
              }
            );
            delete this.caracteristic_user_template.albumphotos[index_];
            // console.log("index_ ", index_);
            // console.log("orem", this.caracteristic_user_template.albumphotos);
          }
          if (payload.name.includes("photo_fiances_") === true) {
            let index_;
            this.caracteristic_user_template.photo_fiances.forEach(
              (element, index) => {
                if (payload.value === element.value) {
                  index_ = index;
                }
              }
            );
            delete this.caracteristic_user_template.photo_fiances[index_];
            // console.log("index_ ", index_);
            // console.log("orem", this.caracteristic_user_template.photo_fiances);
          }
          if (payload.name.includes("photo_celebrations_") === true) {
            let index_;
            this.caracteristic_user_template.photo_celebrations.forEach(
              (element, index) => {
                if (payload.value === element.value) {
                  index_ = index;
                }
              }
            );
            delete this.caracteristic_user_template.photo_celebrations[index_];
            // console.log();
          }
          if (payload.name.includes("photo_story_") === true) {
            let index_;
            this.caracteristic_user_template.photo_story.forEach(
              (element, index) => {
                if (payload.value === element.value) {
                  index_ = index;
                }
              }
            );
            delete this.caracteristic_user_template.photo_story[index_];
          }
        }
        // console.log("IMAGES ", this.caracteristic_user_template);
      } catch (e) {
        // console.log(e);
      }
    },
    saveCelebrations: function (payload) {
      this.caracteristic_user_template.celebrations = payload;
    },
    SaveThoughtsHusband: function (payload) {
      this.caracteristic_user_template.thoughts_husband = payload;
    },
    SaveThoughtsWife: function (payload) {
      this.caracteristic_user_template.thoughts_wife = payload;
    },
    updateStory: function (payload) {
      this.caracteristic_user_template.story = payload;
    },
    DisplayLoading: function (payload) {
      this.loading = payload;
    },
    nextStep: function () {
      this.loading = true;
      let app = this;
      if (
        this.nav === 1 &&
        (store.getters.getThoughtsHusband === "" ||
          store.getters.getThoughtsWife === "")
      ) {
        // this.nav === 1;
        // console.log("STEP :", this.nav);
        const toast = () => {
          createToast(
            "Vous devez dire ce que vous pensez avant de passer à l'étape suivante !",
            {
              type: "warning",
            }
          );
        };
        toast();
        return;
      }
      if (
        this.nav === 2 &&
        this.caracteristic_user_template.celebrations.length <
          parseInt(
            this.configTemplate.find(
              (element) => element.code === "nbr_celebrations"
            ).value
          )
      ) {
        const toast = () => {
          createToast(
            "Impossible de passer à l'etape suivante sans avoir enregistré de celebrations !",
            {
              type: "warning",
            }
          );
        };
        toast();
        return;
      }
      if (
        this.nav === 3 &&
        this.caracteristic_user_template.albumphotos.length < 2
      ) {
        const toast = () => {
          createToast(
            "Impossible de passer à l'etape suivante sans avoir enregistré photo de fiancé , photo de fiancée et photo de fiancés !",
            {
              type: "warning",
            }
          );
        };
        toast();
        return;
      }
      if (
        this.nav === 4 &&
        (this.caracteristic_user_template.story.our_first_meet_date === null ||
          this.caracteristic_user_template.story.our_first_meet_content ===
            "" ||
          this.caracteristic_user_template.story.our_first_date_date === null ||
          this.caracteristic_user_template.story.our_first_date_content ===
            "" ||
          this.caracteristic_user_template.story.she_said_yes_date === null ||
          this.caracteristic_user_template.story.she_said_yes_content === "")
        // JSON.stringify(this.caracteristic_user_template.story) === "{}"
      ) {
        const toast = () => {
          createToast(
            "Impossible de passer à l'etape suivante sans avoir completé votre histoire",
            {
              type: "warning",
            }
          );
        };
        toast();
        return;
      } else {
        app.nav++;
        // this.nav = nor;
        // console.log("On passe au niveau suivant ", this.nav);
      }
    },
    prevStep: function () {
      if (this.nav === 1) {
      } else {
        this.nav--;
      }
      // console.log("NAV ", this.nav);
    },
    onSubmit(values) {
      // console.log(values, null, 2);
    },
    renderMounted: function (celeb) {
      this.celebrations = celeb;
      // console.log(this.celebrations);
    },
    goToPre: function () {
      this.$emit("return-to-page-choice");
    },
    changingImages: function (images) {
      this.pack.images = images;
    },
    getCustomerInfo: function () {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        this.user_id = app.user.id;

        axios
          .get(
            NUPTIAL_NOTEBOOK.TEST_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            // console.log(response.data);
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //   this.$router.push('/inscription-a-terminer/'+2);
            // }
            // console.log("////CUSTOMER");
            // console.log(app.user);
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            // console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    goToNextSummaries: function () {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/en-bref"
      );
    },
    getMarrieds: function (eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data);
          this.isLoading = false;
          this.marrieds = response.data;
          this.caracteristic_user_template.man = this.marrieds[1];
          this.caracteristic_user_template.woman = this.marrieds[0];
        })
        .catch((error) => {
          // console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    getCustomerInfos: function () {
      let app = this;
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "customers/" +
            app.$store.state.customer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.marrieds = response.data.event.marrieds;
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    goToView: function () {
      this.$emit(
        "go-to-view-site-web",
        this.caracteristic_user_template,
        this.nav
      );
    },
    recordingPage: function () {
      // let th_h = store.getters.getThoughtsHusband;
      // let th_w = store.getters.getThoughtsWife;

      // let passport = false;

      // if (
      //   (th_h === "" || th_w === "") &&
      //   store.getters.getImg.lenght < 2 &&
      //   store.getters.getCelebrations.lenght < 2
      // ) {
      //   // const toast = () => {
      //   //   createToast('Chers mariés vous devez dire ce que vous pensez',{
      //   //   type: 'danger',
      //   //   })
      //   // }
      //   // toast();
      //   passport = false;
      // } else {
      //   passport = true;
      // }
      // if (store.getters.getCelebrations.lenght < 2) {
      //   const toast = () => {
      //     createToast("Vous n'avez pas choisi le nombre requis de célébrations(2) !",{
      //     type: 'danger',
      //     })
      //   }
      //   toast();
      //   passport = false;
      // }else{
      //   passport = true
      // }
      // if (store.getters.getImg.lenght > 2) {
      //   passport = true
      // } else {
      //   const toast = () => {
      //     createToast("Vous devez ajouter au minimum:-Une photo de fiancé,-Une photo de fiancée,-Une photo de fiancés",{
      //     type: 'danger',
      //     })
      //   }
      //   toast();
      //   passport = false;
      // }
      // let allInputFiles = document.querySelectorAll('.control-form')
      console.log('ALL INPUT FILES :', allInputFiles)

      // allInputFiles.forEach(function (currentValue, currentIndex, listObj) {
      //   let i = 0
      //   passport = true
      //   if (currentValue.value === '') {

      //     const toast = () => {
      //     createToast("Vous n'avez pas uploadé toutes les photos",{
      //     type: 'danger',
      //     })
      //   }
      //   toast();
      //     passport = false;
      //     return 0;
      //   }

        // console.log(`${}, ${currentIndex}, ${this}`);
      // });

      // if (passport != false) {
      //   this.$emit("go-to-view-site-web", this.pack);
      // }
      this.$emit(
        "go-to-view-site-web",
        this.caracteristic_user_template,
        this.nav
      );

      // axios
      //   .get(
      //     "http://127.0.0.1:8000/api/recordingPage/" +
      //       this.user.id +
      //       "-" +
      //       this.pageUser.id +
      //       "",
      //     {
      //       headers: {
      //         Authorization: "Bearer " + store.state.token,
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   )
      //   .then((response) => {
          console.log(response);
      //   })
      //   .catch((error) => {
          console.log("error", error.response.data);
      //     // this.$moshaToast(
      //     //   "Erreur lors de l'enregistrement des informations !"
      //     // );
      //     this.isLoading = false;
      //   });
    },

    show() {
      if (!this.user.email_verified_at) {
        this.showModal = true;
      }
    },
  },
};
</script>
