<template>
  <div class="py-2 flex flex-col w-full text-sm px-1 ">
    <button class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer" @click="toggleDisplay">
      <span class="text-left">Prix</span>&nbsp;
      <i class="fa text-gray-600 " :class="isDisplay ? 'fa-angle-up' : 'fa-angle-down'"></i>
      <!-- <font-awesome-icon class="" ></font-awesome-icon> -->
    </button>
    <form @submit.prevent="onSubmit" class="flex pt-5" v-show="isDisplay">
      <div class="flex mr-1 " :class="{'w-2/5': atLeastOnePrice(prices), 'w-1/2': !atLeastOnePrice(prices)}">
        <div>
          <label for="">min</label>
          <input placeholder="Prix min" class="border border-gray-300 px-2 rounded-md w-full py-2" type="number" v-model="prices.min_price" />
        </div>
        
        <div class="flex" v-if="errors && errors['min_price'] && errors['min_price'].length > 0">
          <span class="text-red-800">{{ errors['min_price'][0] }}</span>
        </div>
      </div>
      <div class="flex ml-1" :class="{'w-2/5': atLeastOnePrice(prices), 'w-1/2': !atLeastOnePrice(prices)}">
        <div>
          <label for="">max</label>
          <input placeholder="Prix max" class="border border-gray-300 px-2 rounded-md w-full py-2" type="number" v-model="prices.max_price" />
        </div>
        
        <div class="flex" v-if="errors && errors['max_price'] && errors['max_price'].length > 0">
          <span class="text-red-800">{{ errors['max_price'][0] }}</span>
        </div>
      </div>
      <div class="flex w-1/5 -mx-2" v-if="prices.max_price || prices.min_price">
        <button type="submit" class="text-red-800 py-2 px-1 w-full">
          <i class="fa fa-paper-plane text-red-800"></i>
          <!-- <font-awesome-icon icon="fa-paper-plane"></font-awesome-icon> -->
        </button>
        <button class="px-1 text-red-800 text-xs" @click="onResetBaby">
          <!-- <font-awesome-icon class="text-red-800" icon="fa-close"></font-awesome-icon> -->
          <i class="fa fa-close text-red-800"></i>
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { convertTownToTownship } from "@/views/wedding-provider/utils"
export default {
  name: 'FilterItemsPriceComponent',
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      prices: {
        min_price: 0,
        max_price: 0
      },
      isDisplay: this.isOpen,
    }
  },
  computed: {
    ...mapState('providers', {
      errors: state => state.errors,
    }),
  },
  mounted() {
    this.atLeastOnePrice()
  },
  methods: {
    onSubmit() {
      this.$emit('price-filter-selected', this.prices)
    },
    convertTownToTownshipWrapper(name) {
      return convertTownToTownship(name);
    },
    onResetBaby() {
      this.prices = {min_price: 0, max_price: 0}
      this.onSubmit()
    },
    toggleDisplay() {
      this.isDisplay = !this.isDisplay
    },
    atLeastOnePrice(prices) {
      return prices && (prices.max_price && prices.max_price > 0 || prices.min_price && prices.min_price > 0)
    }
  }
}
</script>
