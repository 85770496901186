<template>
  <site-web-viewer-component
    v-if="display === true"
    isMaking="false"
  ></site-web-viewer-component>

  <div v-else>Site is not existing</div>
</template>
<style></style>

<script>
import axios from "axios";
import {
  JOYCARD, //
  NUPTIAL_NOTEBOOK,
  API_PREFIX,
  API_VERSION
} from "@/config";
export default {
  name: "joycardsiteweb",
  components: {},
  beforeUnmount() {
    this.$emit("joycard-sitewebviewerend", false);
  },
  beforeMount() {
    console.log("DISPLAY BEFORE MOUNT ", this.display);
    let app = this;
    this.$emit("joycard-sitewebviewer", true);
    axios
      .get(NUPTIAL_NOTEBOOK.TEST_URL+API_PREFIX+API_VERSION+JOYCARD.FINDURLSITEWEB + this.$route.params.url_site_web)
      .then((response) => {
        if (typeof response.data === "object") {
          this.display = true;
          console.log("Display", app.display);
        }
      })
      .catch((error) => {
        app.display = false;
        console.log(error);
      });
  },
  data: function () {
    return {
      display: false,
    };
  },
  methods: {},
};
</script>
