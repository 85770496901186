<template>
    <div style="background-color: transparent; margin: 0; padding: 0;">
        <table class="nl-container" role="presentation">
            <tbody>
                <tr>
                    <td  style="border: 0px">
                        <table align="center" class="row row-1"
                            role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('images/90cfbf4c-fa89-47c4-af43-3dd131b2d6e5.png'); background-position: top center; background-repeat: no-repeat;"
                            width="100%">
                            <tbody>
                                <tr>
                                    <td style="padding:0px;border: 0px">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                            class="row-content stack" role="presentation"
                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000; width: 650px; margin: 0 auto;"
                                            width="650">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1"
                                                        style="border: 0px;mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 0px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                        width="100%">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="text_block block-1" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad"
                                                                    style="padding-left:10px;padding-right:10px; border: 0px">
                                                                    <div style="font-family: Arial, sans-serif">
                                                                        <div class=""
                                                                            style="font-family: 'Cabin', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 12px; mso-line-height-alt: 14.399999999999999px; color: #555555; line-height: 1.2;">
                                                                            <p
                                                                                style="margin: 0; font-size: 12px; text-align: center; mso-line-height-alt: 14.399999999999999px;">
                                                                                <span style="font-size:72px;"><strong><span
                                                                                            style=""><span style=""><span
                                                                                                    style=""><span
                                                                                                        style="color:#ffffff;">Deviens
                                                                                                        prestataire</span></span></span></span></strong></span><span
                                                                                    style="color:#333333;font-size:46px;"><strong><span
                                                                                            style="font-size:46px;"><span
                                                                                                style="font-size:46px;"><span
                                                                                                    style="font-size:46px;"><span
                                                                                                        style="font-size:120px;color:#ffffff;">
                                                                                                    </span><span
                                                                                                        style="font-size:46px;color:#333333;">JOURS
                                                                                                        DE
                                                                                                        JOIE</span></span></span></span></strong></span>
                                                                            </p>
                                                                            <p
                                                                                style="margin: 0; text-align: center; font-size: 12px; mso-line-height-alt: 14.399999999999999px;">
                                                                                <span
                                                                                    style="background-color:transparent;font-size:15px;"><span
                                                                                        style="color:#eb3f1c;font-size:15px;"><span
                                                                                            style="font-size:15px;"><strong>(augmente
                                                                                                ton chiffre d'affaire sur
                                                                                                joursdejoie.com)</strong></span></span> </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="image_block block-2" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad" style="width:100%;border: 0px">
                                                                    <div align="center" class="alignment"
                                                                        style="line-height:10px"><img alt="Image"
                                                                            src="images/ezgif-2-b649fa09625c.gif"
                                                                            style="display: block; height: auto; border: 0; max-width: 300px; width: 100%;"
                                                                            title="Image" width="300" /></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="text_block block-3" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad"
                                                                    style="padding-left:10px;padding-right:10px;padding-top:0px; border: 0px;">
                                                                    <div style="font-family: Tahoma, Verdana, sans-serif">
                                                                        <div class=""
                                                                            style="font-family: 'Roboto', Tahoma, Verdana, Segoe, sans-serif; font-size: 12px; mso-line-height-alt: 14.399999999999999px; color: #555555; line-height: 1.2;">
                                                                            <p
                                                                                style="margin: 0; text-align: center; font-size: 12px; mso-line-height-alt: 14.399999999999999px;">
                                                                                <span style="font-size:30px;"><em><span
                                                                                            style="font-size:30px;"><span
                                                                                                style="color:#ffffff;font-size:30px;"><span
                                                                                                    style="font-size:30px;">Gratuit
                                                                                                    jusqu'au 31 décembre
                                                                                                    2023</span></span></span></em></span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="button_block block-4" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad" style="border: 0px">
                                                                    <div align="center" class="alignment">
                                                                        <!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" style="height:38px;width:117px;v-text-anchor:middle;" arcsize="90%" stroke="false" fillcolor="#eb3f1c"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:'Trebuchet MS', Tahoma, sans-serif; font-size:16px"><![endif]-->
                                                                        <div
                                                                            style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#eb3f1c;border-radius:34px;width:auto;border-top:0px solid transparent;font-weight:undefined;border-right:0px solid transparent;border-bottom:0px solid transparent;border-left:0px solid transparent;padding-top:0px;padding-bottom:0px;font-family:'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;font-size:16px;text-align:center;mso-border-alt:none;word-break:keep-all;">
                                                                            <span
                                                                                style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;"><span
                                                                                    style="word-break:break-word;"><strong><span
                                                                                            data-mce-style=""
                                                                                            style="line-height: 32px;">je
                                                                                            m'inscris</span></strong></span></span>
                                                                        </div>
                                                                        <!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table align="center" class="row row-2"
                            role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
                            <tbody>
                                <tr>
                                    <td style="border: 0px; padding-top:0px;padding-bottom:0px">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                            class="row-content stack" role="presentation"
                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #faa026; color: #000; width: 650px; margin: 0 auto;"
                                            width="650">
                                            <tbody>
                                                <tr>
                                                    <td class="column column-1"
                                                        style="border: 0px;mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 0px; padding-top: 0px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                        width="33.333333333333336%">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="image_block block-1" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%;">
                                                            <tr>
                                                                <td class="pad"
                                                                    style="width:100%;padding-right:0px;padding-left:0px;border: 0px">
                                                                    <div align="center" class="alignment"
                                                                        style="line-height:10px"><img alt="I'm an image"
                                                                            src="images/startup.png"
                                                                            style="display: block; height: auto; border: 0; max-width: 86.80000000000001px; width: 100%;"
                                                                            title="I'm an image"
                                                                            width="86.80000000000001" /></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="divider_block block-2" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad" style="border: 0px">
                                                                    <div align="center" class="alignment">
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            role="presentation"
                                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                            width="80%">
                                                                            <tr>
                                                                                <td class="divider_inner"
                                                                                    style="font-size: 1px; line-height: 1px; border-top: 0px dashed #FFFFFF;">
                                                                                    <span> </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="5" cellspacing="0"
                                                            class="text_block block-3" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div style="font-family: sans-serif">
                                                                        <div class=""
                                                                            style="font-size: 14px; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; mso-line-height-alt: 16.8px; color: #FFFFFF; line-height: 1.2;">
                                                                            <p
                                                                                style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;">
                                                                                Pénétrez un marché sous exploité et comblez
                                                                                les besoins des consommateurs</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="column column-2"
                                                        style="border: 0px;mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 0px; padding-top: 0px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                                                        width="33.333333333333336%">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="image_block block-1" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad"
                                                                    style="width:100%;padding-right:0px;padding-left:0px;border-top: 0px; border-bottom: 0px;">
                                                                    <div align="center" class="alignment"
                                                                        style="line-height:10px">
                                                                        <img alt="I'm an image"
                                                                            src="images/support.png"
                                                                            style="display: block; height: auto; border: 0; max-width: 86.80000000000001px; width: 100%;"
                                                                            title="I'm an image"
                                                                            width="86.80000000000001" />
                                                                        </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="divider_block block-2" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div align="center" class="alignment">
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            role="presentation"
                                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                            width="80%">
                                                                            <tr>
                                                                                <td class="divider_inner"
                                                                                    style="font-size: 1px; line-height: 1px; border-top: 0px dashed #FFFFFF;">
                                                                                    <span> </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="text_block block-3" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div style="font-family: sans-serif">
                                                                        <div class=""
                                                                            style="font-size: 14px; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; mso-line-height-alt: 16.8px; color: #FFFFFF; line-height: 1.2;">
                                                                            <p
                                                                                style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;">
                                                                                Prenez de l'avance sur la concurrence en
                                                                                diversifiant votre circuit de distribution
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="column column-3"
                                                        style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 0px; padding-top: 0px; vertical-align: top; border: 0px;"
                                                        width="33.333333333333336%">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                            class="image_block block-1" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad"
                                                                    style="width:100%;padding-right:0px;padding-left:0px; border-top: 0px; border-bottom: 0px;">
                                                                    <div align="center" class="alignment"
                                                                        style="line-height:10px"><img alt="I'm an image"
                                                                            src="images/guarantee.png"
                                                                            style="display: block; height: auto; border:0px; max-width: 87px; width: 100%;"
                                                                            title="I'm an image" width="87" /></div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="divider_block block-2" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div align="center" class="alignment">
                                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                                            role="presentation"
                                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                                                                            width="80%">
                                                                            <tr>
                                                                                <td class="divider_inner"
                                                                                    style="font-size: 1px; line-height: 1px; border-top: 0px dashed #FFFFFF;">
                                                                                    <span> </span>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        <table border="0" cellpadding="10" cellspacing="0"
                                                            class="text_block block-3" role="presentation"
                                                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                                                            width="100%">
                                                            <tr>
                                                                <td class="pad">
                                                                    <div style="font-family: sans-serif">
                                                                        <div class=""
                                                                            style="font-size: 14px; font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif; mso-line-height-alt: 16.8px; color: #FFFFFF; line-height: 1.2;">
                                                                            <p
                                                                                style="margin: 0; font-size: 14px; text-align: center; mso-line-height-alt: 16.8px;">
                                                                                Augmenter votre chiffre d'affaire en vous
                                                                                inscrivant sur joursdejoie.com</p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style scoped>
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: inherit !important;
}

#MessageViewBody a {
    color: inherit;
    text-decoration: none;
}

p {
    line-height: inherit
}

.desktop_hide,
.desktop_hide table {
    mso-hide: all;
    display: none;
    max-height: 0px;
    overflow: hidden;
}

.image_block img+div {
    display: none;
}

@media (max-width:670px) {
    .desktop_hide table.icons-inner {
        display: inline-block !important;
    }

    .icons-inner {
        text-align: center;
    }

    .icons-inner td {
        margin: 0 auto;
    }

    .mobile_hide {
        display: none;
    }

    .row-content {
        width: 100% !important;
    }

    .stack .column {
        width: 100%;
        display: block;
    }

    .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0px;
    }

    .desktop_hide,
    .desktop_hide table {
        display: table !important;
        max-height: none !important;
    }
}
</style>