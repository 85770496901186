import { getTownsAPI, getTownAPI } from "@/views/wedding-provider/apis";

export const state = () => ({
    isLoading: false,
    errors: {},
    towns: [],
    town: null
})

export const actions = {
    async getTowns({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getTownsAPI(params);
            
            commit('setLoading', false);
            commit('setTowns', response.data);

        } catch (e) {
            commit('setLoading', false);
            commit('setTowns', []);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    },
    async getTown({ commit }, { id }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getTownAPI(id, params);
            commit('setLoading', false);
            commit('setTown', response.data);

        } catch (e) {
            commit('setLoading', false);
            commit('setTown', null);
            commit('setErrors', e.response);
            throw new Error(e)
        }
    }
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setTowns(state, value) {
        state.towns = value
    },
    setTown(state, value) {
        state.town = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}