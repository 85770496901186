<template>
  <div class="flex flex-col mx-auto md:mx-0">
<!--    <form class="flex" @submit.prevent="onSearchSubmit">-->
<!--      <input class="search-bar" v-model="searchTxt" placeholder="Nom du prestataire" />-->
<!--      <button class="button" type="submit">-->
<!--        <font-awesome-icon icon="search" class="icon" />-->
<!--      </button>-->
<!--    </form>-->
    <div class="flex border-red-800 border-b-2 py-2">
      <filter-item-service-type-component
          v-if="serviceTypes"
          :isOpen="isOpen"
          :currentServiceTypeId="currentServiceTypeId"
          :data="serviceTypes"
          @service-type-filter-selected="onServiceTypeFilterSelected"
      ></filter-item-service-type-component>
    </div>
    <div class="flex border-red-800 border-b-2 py-2">
      <filter-item-town-component
          v-if="towns"
          :isOpen="isOpen"
          :data="towns"
          :currentTownId="currentTownId"
          @town-filter-selected="onTownFilterSelected"
      ></filter-item-town-component>
    </div>
    <div class="flex border-red-800 border-b-2 py-2">
      <filter-item-price-component
          :isOpen="isOpen"
          @price-filter-selected="onPriceFilterSelected"
      ></filter-item-price-component>
    </div>
    <div class="flex border-red-800 border-b-2 py-2">
      <filter-item-review-component
          :isOpen="isOpen"
          @review-filter-selected="onReviewFilterSelected">
      ></filter-item-review-component>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FilterListComponent",
  props: {
    currentTownId: Number,
    currentServiceTypeId: Number,
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchTxt: "",
    }
  },
  computed: {
    ...mapState('towns', {
      towns: state => state.towns,
      isTownLoading: state => state.isLoading,
    }),
    ...mapState('serviceTypes', {
      serviceTypes: state => state.serviceTypes,
      isServiceTypeLoading: state => state.isLoading,
    })
  },
  methods: {
    onSearchSubmit() {
      this.$emit('search-submit', this.searchTxt);
    },
    onTownFilterSelected(townId) {
      this.$emit('town-filter-selected-on-list', townId);
    },
    onServiceTypeFilterSelected(serviceTypeId) {
      this.$emit('service-type-filter-selected-on-list', serviceTypeId);
    },
    onPriceFilterSelected(prices) {
      this.$emit('price-filter-selected-on-list', prices);
    },
    onReviewFilterSelected(mark) {
      
      this.$emit('review-filter-selected-on-list', mark);
    },
  }
}
</script>
