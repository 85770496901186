<template lang="">
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      class="text-danger bi bi-1-circle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312V4.002Z"
      />
    </svg>
  </div>

  <div class="box-thoughts">
    <div>
      <div class="col-md-12">
        <sub-title label="DITES CE QUE VOUS PENSEZ ET CHOISISSEZ LA CATEGORIE DE VOTRE SITE WEB" />
      </div>

      <br />
      <div class="alert alert-success" role="alert">
        Les champs ont bien été enregistré mais vous pouvez les ENREGISTRER
      </div>

      <div class="d-flex flex-row">
        <div class="married-container col-md-4 col-lg-4">
          <div class="row">
            <div class="p-3 col-md-12">
              <div class="col-md-12">
                <div>
                  <div class="row">

                    <div class="form-group" style="width: 100%">
                      <label for="thoughts_husband"
                        >Pensées de la fiancée</label
                      >
                      <textarea
                        required
                        class="form-control"
                        id="thoughts_wife"
                        rows="3"
                        style="width: 100%"
                        v-model="WifeThoughts"
                        >{{ WifeThoughts }}</textarea
                      >
                    </div>
                  </div>
                  <center>
                    <button class="primary-button" @click="saveThoughtWife">
                      ENREGISTRER</button
                    >&nbsp;
                  </center>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <br /><br />
        <div class="married-container col-md-4 col-lg-4">
          <div class="row">
            <div class="p-3 col-md-12">
              <div class="col-md-12">
                <div>
                  <div class="row">
                
                    <div class="form-group" style="width: 100%">
                      <label for="thoughts_husband">Pensées du fiancé</label>
                      <textarea
                        required
                        class="form-control"
                        id="thoughts_husband"
                        rows="3"
                        style="width: 100%"
                        v-model="HusbandThoughts"
                        >{{ HusbandThoughts }}</textarea
                      >
                    </div>
                  </div>
                  <center>
                    <!-- <button 
                                        v-if="modify_h!=true" class="primary-button"  @click='saveThoughtHusband'>ENREGISTRER</button> -->
                    <button class="primary-button" @click="saveThoughtHusband">
                      ENREGISTRER</button
                    >&nbsp;
                  </center>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-4">
                            <img style="width:100%;height:100%;" src="/images/nuptial_notebook/married-2.jpg" alt="">
                        </div> -->
          </div>
        </div>

        <div class="married-container col-md-4 col-lg-4">
          <div class="row">
            <div class="p-3 col-md-12">
              <div class="col-md-12">
                <div>
                  <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for>Catégorie de site web </label>
                <select
                  v-model="model_category"
                  @change="onCategoryChange(model_category)"
                  class="form-control"
                  required
                >
                  <option value>--</option>
                  <option
                    v-for="(category, key) in categories"
                    :key="key"
                    :value="category"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
                </div>
              </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import {
  COMMUNICATION,
  NUPTIAL_NOTEBOOK,
  API_PREFIX,
  API_VERSION,
} from "@/config";

export default {
  name: "CoupleThoughtsComponent",
  data: function () {
    return {
      HusbandThoughts: this.caracteristic_user_template.thoughts_husband !="" ? this.caracteristic_user_template.thoughts_husband  : "",
      WifeThoughts: this.caracteristic_user_template.thoughts_wife !="" ? this.caracteristic_user_template.thoughts_wife  : "",
      name: "",
      type: "",
      value: "",
      checker: 0,
      modify_h: false,
      modify_w: false,
      loading: false,
      models:null,
      categories:null
    };
  },
  props: ["configTemplate","caracteristic_user_template"],
  beforeMount() {
    this.getMessageCategories();
  },mounted(){
    this.$emit("set-loading", false);
  },
  unmounted() {
    this.$emit("set-loading", true);
  },
  methods: {
    onCategoryChange: function (category) {
      let app = this;
      app.models = category
      this.$emit('set-model',category)
    },
    getMessageCategories: function () {
      let app = this;
      axios
        .get(COMMUNICATION.TEST_URL + API_PREFIX + API_VERSION + "categories", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function (response) {
          app.categories = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveThoughtHusband: function () {
      if (document.querySelector("#thoughts_husband").value === "") {
        this.checker = 0;
        this.modify_h = false;
      } else {
        this.checker = 1;
        this.modify_h = true;
      }

      let t = document.querySelector("#thoughts_husband");
      this.$emit('save-thoughts-husband',this.HusbandThoughts);
      const toast = () => {
        createToast("Enregistré", {
          type: "success",
        });
      };
      toast();
    },
    saveThoughtWife: function () {
      if (document.querySelector("#thoughts_wife").value === "") {
        this.checker = 0;
        this.modify_w = false;
      } else {
        this.checker = 1;
        this.modify_w = true;
      }
      let t = document.querySelector("#thoughts_wife");
      this.$emit('save-thoughts-wife',this.WifeThoughts);

      const toast = () => {
        createToast("Enregistré", {
          type: "success",
        });
      };
      toast();
    },

  },
};
</script>
<style>
.semipolar-spinner,
.semipolar-spinner * {
  box-sizing: border-box;
}

.semipolar-spinner {
  height: 65px;
  width: 65px;
  position: relative;
}

.semipolar-spinner .ring {
  border-radius: 50%;
  position: absolute;
  border: calc(65px * 0.05) solid transparent;
  border-top-color: #ff1d5e;
  border-left-color: #ff1d5e;
  animation: semipolar-spinner-animation 2s infinite;
}

.semipolar-spinner .ring:nth-child(1) {
  height: calc(65px - 65px * 0.2 * 0);
  width: calc(65px - 65px * 0.2 * 0);
  top: calc(65px * 0.1 * 0);
  left: calc(65px * 0.1 * 0);
  animation-delay: calc(2000ms * 0.1 * 4);
  z-index: 5;
}

.semipolar-spinner .ring:nth-child(2) {
  height: calc(65px - 65px * 0.2 * 1);
  width: calc(65px - 65px * 0.2 * 1);
  top: calc(65px * 0.1 * 1);
  left: calc(65px * 0.1 * 1);
  animation-delay: calc(2000ms * 0.1 * 3);
  z-index: 4;
}

.semipolar-spinner .ring:nth-child(3) {
  height: calc(65px - 65px * 0.2 * 2);
  width: calc(65px - 65px * 0.2 * 2);
  top: calc(65px * 0.1 * 2);
  left: calc(65px * 0.1 * 2);
  animation-delay: calc(2000ms * 0.1 * 2);
  z-index: 3;
}

.semipolar-spinner .ring:nth-child(4) {
  height: calc(65px - 65px * 0.2 * 3);
  width: calc(65px - 65px * 0.2 * 3);
  top: calc(65px * 0.1 * 3);
  left: calc(65px * 0.1 * 3);
  animation-delay: calc(2000ms * 0.1 * 1);
  z-index: 2;
}

.semipolar-spinner .ring:nth-child(5) {
  height: calc(65px - 65px * 0.2 * 4);
  width: calc(65px - 65px * 0.2 * 4);
  top: calc(65px * 0.1 * 4);
  left: calc(65px * 0.1 * 4);
  animation-delay: calc(2000ms * 0.1 * 0);
  z-index: 1;
}

@keyframes semipolar-spinner-animation {
  50% {
    transform: rotate(360deg) scale(0.7);
  }
}
.box-thoughts {
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
  padding: 25px;
  margin-bottom: 25px;
}
</style>
