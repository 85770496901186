<template>
  <div class="w-full" ref="contact-form" id="contact-form">
    <div class="flex">
      <div class="flex items-center w-full -mt-28">
        <div class="px-2 md:py-6 bg-white rounded-md sticky top-0 w-full text-center">
          <div v-if="$store.state.storeWedding.quoteRequests.isSuccess" class="p-4 mb-4 text-xs text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
            <button class="flex justify-end" @click="onClose">
              <font-awesome-icon icon="fa-close"></font-awesome-icon>
            </button>
            <div class="flex">
              Merci pour votre message.<br />
              Le prestataire vous recontactera dès que possible.
            </div>
          </div>
          <form @submit.prevent="requestQuote(formTypes.MESSAGE)" class="flex flex-col w-full items-center px-2">
            <div class="text-lg py-0.5">Demander un devis</div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Prénom" v-model="given_names" required />
              <div class="flex" v-if="errors && errors['given_names'] && errors['given_names'].length > 0">
                <span class="text-red-800">{{ errors['given_names'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Email" v-model="email" required />
              <div class="flex" v-if="errors && errors['email'] && errors['email'].length > 0">
                <span class="text-red-800">{{ $store.state.storeWedding.quoteRequests.errors['email'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <input class="w-full px-2 py-2 rounded-md bg-gray-100" placeholder="Téléphone" v-model="phone_number" required />
              <div
                  class="flex"
                  v-if="errors && errors['phone_number'] && errors['phone_number'].length > 0"
              >
                <span class="text-red-800">{{ errors['phone_number'][0] }}</span>
              </div>
            </div>
            <div class="py-1 w-full">
              <textarea rows="5" class="w-full px-2 py-2 bg-gray-100 rounded-md" v-model="message" required placeholder="Ecrire votre demande ici..."></textarea>
              <div class="flex" v-if="errors && errors['message'] && errors['message'].length > 0">
                <span class="text-red-800">{{ errors['message'][0] }}</span>
              </div>
            </div>
            <div v-if="isLoading">
              <moonloader :size="'35px'"/>
            </div>
            <div v-else class="py-1 w-full">
              <button v-if="$store.getters['auth/isAuthenticated']" type="submit" class="bg-red-800 text-white rounded-full w-full py-2">
                Envoyer ma demande
              </button>
              <button v-else @click="redirectToLogin" class="bg-red-800 text-white rounded-full w-full py-2">
                Envoyer ma demande
              </button>
            </div>
            
          </form>
          <!-- <div class="flex flex-col" v-if="currentSubscription">
            <div class="flex justify-center items-center">Ou, contactez-nous par :</div>
            <div class="flex justify-center items-center">
              <form v-if="provider.phone_number_1 && currentSubscription.call_autorize" @submit.prevent="requestQuote(formTypes.PHONE)">
                <button
                    v-if="$store.getters['auth/isAuthenticated']"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2" type="submit"
                >
                  Téléphone
                </button>
                <button
                    v-else @click="redirectToLogin"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                >
                  Téléphone
                </button>
              </form>&nbsp;&nbsp;
              <form v-if="provider.phone_number_1 && currentSubscription.whatsapp" @submit.prevent="requestQuote(formTypes.WHATSAPP)">
                <button
                    v-if="$store.getters['auth/isAuthenticated']"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                    type="submit"
                >
                  WhatsApp
                </button>
                <button
                    v-else @click="redirectToLogin"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                >
                  WhatsApp
                </button>
              </form>
              &nbsp;&nbsp;
              <form v-if="provider.website && currentSubscription.siteweb" @submit.prevent="requestQuote(formTypes.WEBSITE)">
                <button
                    v-if="$store.getters['auth/isAuthenticated']"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                    type="submit"
                >
                  Site Web
                </button>
                <button
                    v-else @click="redirectToLogin"
                    class=" border-red-800 text-red-800 hover:bg-red-800 hover:text-white border px-4 py-2"
                >
                  Site Web
                </button>
              </form>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {QUOTE_REQUEST_TYPE, LOGIN_URL} from "@/views/wedding-provider/utils";
import {mapActions, mapState} from "vuex";
export default {
  name: "contact-form",
  props: ['user', 'provider','currentSubscription'],
  data: () => {
    return {
      given_names: '',
      email: '',
      phone_number:'',
      message: '',
      formTypes: QUOTE_REQUEST_TYPE,
      errors: null
    }
  },
  computed: {
    ...mapState('quoteRequests', {
            isLoading: 'isLoading',
    }),
  },
  mounted() {
    
    if (this.user) {
      this.userConnected()
    }
  },
  watch: {

    '$store.state.user'(next) {
      
      if (next && next.id) {
        this.given_names = next.given_names;
        this.email = next.email;
        this.phone_number = next.phone_number_1;
      }
    },
    '$store.state.quoteRequests.errors'(next) {
      if (next) {
        this.errors = next
      }
    }
  },
  methods: {
    dataFormatter(type) {
      return {
        given_names: this.given_names,
        email: this.email,
        phone_number: this.phone_number,
        message: this.message,
        type,
      }
    },
    requestQuote(type) {
      const data = this.dataFormatter(type);
      this.$emit('quote-request-form', data);
    },
    onClose() {
      this.$store.commit('quoteRequests/setSuccess', false);
    },
    redirectToLogin() {
      window.location = '/connexion'
    },
    userConnected() {
        this.given_names = this.user.given_names +' '+this.user.last_name;
        this.email = this.user.email;
        this.phone_number = this.user.phone_number_1;
    },
  }
}
</script>
