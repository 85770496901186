<template lang="">
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      class="text-danger bi bi-3-circle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z"
      />
    </svg>
  </div>
  <div>
    <div
      class="bg-container-image"
      style="padding: 25px"
      v-if="configTemplate.length != 0"
    >
      <div class="col-md-12">
        <center style="display: block; text-align: -webkit-center">
          <h3 class="woman-title">AJOUTEZ VOS PHOTOS :</h3>
          <hr class="hr-woman" />
        </center>
        <div class="row">
          <div class="container-fluid d-flex flex-column flex-nowrap">
            <label
              v-if="configTemplate.length != 0"
              class="input-group-text label-file-control text-left"
              for="inputGroupFile01"
              style="font-size: 150%; font-weight: bold"
              >{{
                `${
                  configTemplate.find(
                    (element) => element.code === "photo_fiance"
                  ).name
                }`
              }}</label
            >
            <label
              v-if="
                configTemplate.find(
                  (element) => element.code === 'photo_fiance'
                ).value != undefined
              "
              class="text-danger"
              >{{
                "*Dimensions: " +
                configTemplate.find(
                  (element) => element.code === "photo_fiance"
                ).value
              }}</label
            >

            <BlockImageComponent
              identifier="13"
              :name="`photo_fiance`"
              :caracteristic_user_template="caracteristic_user_template"
              :allowMultiples="false"
              @save-img="saveImg"
              @delete-img="deleteImg"
            >
            </BlockImageComponent>
          </div>

          <div class="container-fluid d-flex flex-column flex-nowrap">
            <label
              v-if="configTemplate.length != 0"
              class="text-left input-group-text label-file-control"
              for="inputGroupFile01"
              style="font-size: 150%; font-weight: bold"
              >{{
                `${
                  configTemplate.find(
                    (element) => element.code === "photo_fiancee"
                  ).name
                } `
              }}</label
            >
            <label
              v-if="
                configTemplate.find(
                  (element) => element.code === 'photo_fiancee'
                ).value != undefined
              "
              class="text-danger"
              >{{
                "*Dimensions: " +
                configTemplate.find(
                  (element) => element.code === "photo_fiancee"
                ).value
              }}</label
            >

            <BlockImageComponent
              identifier="11"
              :name="`photo_fiancee`"
              :caracteristic_user_template="caracteristic_user_template"
              :allowMultiples="false"
              @save-img="saveImg"
              @delete-img="deleteImg"
            >
            </BlockImageComponent>
          </div>

          <div
            class="container-fluid d-flex flex-column flex-nowrap"
            v-if="multiple_photo_fiances === false"
          >
            <label
              v-if="configTemplate.length != 0"
              class="text-left input-group-text label-file-control"
              for="inputGroupFile01"
              style="font-size: 150%; font-weight: bold"
              >{{
                `${
                  configTemplate.find(
                    (element) => element.code === "photo_fiances"
                  ).name
                }  `
              }}</label
            >
            <label
              v-if="
                configTemplate.find(
                  (element) => element.code === 'photo_fiances'
                ).value != undefined
              "
              class="text-danger"
              >{{
                `*Dimensions: ${
                  configTemplate.find(
                    (element) => element.code === "photo_fiances"
                  ).value
                }`
              }}</label
            >

            <BlockImageComponent
              identifier="10"
              :name="
                configTemplate.find(
                  (element) => element.code === 'photo_fiances'
                ).code
              "
              :allowMultiples="false"
              @save-img="saveImg"
              :caracteristic_user_template="caracteristic_user_template"

              @delete-img="deleteImg"
            >
            </BlockImageComponent>
          </div>

          <div
            class="container-fluid d-flex flex-column flex-nowrap"
            v-else
            v-for="photoFiance in photoFiancesfromConfigTemplate"
          >
            <label
              v-if="configTemplate.length != 0"
              class="text-left input-group-text label-file-control"
              for="inputGroupFile01"
              style="font-size: 150%; font-weight: bold"
              >{{ `${photoFiance.name}  ` }}</label
            >
            <label v-if="photoFiance.value != undefined" class="text-danger">{{
              `*Dimensions: ${photoFiance.value}`
            }}</label>

            <BlockImageComponent
              identifier="10"
              :name="photoFiance.code"
              :allowMultiples="false"
              @save-img="saveImg"
              @delete-img="deleteImg"
              :caracteristic_user_template="caracteristic_user_template"

            >
            </BlockImageComponent>
          </div>

          <div
            class="container-fluid d-flex flex-column flex-nowrap"
            style="width: 100%"
          >
            <label
              v-if="configTemplate.length != 0"
              for=""
              class="text-center"
              style="font-size: 150%; font-weight: bold"
              >{{
                `${
                  configTemplate.find(
                    (element) => element.code === "photo_album"
                  ).name
                }`
              }}</label
            >
            <label
              v-if="
                configTemplate.find((element) => element.code === 'photo_album')
                  .value != undefined
              "
              class="text-danger"
              >{{
                `*Dimensions: ${
                  configTemplate.find(
                    (element) => element.code === "photo_album"
                  ).value
                }`
              }}</label
            >
          </div>
          <div
            class="container-fluid d-flex flex-column flex-nowrap"
            :key="index"
          >
            <BlockImageComponent
              :key="index"
              :identifier="num"

              :name="`albumphoto`"
              :allowMultiples="true"
              @save-img="saveImg"
              :caracteristic_user_template="caracteristic_user_template"

              @delete-img="deleteImg"
            >
            </BlockImageComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlockImageComponent from "./BlockImageComponent.vue";
import store from "@/store";
import axios from "axios";
import { JOYCARD } from "@/config";

export default {
  name: "ImageAlbumComponent",
  setup() {},
  computed: {},
  data() {
    return {
      photoFiancesfromConfigTemplate: [],
      multiple_photo_fiances: false,
    };
  },
  components: {
    BlockImageComponent,
  },
  props: ["configTemplate","caracteristic_user_template"],
  beforeMount() {
    let app = this;

    app.multiple_photo_fiances = JSON.parse(
        this.configTemplate.find(
          (element) => element.code === "multiple_photo_fiances"
        ).value
      );
    if (
      app.multiple_photo_fiances===false
    ) {
      // console.log("Resolve ",app.multiple_photo_fiances);
    } else {
      // console.log("Resolve ",app.multiple_photo_fiances);
      app.extractPhotoFiances();
      // console.log("MULTIPLE PHOTO FIANCES ", app.multiple_photo_fiances);
    }
  },
  mounted() {
    let app = this;
    this.$emit("set-loading", true);
  },
  beforeUnmount() {
    this.$emit("set-loading", false);
  },
  methods: {
    deleteImg:function(payload){
      this.$emit('delete-img',payload)
    },
    removeDuplicateObjects: function (arr) {
      const seen = new Set(); // Set to store unique object references
      const uniqueArray = [];

      for (const obj of arr) {
        const key = JSON.stringify(obj); // Create a unique key based on object properties

        if (!seen.has(key)) {
          seen.add(key);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    },
    saveImg: function (payload) {
      // console.log('save img',payload)
      this.$emit("save-img", payload);
    },

    extractPhotoFiances: function () {
      let app = this;
      let nbr_photo_fiances = (app.nbrPhotoFiances = app.configTemplate.find(
        (element) => element.code === "nbr_photo_fiances"
      ).value);

      for (let i = 1; i <= nbr_photo_fiances; i++) {
        app.photoFiancesfromConfigTemplate.push(
          app.configTemplate.find(
            (element) => element.code === "photo_fiances_" + i
          )
        );
      }

      app.photoFiancesfromConfigTemplate = this.removeDuplicateObjects(
        app.photoFiancesfromConfigTemplate
      );
    },
  },
};
</script>
<style>
.woman-title {
  color: #b71c1c;
}

.label-file-control {
  align-self: center;
  margin-right: 20px;
}

.control-form {
  border-radius: 0;
  border: 1px solid #dddddd;
  /* background-color: transparent; */
  height: 45px;
  /* color: #777c87;*/
}

.hr-woman {
  height: 3px;
  width: 18%;
  border: none;
  color: #b71c1c;
  background-color: #b71c1c;
  margin-top: 10px;
  margin-bottom: 40px;
}

.bg-container-image {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
  margin-bottom: 50px;
}
</style>
