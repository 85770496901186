<template>
    <div v-if="backgroundStyle" style="width: 420px;">
        <a class="primary-button text-center" style="width:250px; height :27px; margin:0px;padding:0px;" href="javascript:void(0);" @click="modal" data-toggle="modal"  :data-target="'#exampleModalCenter' + (index + 1)">
            <a :href="link" target="_blank">
                <div class="card">
                    
                    <div class="header" :style="backgroundStyle">
                        <small class="header-content">
                            <div v-html="title" ></div>
                        </small>
                    </div>
                </div>
            </a>
        </a>
    </div>
</template>

<style scoped>
    .header {
        position:relative;
        /* min-height: 150px; */
        /* background-image: linear-gradient(rgba(0, 0, 0, .550), rgba(0, 0, 0, .550)), url('https://placeimg.com/640/480/any?3'); */
        background-repeat:no-repeat;
        background-size:cover;
        width:100%;
        height:250px;
        padding-left:1px;
        padding-right:1px;
    }

    .header-content {
        position:absolute;
        bottom:0;
        left:0;
        color:#fff;
        font-size:15px;
        text-align:left;
        font-weight:500;
        padding:20px;
    }
</style>

<script>
    export default {
        name: 'TownHallSlide',
        props: ['title', 'image', 'link','index','townsPhoto'],
        data(){
            return{

                slide:{
                    index:this.index,
                    path:this.image
                }
            }
        },
        computed: {
            backgroundStyle: function() {
                return "background-image:linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0)),url(" + this.image + ");";
            }
        },
        methods:{
            modal: function(){
                
                this.$emit("path",this.image);
                this.$emit("index",this.index);
                
            },
        }
        
    }
</script>