<template>
  <header id="head">
    <div
      class="container no-print"
      style="position: relative;border: solid 1px white;"
    >
      <div class="header-wrap" style="position: relative;z-index: 100000; ">
        <div
          class="header-top d-flex justify-content-between align-items-center no-gutters"
          style="vertical-align: middle; padding:0px;min-height:72px;"
        >
          <div class="col menu-left" style="font-size: 12px;">
            <router-link to="/">ACCUEIL</router-link>
            <router-link
              to="/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire"
              >MAIRIES</router-link
            >
            <router-link
              to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
              >CARNET NUPTIAL</router-link
            >
            <router-link
              to="/annuaires-des-prestataires-de-services-mariage-en-cote-divoire"
              >PRESTATAIRES (JOY SHOP)</router-link
            >
            <!-- <router-link to="/shopping">SHOPPING</router-link> -->
          </div>
          <div class="col-3 logo" id="titre">
            <a href="/">
              <img
                class="mx-auto logojdj"
                src="/images/logo.png"
                alt=""
              />
              <!-- <img class="mx-auto" src="/images/logo2.jpeg" style="height:90px;" alt=""> -->
              <!-- <img class="mx-auto" src="/images/old_img/logo.png" alt=""> -->
            </a>
          </div>
          <nav class="col navbar navbar-expand-md justify-content-end">
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span class="lnr lnr-menu"></span>
            </button>
            <div
              class="collapse navbar-collapse menu-right"
              id="collapsibleNavbar"
              style="font-size: 12px;"
            >
              <ul class="navbar-nav">
                <li class="nav-item d-md-none">
                  <router-link class="nav-link" to="/">ACCUEIL</router-link>
                </li>
                <li class="nav-item d-md-none">
                  <router-link
                    class="nav-link"
                    to="/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire"
                    >MAIRIES</router-link
                  >
                </li>
                <li class="nav-item d-md-none">
                  <router-link
                    class="nav-link"
                    to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances"
                    >CARNET NUPTIAL</router-link
                  >
                </li>
                <!-- Bloc décommenter -->
                <li class="nav-item d-md-none">
                  <router-link
                    class="nav-link"
                    to="/annuaires-des-prestataires-de-services-mariage-en-cote-divoire"
                    >PRESTATAIRES (JOY SHOP)</router-link
                  >
                </li> 
                <!-- <li class="nav-item d-md-none">
                  <router-link to="/shopping">SHOPPING</router-link>
                  
                </li> -->
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
                    >JOY CARDS</router-link
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    target="_blank"
                    href="https://blog.joursdejoie.com"
                    >BLOG</a
                  >
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/contacts-de-l-entreprise-d-organisation-de-mariage-cote-d-ivoire"
                    >CONTACTS</router-link
                  >
                </li>
                <li v-if="user != null" class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa fa-user-circle-o" style="font-size:17px;"></i
                    >&nbsp;
                    <span>{{ userFullname }}</span>
                    <span class="caret"></span>
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="goToAccountPage"
                      >Mon compte</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);" @click="$router.push('/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/prestataires-de-mariage-contacte')">Mes demandes de prestatations</a>
                    <li
                      class="dropdown-divider"
                      style="border-top:1px solid #b71c1c;"
                    ></li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="logout"
                      >Déconnexion</a
                    >
                  </div>
                </li>
                <li v-else class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa fa-user-circle-o" style="font-size:17px;"></i>
                    PROFIL
                    <span class="caret"></span>
                  </a>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="goToLoginPage"
                      >CONNEXION</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      @click="goToRegisterPage"
                      >INSCRIPTION</a
                    >
                    <li
                      class="dropdown-divider"
                      style="border-top:1px solid #b71c1c;"
                    ></li>
                    
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="user != null && customer != null">
      <nav
        v-if="user.email_verified_at == null || customer.dob == null"
        class="navbar row"
        style="background-color:#b71c1c;"
      >
        <div class="col-md-12 text-center">
          <a
            href="javascript:void(0);"
            @click="$router.push('/infos-compte-de-mariage-cote-d-ivoire')"
            class="text-white"
          >
            Afin de profiter pleinement des fonctionnalités, veuillez s'il vous
            plait jetter un coup d'oeil à votre profil ❤️...
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import store from "@/store";
export default {
  name: "headerComponent",
  data: function() {
    return {
      user: JSON.parse(store.state.user),
      isLoading: false,
      customer: null
    };
  },
  computed: {
    //
  },
  created: function() {
    store.dispatch("get_user_info");
    this.getAccount();
  },
  computed: {
    userFullname: function() {
      let fullname = null;
      if (this.user != null) {
        fullname = this.user.given_names + " " + this.user.last_name;
        fullname = fullname.substring(0, 7) + "...";
      } else {
        fullname = "MON PROFIL";
      }
      return fullname;
    }
  },
  methods: {
    goToLoginPage: function() {
      this.$router.push("/connexion");
    },
    goToRegisterPage: function() {
      this.$router.push("/inscription");
    },
    goToAccountPage: function() {
      this.$router.push("/infos-compte-de-mariage-cote-d-ivoire");
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        window.location.href = "/";
      });
    },
    getAccount: function() {
      let app = this;
      this.isLoading = true;
      if (this.user) {
        this.$http
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "customers/" +
            this.$store.state.customer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          }
        )
        .then(response => {
          this.customer = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
        });
      }
      
    }
  }
};
</script>

<style scoped>
 @media screen and (min-width: 900px) {
        .logojdj {
            height: 90px;
            background-position: center center;
            background-size: 'cover';
        }
    }
    @media screen and (max-width: 900px) {
        .logojdj {
            height: 45px !important;
        }
    }
.router-link-exact-active {
  color: #b71c1c;
}

.menu-left {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.menu-left a {
  margin-right: 25px;
}

.nav-link {
  padding-right: 0 !important;
}
</style>
