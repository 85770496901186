<template>
  <div class="w-full">
   
    <div class="relative flex" v-if="media_previews && media_previews.length >= 3">
      <div class="flex col-md-7 pr-1 ">
        <img class="h-full w-full object-cover" :src="media_previews[0]" style="max-height: 500px;" alt="">
      </div>
      <div class="flex flex-col  flex-1 relative" style="max-height:500px;width: auto;" >
        <div class="flex h-[15em] pb-1" style="max-height:250px;"  >
          <img class="h-full w-full object-cover" :src="media_previews[1]" alt="">
        </div>
        <div class="flex h-[15em] " style="max-height:250px;">
          <img class="h-full w-full object-cover" :src="media_previews[2]" alt="">
        </div>
        <button @click="currentType('video')"  class="absolute text-red-800 border-red-800 right-1 bottom-0 border px-4 py-1.5 hover:text-white hover:bg-red-800" v-if=" videos > 0">
          {{ `Voir ${videos} Video(s)` }} <i class="fa fa-video-camera"></i>
        </button>
        <button @click="currentType('photo')" class="absolute text-red-800 border-red-800 right-0 bottom-0 border px-4 py-1.5 hover:text-white hover:bg-red-800" v-if="photos > 0">
          {{ `Voir ${photos} Photo(s)` }} <i class="fa fa-camera"></i>
        </button>
      </div>
      <!-- <div v-for="(media_preview,index) in media_previews.slice(0,3)" :key="index" class="flex flex-1">
        <div v-if="media_preview">
          <img class="h-full  object-cover" :src="media_preview" />
        </div>
        <div v-else>  
          <img class="h-full object-cover" :src="STORAGE_BASE_URL+'medias/default.png'" />
        </div>
      </div> -->
           
    </div>
  </div>
</template>

<script>
import {STORAGE_BASE_URL, getGoodPath,requestHeaders} from "@/views/wedding-provider/utils";
import axios from 'axios';

const mapMediasToDisplay = (medias) => {
  
  const mediasToDisplay = medias;
  if(medias.length <= 3) {
    const rest = 3 - medias.length;
    for (let i = 1; i <= rest; i++) {
      if (medias[i]) {
        mediasToDisplay.push(medias[i]);
      }else{
        mediasToDisplay.push(null);
      }
      
    }
  } 
  // else if (medias.length > 3) {
  //   for (let i = 1; i <= 3; i++) {
  //     mediasToDisplay.push(medias[i]);
  //   }
  // }
  
  return mediasToDisplay;
}

export default {
  name: 'portfolio-preview-component',
  props: ['medias'],
  data: () => ({
    storageBaseUrl: STORAGE_BASE_URL,
    media_previews: [],
    currentMedia: null,
    displayModal: false,
  }),
  mounted(){

  },
  watch: {
    medias(next) {
      if (next) {
       
           mapMediasToDisplay(this.medias).map(media => {
            if (media && media.type == 'tiktok_video') {
              axios.get('https://www.tiktok.com/oembed?url='+media.path,requestHeaders).then((res) =>{
                console.log(res)
                this.media_previews.push(res.data.thumbnail_url)
              });
            }else{
              this.media_previews.push(getGoodPath(media, true))
            }
            
          });
        
      }
    },
  },
  computed: {
    photos(){
      
      
      let photocount = 0
      let videocount = 0
      this.medias.forEach(element => {
        if (element) {
          if (element.type == 'photo') {
            photocount++
          } else {
            videocount++
          }
        }
        
      });
      return photocount
    },
    videos(){
      
     
      let photocount = 0
      let videocount = 0
      this.medias.forEach(element => {
        if (element) {
          if (element.type == 'photo') {
            photocount++
          } else {
            videocount++
          }
        }
      });
      return videocount
    }
  },
  methods: {
    currentType(data) {
      this.$emit('quote-current-type', data);
    },
  },
}
</script>

<style scoped>

</style>