import axios from "axios";
import { TOWN_HALL_BASE_URL } from "@/views/wedding-provider/utils";


export async function getTownsAPI(params) {
    try {
        const response = await axios.get(`${TOWN_HALL_BASE_URL}/api/v1/towns-names`, { params });
        return response;
    } catch (error) {
        console.log("===> getTownsAPI error", error);
    }
}

export async function getTownAPI(townId, params) {
    try {
        const response = await axios.get(`${TOWN_HALL_BASE_URL}/api/v1/towns/${townId}`, { params });
        return response;
    } catch (error) {
        console.log("===> getTownsAPI error", error);
    }
}