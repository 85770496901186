<template>
  <div>
    <header>
      <nav-component :is-display-search-inputs="true"></nav-component>
    </header>
    <div class="container mx-auto">
      <div class="w-full text-center py-8" v-if="!$store.state.auth.user">
        Chargement en cours ...
      </div>
      <div class="w-full bg-red-50 text-center py-8" v-else>
        Content de vous revoir {{ $store.state.auth.user.given_names }} !
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
export default {
  name: "CallbackPage",
  layout: 'default',
  data() {
  },
  computed: () => ({
    ...mapState({
      token: state => state.auth.token,
    }),
  }),
  watch: {
    '$store.state.auth.user' (next) {
      if (next && next.id) {
        this.redirectTo()
      }
    },
    '$store.state.auth.token' (next) {
      if (next) {
        this.redirectTo()
      }
    }
  },
  methods: {
    ...mapActions('auth', ['showMe']),
    ...mapMutations('auth', ['setToken']),
    redirectTo(route) {
      window.location = !route ? '/' : route;
    }
  },
  async mounted() {
    if (this.$route.query.api_token) {
      await this.setToken(this.$route.query.api_token);
      if(!this.$store.state.auth.user) {
        await this.showMe();
      }
    }
  }
}
</script>

<style scoped>

</style>