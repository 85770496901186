import { getCommentsAPI, storeCommentAPI, deleteCommentAPI } from "@/views/wedding-provider/apis";

export const state = () => ({
    isLoading: false,
    errors: {},
    errorMessage: null,
    comments: []
})

export const actions = {
    async getComments({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await getCommentsAPI(params);
            
            commit('setComments', response.data);
            commit('setLoading', false);
        } catch (error) {
            console.log('error comment',error)
            commit('setLoading', false);
            commit('setComments', []);
            commit('setErrors', error.response.data);
        }
    },
    async storeComment({ commit }, data, params = {}) {
        commit('setLoading', true);
        try {
            const response = await storeCommentAPI(data, params);
            
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setErrors', error.errors);
            commit('setErrorMessage', error.message);
            throw error.errors
        }
    },
    async deleteComment({ commit }, id, params = {}) {
        commit('setLoading', true);
        try {
            await deleteCommentAPI(id, params);
            commit('setLoading', false);
        } catch (error) {
            commit('setLoading', false);
            commit('setErrors', error.errors);
            commit('setErrorMessage', error.message);
            throw error.errors
        }
    }
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setErrorMessage(state, value) {
        state.errorMessage = value
    },
    setComments(state, value) {
        state.comments = value
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}