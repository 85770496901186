<template>
    <div v-if="validationErrors">
        <ul class="alert alert-danger">
            <li v-for="(value, key) in validationErrors" :key="key">{{ value }}</li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'ValidationErrors',
        data: function() {
            return {

            }
        },
        props: ['errors'],
        computed: {
            validationErrors: function() {
                let errors = Object.values(this.errors);
                errors = errors.flat();
                return errors;
            }
        }
    }
</script>