<template>
  <div class="flex w-full text-center">
    <span class="text-center">Chargement en cours...</span>
  </div>
</template>

<script>
export default {
  name: "spinner-component"
}
</script>

<style scoped>

</style>