<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="mode-choice-container p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info" v-if="mode != ''">
              <div class="text-center">
                Veuillez choisir le mode de votre JOY CARD !
              </div>
            </div>
            <div class="alert bg-green" v-if="mode === ''">
              <div class="text-center">
                Vous avez choisi le mode: {{ name }}
              </div>
            </div>

            <!-- <div class="alert alert-success" v-if="remainingMessages > 0">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages == 0">
              <div class="text-center">Vous disposez de {{ remainingMessages }} messages.</div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages < 0">
              <div
                class="text-center"
              >Veuillez immédiatement contacter l'administrateur svp au 0709525460 !!!!</div>
            </div> -->
          </div>
          <div
            class="row"
            style="display: flex; justify-content: center"
            v-if="modes != null"
          >
            <div
              class="col-md-4 text-center"
              v-for="(mode, key) in modes"
              :key="key"
            >
              <div class="form-group">
                <label :for="'mode' + key">
                  <img :src="mode.path_image" style="border-radius:50%;width: 70%" alt="set" />
                  <br />
                  <br />
                  <input
                    @change="onModeChange(mode)"
                    type="radio"
                    name="mode"
                    :id="'mode' + key"
                  />&nbsp;
                  <label :for="'mode' + key" style="font-size: 16px">{{
                    mode.name
                  }}</label>
                </label>
              </div>
            </div>
          </div>
          <div v-else-if="modes == null">
            <p class="text-center"><div class="half-circle-spinner">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
        </div></p>
          </div>
          <div class="col-md-12">
            <button class="primary-button pull-right" @click="goToNext">
              SUIVANT
              <i class="fa fa-chevron-right" style="color: #fff"></i>
            </button>
            <!-- <s-whats-app
            :window-features="windowFeatures"
            :share-options="shareOptions"
            :use-native-behavior="useNativeBehavior"
            @popup-close="onClose"
            @popup-open="onOpen"
            @popup-block="onBlock"
            @popup-focus="onFocus"
          ></s-whats-app> -->

            <!-- <button class="primary-button pull-right" v-else disabled>
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mode-choice-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
</style>

<script>

import { defineComponent } from "vue";
import { SWhatsApp } from 'vue-socials'

import axios from "axios";
import {
  URL_PREVIEW_TEMPLATE,
  COMMUNICATION,
  API_PREFIX,
  API_VERSION,
  URL_API_JOY,
  NUPTIAL_NOTEBOOK,
  JOYCARD,
} from "@/config";
import { ref } from 'vue';
export default defineComponent({
  name: "PageChoiceModeComponent",
  components: { SWhatsApp },
  setup(){
    const shareNetworkRef = ref(null);

    function runWorkaround(index) {
      if (shareNetworkRef.value === null) return;

      clearInterval(shareNetworkRef.value.popupInterval);
      shareNetworkRef.value.popupWindow = undefined;
    }

    return {
      shareNetworkRef,
      runWorkaround,
    };
    
  },
  data: function () {
    return {
      // shareNetworkRef:ref(null),
      modes: [

      ],
      mode: {},
    };
  },
  mounted: function () {
    this.getModes();
  },
  methods: {

    getModes: function () {
      return new Promise((resolve, reject) => {
        let app = this;
        axios
          .get(NUPTIAL_NOTEBOOK.TEST_URL+API_PREFIX+API_VERSION+JOYCARD.GETMODEJOYCARDURL, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            app.modes = response.data;
            console.log("RESPONSE DATA :", response.data);
            resolve(app.modes);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    onModeChange: function (mode) {
      let app = this;
      app.mode = mode;
    },

    goToNext: function () {
      let app = this;
      app.$emit("go-to-next", app.mode);
    },
  },
});
</script>
