<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-col py-2">
      <select required v-model="discovery_channel" class="flex w-full border-red-800 border-b focus:outline-0 focus:border-b-2">
        <option value="">Comment avez-vous connu ce prestataire ?</option>
        <option value="by_jdj">J'ai connu ce prestataire sur JOURS DE JOIE</option>
        <option value="used_service">Nous avons utilisé ce service pour notre mariage</option>
        <option value="potential_provider">Nous l'envisageons comme prestataire potentiel</option>
        <option value="guest_in_wedding">J'ai été invité·e à un mariage qui a fait appel à ses services</option>
        <option value="professional">Je suis un professionnel qui connaît ce prestataire</option>
      </select>
      <div class="flex" v-if="errors && errors['discovery_channel'] && errors['discovery_channel'].length > 0">
        <span class="text-red-800">{{ errors['discovery_channel'][0] }}</span>
      </div>
    </div>
    <div class="flex flex-col md:flex-row py-2 w-full">
      <div class="w-full md:w-1/4">
        <span>Professionnalisme</span>
        <review-stars-component componentId="professionalism_mark" :inShownMode="false" @on-mark-selected="onMarkSelected"></review-stars-component>
        <div class="flex" v-if="errors && errors['professionalism_mark'] && errors['professionalism_mark'].length > 0">
          <span class="text-red-800">{{ errors['professionalism_mark'][0] }}</span>
        </div>
      </div>
      <div class="w-full md:w-1/4">
        <span>Rapport qualité / prix</span>
        <review-stars-component componentId="quality_price_ratio_mark" :inShownMode="false" @on-mark-selected="onMarkSelected"></review-stars-component>
        <div class="flex" v-if="errors && errors['quality_price_ratio_mark'] && errors['quality_price_ratio_mark'].length > 0">
          <span class="text-red-800">{{ errors['quality_price_ratio_mark'][0] }}</span>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <span>Le recommanderiez-vous à un ami ?</span>
        <review-stars-component
            componentId="recommendation_mark" :inShownMode="false"
            @on-mark-selected="onMarkSelected"
        ></review-stars-component>
        <div class="flex" v-if="errors && errors['recommendation_mark'] && errors['recommendation_mark'].length > 0">
          <span class="text-red-800">{{ errors['recommendation_mark'][0] }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col py-2">
      <textarea
          v-model="description" rows="3" placeholder="Commentaire"
          class="flex w-full border-red-800 border focus:outline-0 focus:border-b-2"
          maxlength="242"
      ></textarea>
      <div class="flex" v-if="errors && errors['description'] && errors['description'].length > 0">
        <span class="text-red-800">{{ errors['description'][0] }}</span>
      </div>
    </div>
    <div v-if="isLoading" class="flex justify-end">
      <moonloader :size="'35px'"/>
    </div>
    <div v-else class="flex justify-end">
      <button v-if="$store.getters['auth/isAuthenticated']" @click="onSubmit" class="px-4 py-2 text-white rounded-full bg-red-800 hover:bg-red-900 w-fit">
        Envoyer
      </button>
      <button v-else @click="redirectToLogin" class="px-4 py-2 text-white rounded-full bg-red-800 hover:bg-red-900 w-fit">
        Envoyer
      </button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {LOGIN_URL} from "@/views/wedding-provider/utils";
export default {
  name: "comment-form-component",
  props: ['provider'],
  data() {
    return {
      mark: {
        global_mark: 0,
        professionalism_mark: 0,
        quality_price_ratio_mark: 0,
        recommendation_mark: 0
      },
      description: null,
      discovery_channel: ""
    }
  },
  computed: {
    ...mapState('comments', {
      errors: state => state.errors,
    }),
    ...mapState('comments', {
        isLoading: 'isLoading',
    })
  },
  methods: {
    onSubmit() {
      
      this.$emit('comment-form-submitted', {
        global_mark: this.mark.global_mark,
        professionalism_mark: this.mark.professionalism_mark,
        quality_price_ratio_mark: this.mark.quality_price_ratio_mark,
        recommendation_mark: this.mark.recommendation_mark,
        description: this.description,
        discovery_channel: this.discovery_channel,
        wedding_provider_id: this.provider.id,
        service: this.provider.service_type.name
      })
    },
    onMarkSelected(item) {
      this.mark[item.componentId] = item.mark
      this.mark.global = this.globalMark()
    },
    globalMark() {
      const { professionalism, quality_price_ratio, recommendation } = this.mark
      return parseFloat((professionalism + quality_price_ratio + recommendation) / 3).toFixed(1)
    },
    redirectToLogin() {
      window.location = '/connexion'
    }
  }
}

</script>