<template>
  <div class="flex w-full bg-white">
    <div class="hidden lg:flex bg-white mx-auto w-full items-center justify-center uppercase text-xs">
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Acceuil</a>
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Mairies</a>
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Carnier nuptial</a>
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Prestataires</a>
      </div>
      <div class="px-10">
        <img src="@/views/wedding-provider/assets/images/logo.png" class="h-20" />
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Joy cards</a>
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Blog</a>
      </div>
      <div class="px-2">
        <a href="#" class="font-semibold hover:text-red-800">Contact</a>
      </div>
      <div class="px-2 font-semibold relative">
        <button
            v-if="user && user.given_names" class="hover:text-red-800"
            @click="toggleUserClick"
        >
          <font-awesome-icon icon="fa-user"></font-awesome-icon>&nbsp;
          {{ user.given_names }}
        </button>
        <a :href="loginUrl" v-else class="hover:text-red-800">
          Connexion / Inscription
        </a>
        <div class="flex flex-col px-4 absolute z-10 bg-white pt-10" v-if="isUserOpen">
          <div class="flex border-t border-b">
            <a href="#" class="uppercase py-3 hover:text-red-800">
              Mon compte
            </a>
          </div>
          <div class="flex border-t border-b">
            <button @click="logout" class="uppercase py-3 hover:text-red-800">
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center w-full lg:hidden">
      <div class="flex">
        <img src="@/views/wedding-provider/assets/images/logo.png" class="h-20 p-3" />
      </div>
      <div class="flex px-2 relative">
        <button @click="toggleMobileMenu" class="border border-gray-500 px-3 py-2 rounded-md hover:bg-gray-500">
          <font-awesome-icon class="text-gray-500 hover:text-white" icon="fa-bars"></font-awesome-icon>
        </button>
      </div>
      <div class="absolute top-16 z-10 bg-white w-full" v-if="isOpen">
        <div class="flex flex-col w-full pt-5 px-3">
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Acceuil</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Mairies</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Carnier nuptial</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Prestataires</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Joy cards</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Blog</a>
          </div>
          <div class="flex py-2 border-b border-t">
            <a href="#" class="w-full hover:text-red-800">Contact</a>
          </div>
          <div class="flex py-2 border-b border-t relative w-full">
            <button
                v-if="user && user.given_names" class="hover:text-red-800"
                @click="toggleUserMobileClick"
            >
              <font-awesome-icon icon="fa-user"></font-awesome-icon>&nbsp;
              {{ user.given_names }}
            </button>
            <a :href="loginUrl" v-else class="hover:text-red-800">
              Connexion / Inscription
            </a>
            <div class="flex flex-col absolute z-10 bg-white mt-6 w-full px-2" v-if="isUserMobileOpen">
              <div class="flex border-t border-b w-full py-3">
                <a href="#" class="uppercase hover:text-red-800 w-full">
                  Mon compte
                </a>
              </div>
              <div class="flex border-t border-b w-full py-3">
                <button @click="logout" class="uppercase font-semibold hover:text-red-800 w-full">
                  Déconnexion
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {LOGIN_URL} from "@/views/wedding-provider/utils";
export default {
  name: "NavMenuComponent",
  props: {
    currentPage: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    isOpen: false,
    isUserOpen: false,
    isUserMobileOpen: false,
    loginUrl: LOGIN_URL,
  }),
  computed: {
    ...mapState({
      // user: state => state.auth.user,
      token: state => state.storeWedding.auth.token,
    }),
  },
  methods: {
    ...mapActions('auth', ['showMe']),
    toggleMobileMenu() {
      this.isOpen = !this.isOpen
    },
    toggleUserClick() {
      this.isUserOpen = !this.isUserOpen
    },
    toggleUserMobileClick() {
      this.isUserMobileOpen = !this.isUserMobileOpen
    },
    logout() {
      this.$store.dispatch('auth/logout')
      window.location.reload()
    }
  },
  mounted() {
    if (!this.user && this.token) {
      this.showMe()
    }
  }
}
</script>
