<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'weddingCard',
        data: function() {
            return {

            }
        },
        methods: {

        }
    }
</script>