<template>
  <div style="width: 100%;background-color: #fff;">
    <form
      style="margin: 0 auto;height: 100vh;background-image: url('/images/backgrounds/auth/auth.png');background-size: contain;z-index: -999;"
      class="p-5"
      id="form-input"
      @submit.prevent="onSubmit"
    >
      <div class="row" style="background-color: #fff;z-index: 999;">
        <div class="col-md-12">
          <div style="text-align: center;" class="pt-5 pb-4">
            <h3 class="font-weight-light">Inscription</h3>
          </div>
        </div>
        
        <div class="col-md-12">
          <validation-errors
            :errors="validationErrors"
            v-if="validationErrors"
          ></validation-errors>
        </div>
        
        <div class="col-md-12 pb-3" v-if="user.type == 'customer'">
          <div class="form-group">
            <select class="col-12 auth-input" v-model="user.civility" required>
              <option value="" selected disabled>Civilité *</option>
              <option value="Monsieur">Monsieur</option>
              <option value="Madame">Madame</option>
              <option value="Mademoiselle">Mademoiselle</option>
            </select>
          </div>
        </div>
        <div class="col-md-6 pb-3" >
          <div class="form-group">
            <input
              v-model="user.given_names"
              class="col-12 auth-input"
              type="text"
              placeholder="Prénoms *"
              required
            />
          </div>
        </div>
        <div class="col-md-6 pb-3" >
          <div class="form-group">
            <input
              v-model="user.last_name"
              class="col-12 auth-input"
              type="text"
              placeholder="Nom *"
              required
            />
          </div>
        </div>
        <div class="col-md-6 pb-3" v-if="user.type == 'provider'">
          <div class="form-group">
            <input
              v-model="user.name"
              class="col-12 auth-input"
              type="text"
              placeholder="Nom de votre boutique *"
              required
            />
          </div>
        </div>
        <div class="col-md-6 pb-3" v-if="user.type == 'provider'">
          <multiselect
              :multiple="false"
              v-model="providerselected"
              :close-on-select="true"
              placeholder="-- Service --"
              :options="providers"
              track-by="name"
              label="name"
              :show-labels="false"
              :allow-empty="true"
            >
              
            </multiselect>
        </div>
        <div class="col-md-12 pb-3" >
          <div class="d-flex col-md-12">
            <label for=""></label>
            <multiselect
              :multiple="false"
              v-model="codeselected"
              :close-on-select="true"
              placeholder="-- Pays --"
              :options="codes"
              track-by="name"
              label="name"
              class="col-md-6"
              :show-labels="false"
              :allow-empty="true"
            >
              <template>
                <img :src="option.flag" width="5%">
                  <span>
                    {{ option.name }}
                  </span>
              </template>
            </multiselect>
            <!-- <input
              v-model="user.phone_number_1"
              class="col-md-7 auth-input"
              type="text"
              placeholder="telephone : XX XX XX XX XX "
              v-mask="mask"
              @accept="onAccept"
              @complete="onComplete"
              required
            /> -->
            <div>
              <masked-input
                label=""
                :name="'phone_number_2' + key"
                type="text"
                :rules="'min:8|max:10'"
                :isRequired="true"
                v-model="user.phone_number_1"
                placeholder="No de telephone"
                :mask="'00 00 00 00 00'"
              />
            </div>
            
          </div>
        </div>
        <div class="col-md-6 pb-3" >
          <div class="form-group ">
            <input
              v-model="user.email"
              class="auth-input"
              type="email"
              name="email"
              @blur="validateEmail"
              placeholder="Adresse email *"
              required
            />
            <span v-if="error" class="text-danger">Email incorrect</span>
          </div>
        </div>
        <div class="col-md-6 pb-3" >
          <div class="form-group">
            <input
              v-model="user.password"
              class="col-11 auth-input"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Mot de passe *"
              required
            />
            <button
              class="col-1 btn btn-sm btn-link"
              type="button"
              @click="passwordInputManagement"
            >
              <i v-if="showPassword" class="fa fa-eye-slash"></i>
              <i v-else class="fa fa-eye"></i>
            </button>
          </div>
        </div>
        <div class="col-md-6 pb-3" v-if="user.type == 'customer'">
          <div class="col-12 form-group">
            <label for=""
              >Date de naissance <span class="text-danger">*</span></label
            >
            <input
              v-model="user.dob"
              class="auth-input"
              type="date"
              placeholder="Date de naissance *"
              :max="moment().subtract(18,'year').format('YYYY-MM-DD')"
              required
            />
          </div>
        </div>
        <div class="col-md-6 pb-3" v-if="user.type == 'customer'">
          <div class="col-12 form-group">
            <label for="">
              Date de d'évènement <span class="text-danger">*</span>&nbsp;
              (mariage,anniversaire,bapteme,brunch,ceremonie)
              <vue-toggle
              :title=" exactDateType ? ' exacte':'approximative'"
              name="nom"
              toggled="true"
              fontSize="11px"
              v-model="exactDateType"
              @toggle="(value) => exactDateType = value"
            />
            </label>
            <input
              v-if="exactDateType"
              v-model="user.wedding_date"
              class="auth-input "
              type="date"
              placeholder="Date de d'évènement *"
              :min="moment().format('YYYY-MM-DD')"
              :max="moment().add(5,'year').format('YYYY-MM-DD')"
              required
            />  
            <div v-else>
              <div class="input-group">
                <input
                  v-model="user_wedding_date_number"
                  type="number"
                  placeholder="xx"
                  class="col-md-3 auth-input"
                  required
                />
                <select
                  v-model="user_wedding_date_surfix"
                  name=""
                  id=""
                  placeholder="jour,mois,semaine,année"
                  class="col-md-9 auth-input"
                  required
                >
                  <option value="weeks">Semaine(s)</option>
                  <option value="months">Mois</option>
                  <option value="years" selected>Année(s)</option>
                </select>
              </div>
              <small class="form-text text-muted">
                Exemple: 6 Mois
              </small>
            </div>
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <label for="cgu-checkbox">
              <input id="cgu-checkbox" type="checkbox" required />&nbsp;
              J'accepte toutes les
              <a
                href="/cgu-organisation-de-mariage-cote-d-ivoire"
                target="_blank"
                class="text-danger"
                >conditions générales d'utilisation</a
              >
              de Jours de Joie.
            </label>
            <label for="newsletter-checkbox">
              <input type="checkbox" id="newsletter-checkbox" />&nbsp; Je
              souhaite m'inscrire à la newsletter de Jours de Joie.
            </label>
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group text-center">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 10px;"
              v-if="!isLoading"
            >
              INSCRIPTION
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 10px;"
              disabled
              v-else
            >
              <spinner size="small" line-fg-color="#fff" />
            </button>
          </div>
        </div>
        <div class="col-md-12 text-center pb-3">
          Retourner à la page de
          <a
            href="javascript:void(0);"
            style="color:#b71c1c;font-weight: 900;"
            @click="$router.push('/connexion')"
            >Connexion</a
          >
          ?
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ValidationErrors from "@/components/common/ValidationErrors";
import moment from "moment";
import axios from 'axios'
import multiselect from "vue-multiselect";
import VueToggle from 'vue-toggle-component';
import { API_PREFIX, API_VERSION, PROVIDER } from "@/config";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import { IMaskDirective } from 'vue-imask';
// import VeeValidate from 'vee-validate';
// Vue.use(VeeValidate);

export default {
  name: "Register",
  components: {
    ValidationErrors,
    multiselect,
    VueToggle
  },
  data: function() {
    return {
      moment:moment,
      mask: {
          mask: '{8} 00 00 00 00 00',
          lazy: false
      },
      user: {
        civility: "",
        last_name: null,
        given_names: null,
        phone_number_1: null,
        phone_number_formatted:null,
        email: null,
        password: null,
        dob: null,
        wedding_date: null,
        type:'customer'
      },
      codes:[],
      providers:[],
      providerselected:null,
      codeselected:{"name":"Côte d'Ivoire", "flag":"https://flagcdn.com/ci.svg","callingCodes": 
            "225"
        ,},
      isLoading: false,
      validationErrors: "",
      exactDateType: true,
      user_wedding_date_number: null,
      user_wedding_date_surfix: null,
      showPassword: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      error:false,
    };
  },
  mounted() {
    this.codeCountries()
    this.provider()
  },
  computed: {
    
  },
  directives: {
      imask: IMaskDirective
  },
  methods: {
    validateEmail() {
        if (this.reg.test(this.user.email)) {
          this.error = false
        } else {
          this.error = true
        }
    },
    weddingDateFormatted() {
      let myDate = null;
      if (this.exactDateType == false) {

        let futureDate = moment()
          .add(
            Number(this.user_wedding_date_number),
            this.user_wedding_date_surfix
          )
          .calendar();
          
        myDate = moment(futureDate).format("YYYY-MM-DD");
        
      }
      return myDate;
    },
    onAccept (e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
      
    },
    onComplete (e) {
      const maskRef = e.detail;
      
    },
    passwordInputManagement() {
      this.showPassword = !this.showPassword;
    },
    onSubmit() {
      this.isLoading = true;
      if (!this.user.phone_number_1) {
        this.$createToast(
            {
            title: 'Attention!',
            description: "Veuillez renseigner votre numero de telephone!"
            },
            {
            position: 'top-center',
            type: 'warning',
            timeout: 10000,
            transition: 'bounce',
            }
        )
      }else{
        this.validationErrors = null;
        this.user.phone_number_formatted = '('+ this.codeselected.callingCodes+') '+ this.user.phone_number_1
        this.user.wedding_date = this.exactDateType == true ? this.user.wedding_date : this.weddingDateFormatted();
        
        this.$store
          .dispatch("register", this.user)
          .then((response) => {
            if (this.user.type == 'customer') {
                this.$store
                .dispatch("login", this.user)
                .then(() => {
                  this.isLoading = false;
                  this.$createToast(
                      {
                      title: 'Operation reussie !',
                      description: "Inscription effectuée avec succès !"
                      },
                      {
                      position: 'top-center',
                      type: 'success',
                      timeout: 10000,
                      transition: 'bounce',
                      }
                  )
                  window.location.href ="/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances";
                })
                .catch(() => {});
            }else{
              window.location.href ="https://joursdejoie.com/connexion";
            }
            
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error)
            if (error.response.status == 422) {
              this.validationErrors = error.response.data.errors;
            } else {
              this.errors = error.response.data.errors;
            }
          });
      }
      
    },
    goToLogin: function() {
      this.$router.push("/connexion");
    },

    codeCountries(){
      let app = this
      axios.get('https://restcountries.com/v3.1/all')
        .then((response) => {
          
          response.data.forEach(element => {
            element.name =element.name.common
            element.flag =element.flags.svg
            if (element.idd.suffixes) {
              element.callingCodes =element.idd.root+element.idd.suffixes[0]
            } else {
              element.callingCodes =element.idd.root+element.idd
            }
            // element.callingCodes =element.idd.root+element.idd.suffixes[0]
          });
          
          app.codes = response.data
        })
        .catch((error) => {
          
          this.isLoading = false;
        });
    },
    provider(){
      let app = this
      axios.get(PROVIDER.TEST_URL+API_PREFIX+API_VERSION+'wedding-provider-service-types')
        .then((response) => {
          
          app.providers = response.data.data
        })
        .catch((error) => {
          
          this.isLoading = false;
        });
    }
  },
};
</script>
<style>
p.category {
  display: none;
}

.wizard-title {
  display: none;
}
.wizard-header {
  display: none;
}
.wizard-tab-content {
  padding: 0px !important;
}
body {
  overflow-y: auto;
}
</style>
