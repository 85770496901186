<template>
  <div>
    <div
      style="background-image: url(/images/backgrounds/auth/cover.jpg); background-size: cover; background-repeat: no-repeat;"
    >
      <div class="row" style="max-height: 100vh;">
        <div class="col-md-6"></div>
        <!-- <div class="col-md-6"> -->
        <div class="col-md-6">
          <!-- <div class="backgroundContainer"> -->
          <slot />
          <!-- </div> -->
        </div>
        <!-- <section id="wrapper" class="login-register login-sidebar" style="background-image:url(/images/backgrounds/auth/cover.jpg);">
            <div class="">
                <div class="">
                    <div class="login-box card backgroundContainer">
                        <div class="card-body child">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.backgroundContainer::before {
}
.backgroundContainer {
  /* position: absolute; */
  /* z-index: -999; */
  background-image: url("@/images/backgrounds/auth/auth.png");
  background-size: contain;
  /* opacity: 0.1; */
  /* padding: 100%; */
}

.backgroundContainer::after {
  /* opacity: 0.1; */
}

.backgroundContainer .child {
  /* position: relative; */
  /* z-index: 999; */
  text-decoration: none;
  opacity: 1;
}
</style>

<script>
export default {
  name: "auth"
};
</script>
