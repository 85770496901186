<template>
  <div class="py-2 flex flex-col w-full text-sm px-1">
    <button class="flex justify-between filter-header bg-white border-b pb-2 items-center cursor-pointer" @click="toggleDisplay">
      <span class="text-left">Catégorie de service</span>&nbsp;
      <i :class="isDisplay ? 'fa-angle-up' : 'fa-angle-down'" class="text-gray-600 fa"></i>
      <!-- <font-awesome-icon class="text-gray-600" :icon="isDisplay ? 'fa-angle-up' : 'fa-angle-down'"></font-awesome-icon> -->
    </button>
    <div class="py-2 flex flex-col" v-show="isDisplay">
      <div class="py-1 hover:text-red-900" v-for="(serviceType, key) in data" :key="key">
        <button
            class="text-left bg-white"
            @click="onServiceTypeFilterSelected(serviceType.id,serviceType.name)"
            :class="{'text-red-800': currentServiceTypeId === serviceType.id}"
        >
          {{ serviceType.name }}({{ serviceType.providers_count }})
          <i class="fa fa-close" v-if="currentServiceTypeId === serviceType.id"></i>
          <!-- <font-awesome-icon v-if="currentServiceTypeId === serviceType.id" icon="fa-close"></font-awesome-icon> -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "filter-item-service-type",
  props: {
    data: {
      type: Array,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    currentServiceTypeId: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
      isDisplay: this.isOpen,
    }
  },
  mounted(){
    
  },
  
  methods: {
    toggleDisplay() {
      this.isDisplay = !this.isDisplay
    },
    onServiceTypeFilterSelected(serviceTypeId,serviceTypeName) {
      this.$ua.trackEvent("Categorie", "Click", serviceTypeName, null);
      this.$emit('service-type-filter-selected', serviceTypeId);
    },
  }
}
</script>