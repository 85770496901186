<template>
  <div class="bg-container-town-hall">
  
    <br />
    <br />
    <br />
    <div class="container" style="text-align:center;">
      <div class="row">
        <div class="col-md-12">
          <div
            class="h2"
            style="color:#b71c1c;font-weight:600;padding-top:30px;"
          >
            TROUVER LA MAIRIE DE VOTRE MARIAGE<br />
            <img
              class="img-fluid"
              src="/images/icon-flower.png"
              style="width:100px;height:auto;margin-top:-25px;margin-bottom:-25px;"
            />
          </div>
        </div>
      </div>
    </div>
    <town-hall-component></town-hall-component>
  </div>
</template>
<style>
.test select {
  box-shadow: none !important;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857;
  outline: medium none;
  padding: 0 9px;
  width: 100%;
  border-radius: 4px;
  color: #b71c1c;
}
.bg-container-town-hall {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-town-hall .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-town-hall::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/town-hall/town-halls.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import TownHallComponent from "@/components/town-hall/TownHallComponent";
import { useMeta } from 'vue-meta'
export default {
  setup () {
    useMeta({
      title: 'Mairies, Informations administratives pour la célébration de mariages civils',
      htmlAttrs: { lang: 'fr', amp: true },
      meta: [
      { charset: "utf-8" },
      {
        name: "keywords",
        content:
          "Info mairies- images de mairies en Côte d’Ivoire, Trouver des prestataires de mariage,Trouver les informations sur toutes les mairies de Côte d’Ivoire, marie d’Abidjan, horaire de célébration de mariage",
      },
      {
        name: "description",
        content: "Informations sur toutes les mairies de Côte d’Ivoire",
      },
    ],
    })
  },
  components: {
    TownHallComponent,
  },
};
</script>
