import { createStore } from 'vuex'
import axios from 'axios';
import { AUTH, API_PREFIX, API_VERSION } from "@/config";
import { NUPTIAL_NOTEBOOK } from "@/config";
import storeWedding from '../views/wedding-provider/store/index.js'
export default createStore({
    state: {
        isLoading: 0,
        token: localStorage.getItem("token") || "",
        user: localStorage.getItem("user") || null,
        cart: 0,
        customer_id: localStorage.getItem("customer_id") || null,
        customerPacks: [],
        archive: localStorage.getItem("archive") || null
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.isLoading,
        getCart: state => state.cart,
        getUser: state => state.user,
        getCustomerPacks: state => state.customerPacks,
        getNumberOfCustomerPacks: state =>
            state.customerPacks ? state.customerPacks.length : 0
    },
    setters: {
        //
    },
    mutations: {
        auth_request(state) {
            state.isLoading = 1;
        },
        auth_success(state, token, user) {
            state.isLoading = 0;
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.isLoading = 0;
        },

        register_request(state) {
            state.isLoading = 1;
        },
        register_success(state) {
            state.isLoading = 0;
        },
        register_error(state) {
            state.isLoading = 0;
        },

        logout_request(state) {
            state.isLoading = 1;
        },
        logout_success(state) {
            state.isLoading = 0;
            state.token = "";
        },
        logout_error(state) {
            state.isLoading = 0;
        },

        update_profile_request(state) {
            state.isLoading = 1;
        },
        update_profile_success(state) {
            state.isLoading = 0;
        },
        update_profile_error(state) {
            state.isLoading = 0;
        },

        get_user_info_request(state) {
            state.isLoading = 1;
        },
        get_user_info_success(state, user) {
            state.isLoading = 0;
            state.user = user;
        },
        get_user_info_error(state) {
            state.isLoading = 0;
        },

        get_customer_info_success(state, customer_id) {
            state.customer_id = customer_id;
        },

        addToCart(state) {
            state.cart = state.cart + 1;
        },
        removeFromCart(state) {
            state.cart = state.cart - 1;
        },
        raz(state) {
            state.cart = 0;
        },
        initialise(state) {
            const value = JSON.parse(localStorage.getItem("product_id"));
            state.cart = value.length;
        },

        ADD_PACK_TO_CART: function(state, pack) {
            state.customerPacks.push(pack);
        },
        REMOVE_PACK_TO_CART: function(state, key) {
            state.customerPacks.splice(key, 1);
        },
        CLEAR_PACK_CART: function(state) {
            state.customerPacks = [];
        },
        archivateUPdate: function(state) {
            state.archive = null;
        }
    },
    actions: {
        login({ commit }, user_credentials) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios
                    .post(
                        AUTH.TEST_URL + API_PREFIX + API_VERSION + "login",
                        user_credentials
                    )
                    .then(resp => {

                        if (resp.data.user.role.name == "CUSTOMER") {
                            const token = resp.data.token;
                            const user = resp.data.user;
                            const archivate = resp.data.customer[0].is_enable;
                            localStorage.setItem("token", token);
                            localStorage.setItem("user", JSON.stringify(user));
                            localStorage.setItem("archive", archivate);
                            commit("auth_success");
                            resolve(resp);
                        } else {
                            commit("auth_error");
                            localStorage.removeItem("token");
                            localStorage.removeItem("user");
                            reject({
                                success: false,
                                message:"Vous n'êtes pas un client"
                            });
                            // reject(resp)
                        }
                    })
                    .catch(err => {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        reject(err);
                    });
            });
        },
        register({ commit }, customer) {
            return new Promise((resolve, reject) => {
                commit("register_request");
                let user = {
                    last_name: customer.last_name,
                    given_names: customer.given_names,
                    phone_number_1: customer.phone_number_formatted,
                    email: customer.email,
                    password: customer.password,
                    type: customer.type,
                    name: customer.name,
                    service_type_id: customer.service_type_id,
                };

                axios
                    .post(AUTH.TEST_URL + API_PREFIX + API_VERSION + "register", user)
                    .then(resp => {
                        
                        let customerObj = {
                            civility: customer.civility,
                            last_name: customer.last_name,
                            given_names: customer.given_names,
                            email: customer.email,
                            phone_number_1: customer.phone_number_formatted,
                            dob: customer.dob,
                            wedding_date: customer.wedding_date,
                            user_id: resp.data.user.id
                        };
                        if (user.type == 'customer') {
                            axios
                                .post(
                                    NUPTIAL_NOTEBOOK.TEST_URL +
                                    API_PREFIX +
                                    API_VERSION +
                                    "customers",
                                    customerObj
                                )
                                .then(response => {

                                    commit("register_success");
                                    resolve(resp);
                                })
                                .catch(error => {
                                    commit("register_error");
                                    reject(error);
                                });
                        }else{
                            commit("register_success");
                            resolve(resp);
                        }
                        
                    })
                    .catch(err => {
                        commit("register_error");
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                // commit("logout_request");
                // localStorage.removeItem("token");
                // localStorage.removeItem("user");
                // localStorage.removeItem("customer");
                // localStorage.removeItem("user_email_verified");
                // localStorage.removeItem("customer_id");
                // delete axios.defaults.headers.common["Authorization"];
                // commit("logout_success");
                // resolve();
                const token = localStorage.getItem("token");
                axios.delete( AUTH.TEST_URL + API_PREFIX + API_VERSION + 'logout', {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                  })
                  .then(() => {
                    commit('logout_success')
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    localStorage.removeItem("customer");
                    localStorage.removeItem("user_email_verified");
                    localStorage.removeItem("customer_id");
                    localStorage.removeItem('token')
                    delete axios.defaults.headers.common['Authorization']
                    resolve()
                  })
                  .catch(err => {
                    commit('logout_error')
                    reject(err)
                  })
            });
        },
        update_profile({ commit }, user) {
            return new Promise((resolve, reject) => {
                commit("update_profile_request");
                const token = localStorage.getItem("token");
                axios({
                        url: AUTH.TEST_URL + API_PREFIX + API_VERSION + "profile/update",
                        data: user,
                        method: "POST",
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    })
                    .then(resp => {
                        commit("update_profile_success");
                        resolve(resp);
                    })
                    .catch(err => {
                        commit("update_profile_error", err);
                        reject(err);
                    });
            });
        },
        get_user_info({ commit }) {
            return new Promise((resolve, reject) => {
                commit("get_user_info_request");
                const token = localStorage.getItem("token");
                if (token) {
                    axios({
                        url: AUTH.TEST_URL + API_PREFIX + API_VERSION + "profile",
                        method: "GET",
                        headers: {
                            Authorization: "Bearer " + token
                        }
                    })
                    .then(resp => {
                        const user = resp.data.user;
                        
                        localStorage.removeItem("user");
                        localStorage.setItem("user", JSON.stringify(user));
                        commit("get_user_info_success", JSON.stringify(user));
                        resolve(resp);
                    })
                    .catch(err => {
                        commit("get_user_info_error", err);
                        reject(err);
                    });
                }
                
            });
        },
        archiveAccount({ commit }, customerId) {
            return new Promise((resolve, reject) => {
                axios
                    .put(
                        NUPTIAL_NOTEBOOK.TEST_URL +
                        API_PREFIX +
                        API_VERSION +
                        "customer-archive/" +
                        customerId, {
                            headers: {
                                Authorization: "Bearer " + token
                            }
                        }, { is_enable: null }
                    )
                    .then(response => {
                        commit("archivateUPdate");
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },

        get_customer_info({ commit }) {
            return new Promise((resolve, reject) => {
                let app = this;
                const user = JSON.parse(localStorage.getItem("user"));
                const token = localStorage.getItem("token");
                if (user) {
                    app.isLoading = true;
                    axios
                        .get(
                            NUPTIAL_NOTEBOOK.TEST_URL +
                            API_PREFIX +
                            API_VERSION +
                            "customers-by-user/" +
                            user.id, {
                                headers: {
                                    Authorization: "Bearer " + token
                                }
                            }
                        )
                        .then(response => {
                            const customer_id = response.data.id;
                            localStorage.setItem("customer_id", JSON.stringify(customer_id));
                            commit("get_customer_info_success", customer_id);
                            resolve(response);
                        })
                        .catch(error => {
                            commit("get_customer_info_error");
                            reject(error);
                        });
                }
                
            });
        },
        loginSocial({ commit }, social) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios
                    .post(AUTH.TEST_URL + "oauth/token", social)
                    .then(resp => {
                        axios
                            .get(
                                AUTH.TEST_URL +
                                API_PREFIX +
                                API_VERSION +
                                "userId/" +
                                social.userId
                            )
                            .then(response => {
                                // let userId = response.data.id
                                
                                // if(response.data.role.name == 'CUSTOMER') {
                                //   const token = resp.data.access_token;
                                //   const user = response.data;
                                //   localStorage.setItem('token', token);
                                //   localStorage.setItem('user', JSON.stringify(user));
                                //   commit('auth_success');
                                //   resolve(resp);
                                // } else {
                                //   commit('auth_error');
                                //   localStorage.removeItem('token');
                                //   localStorage.removeItem('user');
                                //   reject(resp);
                                // }
                            })
                            .catch(() => {});
                    })
                    .catch(err => {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        reject(err);
                    });
            });
        },

        addPackToCart: function({ commit }, pack) {
            commit("ADD_PACK_TO_CART", pack);
        },
        removePackToCart: function({ commit }, key) {
            commit("REMOVE_PACK_TO_CART", key);
        },
        clearPackCart: function({ commit }) {
            commit("CLEAR_PACK_CART");
        }
    },
    modules: {
        storeWedding
    }
})