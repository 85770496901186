<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="channel-choice-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Envoyez votre Message Via" gender="F" />
          </div>
          <div class="col-md-12">
            <div class="alert alert-info" v-if="remainingMessages == null">
              <div class="text-center">
                Veuillez s'il vous plaît choisir le canal d'envoi !
              </div>
            </div>
            <div class="alert alert-success" v-if="remainingMessages > 0">
              <div class="text-center">
                Vous disposez de {{ remainingMessages }} messages.
              </div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages == 0">
              <div class="text-center">
                Vous disposez de {{ remainingMessages }} messages.
              </div>
            </div>
            <div class="alert alert-danger" v-else-if="remainingMessages < 0">
              <div class="text-center">
                Veuillez immédiatement contacter l'administrateur svp au
                0709525460 !!!!
              </div>
            </div>
            <div
              class="alert alert-success"
              v-else-if="remainingMessages === 'indéfini'"
            >
              <div class="text-center">Illimité</div>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <!-- <div
              class="col-md-12"
              v-if="
                message &&
                message.channel &&
                message.channel.id == 3 &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              "
            >
              <div class="alert alert-danger text-center">
                Le nombre total de message à envoyer est :
                <span class="badge badge-secondary">{{
                  message.messageNumber +
                  " message(s)" +
                  " x " +
                  message.tab.length +
                  " invité(s) = " +
                  Number(message.messageNumber) * message.tab.length +
                  " message(s)"
                }}</span>
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="
                message &&
                message.channel &&
                message.channel.id == 3 &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              "
            >
              <div class="alert alert-danger text-center">
                Vous aurez à payer :
                <span
                  class="badge badge-secondary"
                  v-if="messageSellingPrice == 0"
                  >{{
                    message.channel.selling_price +
                    " FCFA x " +
                    Number(message.messageNumber) * message.tab.length +
                    " message(s) " +
                    "  = " +
                    messageSellingPrice +
                    " FCFA"
                  }}</span
                >
                <span class="badge badge-secondary" v-else>{{
                  message.channel.selling_price +
                  " FCFA x " +
                  (Number(message.messageNumber) * message.tab.length -
                    Number(message.customerChannel.pivot.remaining_messages)) +
                  " message(s) " +
                  "  = " +
                  messageSellingPrice +
                  " FCFA"
                }}</span>
              </div>
            </div> -->
          </div>
          <div class="row" v-if="channels != null && customerChannels != null">
            <div
              class="col-md-4 text-center"
              v-for="(channel, key) in channels"
              :key="key"
            >
              <div class="form-group">
                <label :for="'channel' + key">
                  <img :src="channel.image" style="width: 70%" alt srcset />
                  <br />
                  <br />
                  <input
                    @change="onChannelChange(channel)"
                    :value="channel"
                    type="radio"
                    name="channel"
                    :id="'channel' + key"
                  />&nbsp;
                  <label :for="'channel' + key" style="font-size: 16px">{{
                    channel.name
                  }}</label>
                </label>
              </div>
            </div>
          </div>
          <div
            v-else-if="channels == null || customerChannels == null"
            class="col-md-12"
          >
            <div class="col-md-12 text-center">
              <moonloader />
            </div>
            <!-- <p class="text-center">Chargement en cours ...</p> -->
          </div>
          <div class="col-md-12" v-if="channel != null">
            <button
              class="primary-button pull-right"
              @click="goToTargetChoice"
              v-if="channel.id != 1"
            >
              SUIVANT
              <i class="fa fa-chevron-right" style="color: #fff"></i>
            </button>
            <button class="primary-button pull-right" @click="goToTargetChoice" v-else>
              SUIVANT
              <i class="fa fa-chevron-right" style="color: #fff"></i>
            </button>
            <!-- <ShareNetwork
              @open="open" @change="change" @close="close"
              network="whatsapp"
              :style="{backgroundColor: '#25d366'}"
              url="https://news.vuejs.org/issues/180"
              title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
              description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            >
              <i :class="'fab fah fa-lg fa-whatsapp'"></i>
              Share on Facebook
          </ShareNetwork> -->

            

            <!-- <button class="primary-button pull-right" v-else disabled>
              SUIVANT
              <i class="fa fa-chevron-right" style="color:#fff;"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.channel-choice-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
</style>

<script>
import { ref } from "vue";

import { defineComponent } from "vue";
import axios from "axios";
import {
  COMMUNICATION,
  API_PREFIX,
  API_VERSION,
  URL_SITE_WEB,
  NUPTIAL_NOTEBOOK,
} from "@/config";

export default defineComponent({
  name: "ChannelChoiceComponent",
  

  

  data: function () {
    return {
      channels: null,
      customerChannels: null,
      channel: null,
      remainingMessages: null,
      customerChannel: null,
      
    };
  },
  computed: {
    messageSellingPrice: {
      get: function () {
        if (
          Number(this.message.customerChannel.pivot.remaining_messages) <
          Number(this.message.messageNumber) * this.clientcounter
        ) {
          return (
            Number(this.message.channel.selling_price) *
            (Number(this.message.messageNumber) * this.clientcounter -
              Number(this.message.customerChannel.pivot.remaining_messages))
          );
        } else {
          return 0;
        }
      },
      set: function (message) {
        this.clientcounter = message.tab.length;
      },
    },
  },
  props: ["message", "mode"],
  mounted: function () {
    this.getChannels().then(() => {
      this.getCustomerChannels();
    });
  },
  methods: {
    onClose() {},
    onOpen() {},
    onBlock() {},
    onFocus() {},
    getChannels: function () {
      return new Promise((resolve, reject) => {
        let app = this;
        axios
          .get(COMMUNICATION.TEST_URL + API_PREFIX + API_VERSION + "channels", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            app.channels = response.data;
            resolve(app.channels);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    goToPayment() {
      let app = this;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });

      // app.createdMessage = {
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id:
      //   app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // };

      // app.message.channel_id = app.message.channel ? app.message.channel.id : null,
      (app.message.channel_id = this.channel ? this.channel.id : null),
        (app.message.label = app.message.label),
        (app.message.message_category_id = app.message.category.id),
        (app.message.message_model_id = app.message.model
          ? app.message.model.id
          : null),
        (app.message.message_design_id = app.message.layout
          ? app.message.layout.id
          : null),
        (app.message.from = app.message.from),
        (app.message.model = app.message.model);
      (app.message.subject = app.message.subject),
        (app.message.body = app.message.body),
        (app.message.tab = app.message.tab),
        (app.message.target = targetIds.join(";"));

      this.message.sellingPrice = this.message.channel.selling_price;
      console.log("SELLING PRICE ", this.message);
      this.$emit("go-to-payment", app.message);
    },
    goToTargetChoice() {
      let app = this;
      let message_r = app.message;
      if (this.message.mode.name === "Site web") {
        this.message.channel= app.channel
        this.message.customerChannel=app.customerChannel
        // = {
        //   channel: app.channel,
        //   label: "Annonce de mariage !",
        //   mode: app.message.mode,
        //   category: app.message.category_id,
        //   model: null,
        //   layout: null,
        //   lorem: app.message.lorem,
        //   ipsum: app.message.ipsum,
        //   messageLength:
        //     app.message.mode.id_mode_joycard === 2
        //       ? 1
        //       : app.channel.id === 3
        //       ? app.message.messageLength
        //       : 1,
        //   messageNumber:
        //     app.message.mode.id_mode_joycard === 2
        //       ? 1
        //       : app.channel.id === 3
        //       ? app.message.messageNumber
        //       : 1,
        //   peopleCount: null,
        //   customerChannel: app.customerChannel,
        // };
      } else {
        // if (app.messagePerso) {
        //   message_r = {
        //     channel: app.channel,
        //     label: app.message.label,
        //     category: app.message.category,
        //     model: app.message.model,
        //     layout: null,
        //     mode: app.message.mode,
        //     from: app.message.from,
        //     subject: app.message.subject,
        //     body: app.message.body,
        //     targets: [],
        //     tab: [],
        //     displayMessage: app.messagePerso,
        //     messageLength: app.channel.id === 3 ? app.message.messageLength : 1,
        //     messageNumber: app.channel.id === 3 ? app.message.messageNumber : 1,
        //     peopleCount: null,
        //     customerChannel: app.customerChannel,
        //   };
        // } else {
          app.message.channel = app.channel
          app.message.customerChannel = app.customerChannel
          app.message.messageLength= app.channel.id === 3 ? app.message.messageLength : 1
          app.message.messageNumber= app.channel.id === 3 ? app.message.messageNumber : 1
          // = {
          //   channel: app.channel,
          //   label: app.message.label,
          //   category: app.message.category,
          //   model: app.message.model,
          //   layout: app.message.layout,
          //   from: app.message.from,
          //   mode: app.message.mode,
          //   subject: app.message.subject,
          //   body: app.message.body,
          //   targets: [],
          //   tab: [],
          //   displayMessage: app.message.displayMessage,
          //   messageLength: app.channel.id === 3 ? app.message.messageLength : 1,
          //   messageNumber: app.channel.id === 3 ? app.message.messageNumber : 1,
          //   peopleCount: null,
          //   customerChannel: app.customerChannel,
          // };
        // }
      }

      this.$emit("go-to-target-choice", app.message, this.channel);
    },
    // goToMessageBuilder: function() {
    //   let app = this;
    //   app.$emit("go-to-message-builder", app.channel,);
    // },
    getCustomerChannels: function () {
      let self = this;
      self.isLoading = true;
      axios
        .get(
          COMMUNICATION.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function (response) {
          self.customerChannels = response.data;
          self.isLoading = false;
          app.customerChannels = response.data;
        })
        .catch(function (error) {
          console.log(error);
          self.isLoading = false;
        });
    },
    disabledChannel: function (channel) {
      this.customerChannels.forEach((item) => {
        if (channel.id == item.id) {
          return item.pivot.remaining_messages;
        }
      });
    },
    onChannelChange: function (channel) {
      let app = this;
      this.channel = channel;
      this.message.channel = channel;
      console.log("CUSTOMER CHANNEL ", this.customerChannels);
      this.customerChannels.forEach((item) => {
        if (channel.id == item.id) {
          if (channel.id != 3) {
            this.remainingMessages = "indéfini";
          }
          this.remainingMessages = item.pivot.remaining_messages;
          this.customerChannel = item;
          app.message.customerChannel = this.customerChannel;
        }
      });
    },
  },
});
</script>
