<template>
  <div style="width: 100%;background-color: #fff;">
    <form
      style="margin: 0 auto;height: 100vh;background-image: url('/images/backgrounds/auth/auth.png');background-size: contain;z-index: -999;"
      class="p-5"
      id="form-input"
      @submit.prevent="onSubmit"
    >
      <div class="row" style="background-color: #fff;z-index: 999;">
        <div class="col-md-12">
          <div style="text-align: center;" class="pt-5 pb-4">
            <h3 class="font-weight-light">Mot de passe oublié</h3>
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group">
            <input
              class="auth-input"
              type="email"
              v-model="user.email"
              placeholder="Adresse email"
              required
            />
          </div>
        </div>
        <div class="col-md-12 pb-3">
          <div class="form-group text-center">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 15px;"
              v-if="!isLoading"
            >
              ENVOYER
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-block"
              style="border-radius: 30px;padding: 15px;"
              disabled
              v-else
            >
              <spinner size="small" line-fg-color="#fff" />
            </button>
          </div>
        </div>

        <div class="col-md-12 form-group m-b-0 p-t-5 text-center">
          Retourner à la page de
          <a href="javascript:void(0);" @click="goToLogin" class="text-primary"
            ><b>connexion</b></a
          >
          ?
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped></style>

<script>
import ValidationErrors from "@/components/common/ValidationErrors";
import { AUTH, API_PREFIX, API_VERSION } from "@/config";

import axios from "axios";
export default {
  name: "RecoverPassword",
  data: function() {
    return {
      user: {
        email: null,
        password: null
      },
      error: false,
      isLoading: false,
      status: null,
      social: {
        grant_type: "social",
        client_id: 2,
        client_secret: "jHgvL5xHIGvxU9ltY5dewowAuLTxTnpODbw08AYX",
        provider: "facebook",
        access_token: null
      }
    };
  },
  components: {
    ValidationErrors
  },
  mounted: function() {
    //
  },
  methods: {
    goToLogin: function() {
      this.$router.push("/connexion");
    },
    onSubmit: function() {
      this.isLoading = true;
      axios
        .post(
          AUTH.TEST_URL + API_PREFIX + API_VERSION + "password-reset-email",
          {
            email: this.user.email
          }
        )
        .then(response => {
          this.isLoading = false;
          this.$router.push("/connexion");
         
          this.$createToast(
              {
              title: 'Operation reussie !',
              description: "Mail de réinitialisation de mot de passe envoyé avec succès  !"
              },
              {
              position: 'top-center',
              type: 'success',
              timeout: 10000,
              transition: 'bounce',
              }
          )
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
          this.$createToast(
            {
            title: 'Attention !',
            description: "Erreur lors de l'envoie de mail "
            },
            {
            position: 'top-center',
            type: 'error',
            timeout: 10000,
            transition: 'bounce',
            })
        });
    }
  }
};
</script>
