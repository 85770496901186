<template>
  <div class="bg-container-budgets">
    <div>
      <Bar />
      <div class="container p-3" style="padding: 25px;">
        <big-title label="BUDGET" />
        <br />
        <!-- <p class="text-center">
          Un mariage réussi dépend d’une bonne organisation, mais aussi de la
          maîtrise des dépenses. <br />
          Contrôlez chaque dépense de votre mariage pour qu'il n'y ait pas de
          surprises grâce à la rubrique « Budget. »
        </p> -->

        <p class="text-center">
          Si pour se marier il faut de l’amour, cet élément représente<br />
          autant d’importance. Car pour que tous nos désirs et rêves se
          réalisent ; <br />
          il faut mettre la main au portefeuille ! <br />
          La rubrique “Budget” vient vous aider à définir<br />
        </p>

        <div class="text-center">
          <p class="collapse" id="collapseB">
            un budget adapté à votre mariage.<br />
            De quoi avez-vous besoin : Vous devez avoir rempli votre rubrique
            “En bref”, <br />
            avoir accès à une bonne connexion internet, faire preuve d’un tout
            petit peu de patience.<br />
            Une fois votre fiche de budget remplie, vous vous sentiez maîtres de
            vos dépenses<br />
            car vous pourrez scrupuleusement suivre leur évolution et anticiper
            les imprévus.<br /><br />

            <strong>Le saviez-vous :</strong><br />
            les frais partagés ce sont, les faire-part, la location de la
            salle<br />
            de réception, la musique, les cadeaux invités et les cadeaux aux
            témoins,<br />
            le transport des invités si nécessaire.
          </p>
          <a
            class="btn btn-link btn-sm"
            style="color: #b71c1c; text-decoration: none;"
            data-toggle="collapse"
            href="#collapseB"
            role="button"
            aria-expanded="false"
            aria-controls="collapseB"
            @click="textFullDisplay = !textFullDisplay"
          >
            <strong>{{
              textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
            }}</strong>
          </a>
        </div>
        <br />

        <div v-if="isLoading == true">
          <spinner  /><br /><br />
        </div>
        <div
          class=""
          v-else-if="user.email_verified_at != null && customer.dob != null"
        >
          <div
            class="row"
            v-if="isLoading == false && customer != null && budgets != null"
          >
            <div class="col-md-12">
              <div class="pull-left noScreen">
                <div class="font-weight-bold">Date: {{ dateNow }}</div>
              </div>
              <div class="pull-right noPrint">
                <button @click="print" class="default-button no-print">
                  <i class="fa fa-print"></i> Imprimer
                </button>
              </div>
            </div>
            <div
              class="col-md-12"
              v-for="(item, index) in budgets"
              :key="index"
            >
              <budget-component
                :budgetProps="item"
                :index="index"
                @update-budget="updateBudget"
              />
            </div>
            <div class="col-md-12">
              <table>
                <tr>
                  <th style="font-size: 20px">Budget Mini</th>
                  <td
                    class="font-weight-bold"
                    style="text-align:right;color:#b71c1c;font-size: 20px"
                  >
                    {{ formatPrice(totalMin) }} Fcfa
                  </td>
                </tr>
                <tr>
                  <th style="font-size: 20px">Budget Reel</th>
                  <td
                    class="font-weight-bold"
                    style="text-align:right;color:#b71c1c; font-size: 20px"
                  >
                    {{ formatPrice(totalReal)  }} Fcfa
                  </td>
                </tr>
                <tr>
                  <th style="font-size: 20px">Budget Max</th>
                  <td
                    class="font-weight-bold"
                    style="text-align:right;color:#b71c1c; font-size: 20px"
                  >
                    {{ formatPrice(totalMax)  }} Fcfa
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div v-else>
            <spinner  /><br /><br />
          </div>
          <div class="row noScreen">
            <div class="col-md-12">
              <p style="text-align:center;">
                Généré depuis
                <a style="color:#b71c1c;" href="#">Joursdejoie.com</a>
              </p>
            </div>
          </div>
        </div>
        <saved-modal :user="user" @resendMailAccountVerification="resendMailAccountVerification" v-show="showModal" @close-modal="showModal = false" />
        <center>
          <button class="default-button" @click="goToPrevious">
            PRÉCÉDENT</button
          >&nbsp;
          <button class="default-button" @click="goToNext">SUIVANT</button>
        </center>
      </div>
    </div>
  </div>
</template>

<style scoped>
#text {
  width: 40%;
  /* overflow: hidden;
        display: inline-block;
        white-space: nowrap; */
}

#min,
#max,
#real {
  width: 20%;
  /* overflow: hidden;
        display: inline-block;
        white-space: nowrap; */
}

.bg-container-budgets {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-budgets .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-budgets::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/budgets.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import BudgetComponent from "@/components/nuptial-notebook/BudgetComponent";
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import Bar from "@/components/nuptial-notebook/Bar.vue";
import axios from "axios";
import { useMeta } from 'vue-meta'

export default {
  name: "CustomerBudgets",
  setup () {
    useMeta({
      title: "Carnet Nuptial - Budgets - Maîtriser le budget de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Planifier son mariage en Côte d'Ivoire",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, comment planifier son mariage, comment élaborer le budget de son mariage, dépenses du mariage, mariage à petit budget, mariage à gros budget, dot, prix robe de mariée, prix d’alliance, prix de salle de réception à Abidjan, prix d’un wedding planner,event planner",
        },
      ],
    })
  },
  components: {
    BudgetComponent,
    Bar,
  },
  data: function() {
    return {
      showModal:false,
      isLoading: false,
      budgets: null,
      budgetForms: null,
      customer: {dob:null},
      dateNow: new Date().toLocaleString(),
      totalMin: 0,
      totalMax: 0,
      totalReal: 0,
      user: JSON.parse(this.$store.state.user),
      textFullDisplay: false,
    };
  },
  mounted: function() {
    this.show();
    this.getCustomerInfo()
      .then((customer) => {
        this.getBudgets(customer.event.id);
      })
      .catch(() => {});
    
  },
  methods: {
    formatPrice(value) {
                let val = (value/1).toFixed(0).replace(' ', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    print: function() {
      window.print();
    },
    goToPrevious: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/comite-restreint"
      );
    },
    goToNext: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage"
      );
    },
    getCustomerInfo: function() {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.TEST_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //     this.$router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getBudgets: function(eventId) {
      this.isLoading = true;
      let app = this;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "budgets-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          app.budgets = response.data;

          this.budgets.forEach((item) => {
            item.children.forEach((item2) => {
              this.totalMin += Number(item2.pivot.min_amount);
              this.totalMax += Number(item2.pivot.max_amount);
              this.totalReal += Number(item2.pivot.real_amount);
            });
          });
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.$createToast(
            {
            title: 'Attention !',
            description: "Erreur lors de la récupération des informations !"
            },
            {
            position: 'top-center',
            type: 'error',
            timeout: 10000,
            transition: 'bounce',
            }
          )
            
          this.isLoading = false;
        });
    },
    updateBudget: function(budget) {
      
      this.isLoading = true;
      axios
        .post(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "budgets/events",
          budget,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.getBudgets(this.customer.event.id);
          this.isLoading = false;
          this.$createToast(
              {
                title: 'Operation reussie !',
                description: "Informations enregistrées avec succès !  !"
              },
              {
                position: 'top-center',
                type: 'success',
                timeout: 10000,
                transition: 'bounce',
              }
          );
        })
        .catch((error) => {
          console.log("error", error.response.data);
         
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'enregistrement des informations !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    resendMailAccountVerification: function(customerEmail) {
      this.isLoading = true;
      axios
        .get(
          AUTH.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "send-email-verification/" +
            customerEmail,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Mail envoyé avec succès !"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.$createToast(
          {
          title: 'Attention !',
          description: "Erreur lors de l'envoie de mail !"
          },
          {
          position: 'top-center',
          type: 'error',
          timeout: 10000,
          transition: 'bounce',
          })
          this.isLoading = false;
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal= true
      }
    },
  },
};
</script>
