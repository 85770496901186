<template>
	<div v-bind:style="{backgroundImage:bgUrl,height:hauteur+'px'}" :class="bgClass" class="d-flex flex-column justify-content-center no-print">
		<div v-if="titre" style="background-color:rgba(255,255,255,0.8);width:330px;padding-top:20px;padding-bottom:20px;">
			<h2 style="color:#b71c1c;;word-wrap: normal;" class="text-center text-uppercase text-nowrap bd-highlight">{{ titre }}</h2>
		</div>
	</div>
</template>
<style scoped>
        @media(min-width:800px){


    }
    @media screen and (min-width: 900px) {
        .bg-image--visibility {
            height: 400px;
            background-position: left center;
            background-size: 100%;
        }
    }
    @media screen and (max-width: 900px) {
        .bg-image--visibility {
            height: 300px !important;
            background-position: left;
            background-size: 191% auto;
        }
    }
</style>

<script>
export default {
    props :{
        titre : {
            type : String,
            default : ''
        },
        bgClass : {
            type: String,
            default : ''
        },

        bgUrl : {
            type : String,
            default : ''
        },

        hauteur :  {
            type : Number,
            default : 400
        },
        mt : {
            type : Number,
            default : 500
        }
    }
}
</script>
