<template lang="">
    <div >
        <header style="display:flex;align-items:center;justify-content:center;background-color:white;padding:5px;box-shadow:none;">
            <button class="secondary-button pull-left"
                  @click="goToPre" style="display:magin-left:20px;">
                  PRECEDENT
                  <i class="fa fa-chevron-right" style="color:#fff;"></i>
                </button>
                <img
                  class="mx-auto"
                  src="/images/logo.png"
                  style="height:90px;"
                  alt=""
                />
                <button class="primary-button pull-right"
                v-if="nav===4"
                @click="goToSui" style="display:magin-right:20px;">
                VALIDER
              </button>
                <!-- <img class="mx-auto" src="/images/logo2.jpeg" style="height:90px;" alt=""> -->
                <!-- <img class="mx-auto" src="/images/old_img/logo.png" alt=""> -->
        </header>
        
    </div>
</template>
<script>

import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import store from '@/store'

export default {
    name:'HeaderTemplateComponent',
    props:["nav"],
    methods:{
      goToPre(){
        this.$emit('click-on-pre')
      },
      goToSui(){
        console.log('ThougthsWife@@@ :',store.getters.getThoughtsWife)
        this.$emit('click-on-sui')
      }
    }
    
}
</script>
<style lang="">
    
</style>