<template>
  <div id="app">
    <file-pond
      :maxFiles="allowMultiples === true ? '10' : '1'"
      name="test"
      ref="pond"
      class-name="my-pond"
      label-idle="Glissez et/ou déposez votre(vos) image(s) !"
      allow-multiple="allowMultiples"
      required="true"
      accepted-file-types="image/jpeg, image/png"
      v-bind:files="myFiles"
      v-on:init="handleFilePondInit"
      @addfile="addFiles"
      @addfilestart="testAddFileStart"
      @removefile="removeFiles"
    />
  </div>
</template>

<script>
import store from "@/store";

import vueFilePond from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "app",
  data: function () {
    return { myFiles: [], nbr: 0, existing: false };
  },
  mounted() {
    // this.getImages();
  },
  props: ["allowMultiples", "name", "caracteristic_user_template"],
  methods: {
    handleFilePondInit: function () {
      // console.log("FilePond has initialized");
      console.log(
        "CARACTERISTIC_USER_TEMPLATE ",
        this.caracteristic_user_template.photo_celebrations
      );
      try {
        if (this.caracteristic_user_template != undefined) {
          if (this.caracteristic_user_template.photo_celebrations.length > 0) {
            this.caracteristic_user_template.photo_celebrations.forEach(
              (element, index) => {
                if (this.name === element.name) {
                  
                  if (element.value) {
                    this.$refs.pond.addFile(
                      this.caracteristic_user_template.photo_fiance
                    );
                  }
                }
              }
            );
          }else{

          }
          if (
            this.caracteristic_user_template.photo_fiance != "" ||
            this.caracteristic_user_template.photo_fiance != undefined
          ) {
            this.$refs.pond.addFile(
              this.caracteristic_user_template.photo_fiance
            );
          }
          if (
            this.caracteristic_user_template.photo_story.isArray === true &&
            this.caracteristic_user_template.photo_fiances.length > 0
          ) {
            this.caracteristic_user_template.photo_story.forEach(
              (element, index) => {
                if (this.name === element.name) {
                  this.$refs.pond.addFile(element.value);
                }
              }
            );
          }
          if (this.caracteristic_user_template.photo_fiancee != "") {
            this.$refs.pond.addFile(
              this.caracteristic_user_template.photo_fiancee
            );
          }
          if (
            this.caracteristic_user_template.photo_fiances.isArray === true &&
            this.caracteristic_user_template.photo_fiances.length > 0
          ) {
            this.caracteristic_user_template.photo_fiances.forEach(
              (element, index) => {
                this.$refs.pond.addFile(element.value);
              }
            );
          }
          if (
            this.caracteristic_user_template.photo_fiances.isArray === false &&
            this.caracteristic_user_template.photo_fiances.length > 0
          ) {
            this.$refs.pond.addFile(
              this.caracteristic_user_template.photo_fiances
            );
          }
          if (this.caracteristic_user_template.albumphotos.length > 0) {
          }
        }
      } catch (e) {}

      // if(this.caracteristic_user_template[`${this.name}`]!=undefined || this.caracteristic_user_template[`${this.name}`]!=""){
      //   this.$refs.pond.addFile(this.caracteristic_user_template[`${this.name}`])
      // }if(this.name==="albumphoto"){
      //   this.caracteristic_user_template.albumphotos.forEach((element,index)=>{
      //       this.$refs.pond.addFile(element.value)
      //   })
      // }
      // console.log('CARACTERISTIC_USER_TEMPLATE',this.caracteristic_user_template)
      this.$refs.pond.getFiles();
    },
    testAddFileStart: function () {
      if (this.allowMultiples === true) {
      } else {
        this.$refs.pond.removeFile(1);
        // console.log("SUPPRIME");
      }
    },
    removeFiles: function (error, file) {
      let file_f = file.file;
      this.$emit("delete-img", { name: this.name, value: file_f });
    },
    addFiles: function (error, file) {
      let size = this.$refs.pond.getFiles().length;
      let files_pond = this.$refs.pond.getFiles();
      let element = files_pond[0];
      let valid = element.fileSize > 1000000 ? false : true;
      if (valid) {
        if (this.name === "albumphoto") {
          this.nbr += 1;
        }
        let size = this.$refs.pond.getFiles().length;
        let files_pond = this.$refs.pond.getFiles();

        let element = files_pond[0];

        if (this.name.includes("albumphoto") === true) {
          
          this.$emit("save-img", {
            name: this.name + "_" + this.nbr,
            value: element.file,
          });
        } else {
          this.$emit("save-img", { name: this.name, value: element.file });
        }
      } else {
        this.$refs.pond.removeFile(file);
        const toast = () => {
          createToast("L'image insérée doit être inferieure à 1MB !");
        };
        toast();
      }
    },
    getImages() {
      // let imgs = store.getters.getImg;
      let imgs_specific = imgs.filter(
        (element) => element.name.includes(this.name) === true
      );

      const FilePond = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview
      );
      imgs_specific.forEach((element) => {
        this.$refs.pond.addFiles(element.value);
      });

      // console.log("IMGS :", imgs_specific);

      // console.log(
      "FIND IMG :",
        imgs.find((element) => element.name.includes(this.name) === true);
      // );
      // console.log("GETTERS IMGS", imgs);
    },
  },
  components: {
    FilePond,
  },
};
</script>
<style>
.filepond--root {
  max-height: 50em;
}
</style>
