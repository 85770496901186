<template>
  <div class="flex flex-col w-full">
    <div class="flex font-weight-bold">
      Adresse
    </div>
    <hr />
    <div class="flex flex-col md:flex-row" v-if="addresses">
      <address-item-component
          v-for="(address, key) in addresses"
          :key="key"
          :address="address" @on-address-click="onAddressClick(address)"
      ></address-item-component>
    </div>
    <hr />
    <div class="maps">
      <div v-if="currentAddress" style="margin:0px;padding:0px;overflow:hidden">
        <!-- <iframe
          height="256"
          width="100%"
          style="border:0;overflow:hidden;height:256px;width:100%"
          loading="lazy"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyC4YnvQ9y2DwnKKX0U7YCDDSkLlz-aBhKg
                  &q=${currentAddress}`">
        </iframe> -->
        <iframe
            width="100%"
            height="450"
            frameborder="0"
            style="border:0"
            :src="
              `https://www.google.com/maps/embed/v1/place?q=${currentAddress}&key=AIzaSyC4YnvQ9y2DwnKKX0U7YCDDSkLlz-aBhKg`
            "
            allowfullscreen
          >
          </iframe>
      </div>


      <div class="w-full" v-else>
        Adresse non definie
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AddressListComponent",
  props: {
    addresses: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      currentAddress: null
    }
  },
  mounted() {
    console.log(this.addresses)
    if (this.addresses.length>0) {
        if (this.addresses[0].latitude ) {
          this.currentAddress = this.addresses[0].latitude + '%2C'  + this.addresses[0].longitude;
        }else{
          this.currentAddress = this.addresses[0].town_name + ','  + this.addresses[0].district_name;
        }
        
      }
    // this.currentAddress= this.addresses && this.addresses[0] ? this.addresses[0].latitude + '%2C'  + this.addresses[0].longitude : this.addresses && this.addresses[0] && this.addresses[0].town_name + ', ' + this.addresses[0].district_name
  },
  methods: {
    onAddressClick(address) {
      if (address.street && address.town_name && address.district_name) {
        if (address.latitude ) {
          this.currentAddress = address.latitude + '%2C'  + address.longitude;
        }else{
          this.currentAddress = address.town_name + ','  + address.district_name;
        }
        
      }
    }
  }
}
</script>‹
