<template>
    <div>
        <Bar />
        <div class="container-fluid pr-5 pl-5" style="padding: 20px;">
            <big-title label="Mes demandes de prestatations" />

            <br />
            <!-- <p class="text-center">
        Les choses se précisent ! Comme son nom l’indique, la rubrique «
        Planning » vous aide à planifier <br />
        les actions importantes de votre mariage. Mettez à jour votre liste de
        tâches, plus rien ne vous échappera !
      </p> -->
            <p class="text-center">
                Vous êtes bien lancés dans l’organisation de votre mariage, l’un des plus beaux jours de votre vie.<br />
                Vous n’avez négligé aucun détail. Vous avez sélectionné avec minutie, sur JOY SHOP de joursdejoie.com, les
                prestataires qui vont rendre réel ce rêve. <br />
                Grâce à JOY SHOP de joursdejoie.com, vous les avez contactés des wedding planners, les stylistes, des
                décorateurs, des joailliers, des pâtissiers, des lieux de réception, des hôtels, des services traiteurs,
                etc<br />
                Vous leur avez demandé des devis et le détail de leurs services et produits. <br />
                Pour ne manquer aucun détail, joursdejoie.com retrace toutes vos échanges de l’acceptation du service
                jusqu’à sa réalisation complète<br>
                Relax, joursdejoie.com s’occupe de tout et organiser son mariage devient passionnant.<br />
            </p> <br>

            <div class="text-center">
                <div class="collapse" id="collapseP">
                    <p>
                        A quels prestataires avez-vous décidé de confiez la réalisation de votre mariage de rêve ? <br>
                        Qui est votre Wedding/Event Planner ? Avec qui allez-vous commander vos robes et costumes de mariage
                        ? <br>
                        Qui a confectionné les magnifiques alliances que vous porterez à vie ? Quel service traiteur égayera
                        les papilles de vos convives ? <br>
                        Qui exécutera le splendide gâteau de mariage auquel vous avez toujours rêvé… <br />
                    </p> <br>
                    <p>
                        Avec JOY SHOP de joursdejoie.com, la question ne se pose plus. <br />
                        Parcourez JOY SHOP, notre annuaire des professionnels des évènements heureux et <br />
                        sélectionner les prestataires qui répondront au mieux à vos attentes (qualité/prix).

                    </p> <br>
                    <p>
                        Mettez des cœurs sur vos prestataires favoris et vous verrez leurs boutiques s’afficher dans votre
                        carnet nuptial. <br />
                        Waouh ! JOY SHOP de joursdejoie.com c’est trop Top...
                    </p>
                </div>

                <a class="btn btn-link btn-sm" style="color: #b71c1c; text-decoration: none;" data-toggle="collapse"
                    href="#collapseP" role="button" aria-expanded="false" aria-controls="collapseP"
                    @click="textFullDisplay = !textFullDisplay">
                    <strong>{{
                        textFullDisplay == false ? "+ VOIR PLUS" : "- VOIR MOINS"
                    }}</strong>
                </a>
            </div>
            <div class="container p-0" v-if="!isLoading">
                <div class="row clearfix">
                    <div class="col-lg-12 p-0" style="position: relative;">
                        <div class="card chat-app row">
                            <div id="plist" class="people-list">
                                <ul class="list-unstyled chat-list mt-2 mb-0">
                                    <li class="clearfix" v-for="(object, index) in favoritesListe"
                                        @click="conversationselect(object, index)" 
                                        :class="indexSelected == index ? 'active' : null"> 
                                        <!-- <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="avatar"> -->
                                        <img :src="'https://ui-avatars.com/api/?name=' + object.provider.name + '&rounded=true&bold=true&background=b71c1c&color=ffffff'">
                                        <div class="about">
                                            <div class="name">{{ object.provider.name.slice(0, 13) + '...' }}</div>
                                            <div class="status"> <i class="fa fa-circle online"></i>
                                            {{ sendStatus(object.status) }} </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="chat" v-if="conversationselected">
                                <div class="chat-header clearfix sticky" id="myHeader">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                                <img  :src="'https://ui-avatars.com/api/?name=' + conversationselected.provider.name + '&rounded=true&bold=true&background=b71c1c&color=ffffff'">
                                            </a>
                                            <div class="chat-about">
                                                <h6 class="m-b-0">{{ conversationselected.provider.name }} </h6>
                                                {{ conversationselected.service }} <br>
                                                <small>Date de demande: {{
                                                    moment(conversationselected.created_at).calendar() }}</small>
                                                <!-- {{ moment(conversationselected.created_at).calendar()}} -->
                                            </div>
                                        </div>
                                        <div class="col-lg-6  text-right">
                                            <span class="badge bg-warning text-dark">{{
                                                sendStatus(conversationselected.status) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-history">
                                    <ul class="m-b-0" v-if="!loadMessage">
                                        <li class="clearfix" v-for="(message, index) in conversationselected.conversations"
                                            :key="index">
                                            <div class="message-data"
                                                :class="message.user_id == JSON.parse($store.state.user).id ? 'text-right' : ''">
                                                <span class="message-data-time"><small> {{
                                                    moment(message.created_at).format("DD MMMM YYYY HH:mm")
                                                }}</small></span>
                                            </div>
                                            <div
                                                :class="message.user_id == JSON.parse($store.state.user).id ? ' float-right' : ''">
                                                <div v-if="Number(message.is_file)" class="message"
                                                    :class="message.user_id == JSON.parse($store.state.user).id ? ' other-message' : 'my-message'">
                                                    <img :class="{ 'rounded-t-lg mx-auto h-[15rem]': !isList, 'w-full rounded-tl-lg rounded-bl-lg': isList }"
                                                        class="h-full w-full object-cover"
                                                        :src="storageBaseUrl + message.message" />

                                                </div>
                                                <div v-else class="message"
                                                    :class="message.user_id == JSON.parse($store.state.user).id ? ' other-message' : 'my-message'">
                                                    {{ message.message }} </div> <br />
                                                <div
                                                    :class="message.user_id == JSON.parse($store.state.user).id ? 'text-right' : ''">
                                                    <span class="badge badge-info">{{ sendStatus(message.status) }}</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-else class=" text-center">
                                        <moonloader :size="'35px'" />
                                    </div>
                                </div>
                                <div class="flex flex-col w-full py-2 px-2"
                                    v-if="['TO_BE_TREATED','ACCEPTED','IN_PROGRESS','QUOTE_SEND','QUOTE_ACCEPTED'].includes(conversationselected.status)">
                                    <div class="chat-message clearfix">
                                        <div class="input-group mb-0">
                                            <textarea  v-model="conversationselected.newmessage" class="form-control" placeholder="Votre message ici..." name="" id="" cols="30" rows="2"></textarea>
                                            <button v-if="conversationselected.newmessage"
                                                class="flex justify-center items-center text-red-800 py-2 px-2 text-sm"
                                                @click="editQuoteRequestStatus(conversationselected.id, conversationselected.status, conversationselected.newmessage)">
                                                Envoyer
                                                <i class="fa fa-send"></i>
                                            </button>
                                            <button v-else disabled
                                                class="flex justify-center items-center text-red-800 py-2 px-2 text-sm">
                                                Envoyer
                                                <i class="fa fa-send"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- <textarea name="" placeholder="Votre commentaire" v-model="conversationselected.newmessage" id="" class="form-control" cols="160" rows="5"></textarea> -->
                                </div>
                                <div class="d-flex justify-content-around col-md-12"
                                    v-if="conversationselected.status == 'QUOTE_SEND'">
                                    <button class="flex justify-end items-center btn btn-danger py-2 text-sm"
                                        @click="editQuoteStatus('QUOTE_REFUSED', conversationselected.id, 'Devis refusé')">
                                        <i class="fa fa-close" style="color:white"></i> &nbsp;
                                        Refuser le devis
                                    </button>
                                    <button class="flex justify-end items-center  py-2 btn btn-success text-sm"
                                        @click="editQuoteStatus('QUOTE_ACCEPTED', conversationselected.id, 'Devis accepté')">
                                        &nbsp;
                                        <i class="fa fa-check" style="color:white"></i>&nbsp;
                                        Accepter devis
                                    </button>
                                </div>
                                <div class="d-flex justify-content-around col-md-12 p-4"
                                    v-if="['DONE','REFUSED','QUOTE_REFUSED','DONE_REFUSED','DONE_ACCEPTED'].includes(conversationselected.status)" >
                                    <div class="d-flex justify-content-around col-md-12"
                                        v-if="conversationselected.status == 'DONE'">
                                        <button class="flex justify-end items-center btn btn-danger py-2 text-sm"
                                            @click="editQuoteStatus('DONE_REFUSED', conversationselected.id, 'Prestation non executée')">
                                            <i class="fa fa-close" style="color:white"></i> &nbsp;
                                            Prestation non executée
                                        </button>
                                        <button class="flex justify-end items-center  py-2 btn btn-success text-sm"
                                            @click="editQuoteStatus('DONE_ACCEPTED', conversationselected.id, 'Je confirme')">
                                            &nbsp;
                                            <i class="fa fa-check" style="color:white"></i>&nbsp;
                                            Je confirme
                                        </button>
                                    </div>
                                    <button v-else class="flex justify-end items-center  py-2 btn btn-success text-sm"
                                        @click="$router.push('/prestataires-de-mariage-details/' + conversationselected.shop_id + '#noterprestataire')">
                                        &nbsp;
                                        <i class="fa fa-edit" style="color:white"></i>&nbsp;
                                        Noter le client
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class=" container">
                <div class=" text-center">
                    <moonloader :size="'35px'" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import Bar from "@/components/nuptial-notebook/Bar.vue";
import { mapActions, mapState } from "vuex";
import { STORAGE_BASE_URL } from "@/views/wedding-provider/utils";
import { BASE_URL } from "@/views/wedding-provider/utils";
import moment from "moment"
moment.locale()

export default {
    components: {
        Bar,
    },
    data() {
        return {
            storageBaseUrl: STORAGE_BASE_URL,
            moment: moment,
            favoritesObject: [],
            favoritesValues: [],
            allStatus: [
                { id: 'TO_BE_TREATED', order: 1, name: 'À traiter' },
                { id: 'ACCEPTED', order: 2, name: 'Accepté' },
                { id: 'REFUSED', order: 6, name: 'Devis refuser '},
                { id: 'QUOTE_SEND', order: 3, name: ' Devis Envoyé' },
                { id: 'QUOTE_ACCEPTED', order: 3, name: ' Devis Accepté' },
                { id: 'QUOTE_REFUSED', order:'badge-danger',name: 'Devis refusé '},
                { id: 'IN_PROGRESS', order: 4, name: 'En cours de traitement' },
                { id: 'DONE', order: 5, name: 'Prestation exécutée' },
                { id: 'DONE_REFUSED', order:'badge-danger',name: 'Prestation non executée '},
                { id: 'DONE_ACCEPTED', order:'badge-danger',name: 'Prestation confirmée '}
            ],
            click: 0,
            textFullDisplay: false,
            oldStatus: [],
            indexSelected: 0,
            conversationselected: null,
            newstatus: null,
            loadMessage:false
        }
    },
    computed: {
        ...mapState('quoteRequests', {
            isLoading: 'isLoading',
            favoritesListe: 'quoteRequests',
        }),
    },
    mounted() {
        this.favoritesDisplay()
    },
    methods: {
        ...mapActions('quoteRequests', ['getQuoteRequests']),
        sendStatus(status) {
            console.log(status)
            let name = null
            this.allStatus.forEach(element => {
                if (element.id == status) {
                    name = element.name
                }
            });
            return name;
        },
        async favoritesDisplay() {
            await this.getQuoteRequests()
            console.log(this.favoritesListe)
            this.favoritesListe.forEach((element, index) => {
                if (index == this.indexSelected) {
                    this.conversationselected = element
                }
            });
        },
        async editQuoteRequestStatus(quoteId, status, message) {
            const response = await axios.put(`${BASE_URL}/api/v1/wedding-provider-quote-requests/${quoteId}`, {
                quoteRequestId: quoteId,
                status: status,
                message: message
            })
            console.log(response)
            if (response.data.success) {
                await this.favoritesDisplay()
            }
        },
        async editQuoteStatus(status, id, message) {
            this.loadMessage =  true
            this.favoritesListe.forEach(element => {
                if (element.id == id) {
                    element.status = status
                    this.newstatus = status
                }
            });

            this.conversationselected.status = status
            this.newstatus = status
            this.loadMessage =  false
            this.editQuoteRequestStatus(id, status, message);
        },
        conversationselect(conversation, index) {
            this.conversationselected = conversation
            this.indexSelected = index
        }
    },
}
</script>
<style scoped>
/* CHATS DESIGN */


.card {
    background: #fff;
    transition: .5s;
    border: 0;
    margin-bottom: 30px;
    border-radius: .55rem;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    flex-direction: row;
}

.sticky {
  position: absolute;
  /* top: 0; */
  width: 900px;
  background: #fff;
  z-index:100;
}
.chat-app .people-list {
    /* width: 280px; */
    position: relative;
    left: 0;
    top: 0;
    padding: 10px;
    z-index: 7;
    overflow-y: auto;
    height: 600px;
}

.chat-app .chat {
    /* margin-left: 280px; */
    border-left: 1px solid #eaeaea;
    width: 900px;
    height: 600px;
    overflow-y: auto;
}

.people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s
}

.people-list .chat-list li {
    padding: 10px 15px;
    list-style: none;
    border-radius: 3px
}

.people-list .chat-list li:hover {
    background: #efefef;
    cursor: pointer
}

.people-list .chat-list li.active {
    background: #efefef
}

.people-list .chat-list li .name {
    font-size: 15px;
    text-overflow: ellipsis;
}

.people-list .chat-list img {
    width: 45px;
    border-radius: 50%
}

.people-list img {
    float: left;
    border-radius: 50%
}

.people-list .about {
    float: left;
    padding-left: 8px
}

.people-list .status {
    color: #999;
    font-size: 13px
}

.chat .chat-header {
    padding: 15px 20px;
    border-bottom: 2px solid #f4f7f6;

}

.chat .chat-header img {
    float: left;
    border-radius: 40px;
    width: 40px
}

.chat .chat-header .chat-about {
    float: left;
    padding-left: 10px
}

.chat .chat-history {
    margin-top: 110px;
    border-bottom: 2px solid #fff
}

.chat .chat-history ul {
    padding: 0
}

.chat .chat-history ul li {
    list-style: none;
    margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
    margin-bottom: 0px
}

.chat .chat-history .message-data {
    margin-bottom: 15px
}

.chat .chat-history .message-data img {
    border-radius: 40px;
    width: 40px
}

.chat .chat-history .message-data-time {
    color: #434651;
    padding-left: 6px
}

.chat .chat-history .message {
    color: #444;
    padding: 18px 20px;
    line-height: 26px;
    font-size: 16px;
    border-radius: 7px;
    display: inline-block;
    position: relative
}

.chat .chat-history .message:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: relative;
    pointer-events: none;
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .my-message {
    background: #E09292
}

.chat .chat-history .my-message:after {
    bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #E09292;
    border-width: 10px;
    margin-left: -10px
}

.chat .chat-history .other-message {
    background: #E2B742;
    text-align: right
}

.chat .chat-history .other-message:after {
    border-bottom-color: #E2B742;
    /* left: 93% */
}

.chat .chat-message {
    padding: 20px
}

.online,
.offline,
.me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle
}

.online {
    color: #86c541
}

.offline {
    color: #e47297
}

.me {
    color: #1d8ecd
}

.float-right {
    float: right
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0
}

@media only screen and (max-width: 767px) {
    .chat-app .people-list {
        height: 250px;
        width: 100%;
        
        overflow-x: auto;
        background: #fff;
        overflow-y: auto;
        /* left: -400px; */
        /* display: none */
        display: table-row;
        /* border: #999 1px solid ; */
        box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
        border-radius: 0.55rem 0.55rem 0 0

    }

    .sticky {
        position: absolute;
        /* top: 0; */
        width: 100%;
        background: #fff;
    }

    .chat-app .people-list.open {
        left: 0
    }

    .chat-app .chat {
        margin: 0
    }

    .chat-app .chat .chat-header {
        border-radius: 0.55rem 0.55rem 0 0
    }

    .chat-app .chat-history {
        margin-top: 130px;
        height: 300px;
        overflow-x: auto
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app .chat-list {
        height: 650px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: 600px;
        overflow-x: auto
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app .chat-list {
        height: 480px;
        overflow-x: auto
    }

    .chat-app .chat-history {
        height: calc(100vh - 350px);
        overflow-x: auto
    }
}</style>