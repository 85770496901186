import { BASE_URL } from "@/views/wedding-provider/utils";
import axios from 'axios';

export async function getServiceTypesAPI(params) {
    try {
        const response = await axios.get(`${BASE_URL}/api/v1/wedding-provider-service-types`, { params });
        return response.data;
    } catch (error) {
        console.log("===> getServiceTypesAPI error", error)
        throw new Error(error)
    }
}