<template lang="">
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="currentColor"
      class="text-danger bi bi-1-circle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176zM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218"
      />
    </svg>
  </div>

  <div class="box-thoughts">
    <div>
      <div class="col-md-12">
        <sub-title label="ECRIVEZ VOTRE HISTOIRE" />
      </div>

      <div class="col-md-6 col-lg-12">
        <div class="row">
          <div class="p-3 col-md-12">
              <div>
                <div class="col-md-12">
                  <p class="fs-2" style="font-weight:bold;font-size:15px">La première fois que vous vous êtes rencontrés</p>
                  <div class="form-group" style="width: 100%">
                    <textarea v-model="our_first_meet_content"
                      class="form-control" @change="changeU"
                      placeholder="Dîtes quelque chose..."
                      id="floatingTextarea2"
                      style="height: 100px;margin-bottom:10px;"
                    ></textarea>
                    <VueDatePicker v-model="our_first_meet_date" month-picker ></VueDatePicker>
                    <label
                      class="text-danger"
                      >{{
                        `*Dimensions: ${
                          configTemplate.find(
                            (element) => element.code === "photo_story_dimensions"
                          ).value
                        }`
                      }}</label>
                    <BlockImageComponent
                      :key="index"
                      :identifier="num"
                      :name="`photo_story_our_first_meet_photo`"
                      :allowMultiples="false"
                      @save-img="saveImg"
                      @delete-img="deleteImg"
                      :caracteristic_user_template="caracteristic_user_template"

                    >
                    </BlockImageComponent>
                  </div>
                </div>
                <div class="col-md-12">
                  <p class="fs-2" style="font-weight:bold;font-size:15px">Le premier rendez vous</p>
                  <div class="form-group" style="width: 100%">
                    <textarea v-model="our_first_date_content"
                      class="form-control"
                      placeholder="Dîtes quelque chose..."
                      id="floatingTextarea2"
                      style="height: 100px;margin-bottom:10px;"
                    ></textarea>
                    <VueDatePicker v-model="our_first_date_date" month-picker ></VueDatePicker>
                    <label
                      class="text-danger"
                      >{{
                        `*Dimensions: ${
                          configTemplate.find(
                            (element) => element.code === "photo_story_dimensions"
                          ).value
                        }`
                      }}</label>
                    <BlockImageComponent
                      :key="index"
                      :identifier="num"
                      :name="`photo_story_our_first_date_photo`"
                      :allowMultiples="false"
                      @save-img="saveImg"
                      @delete-img="deleteImg"
                      :caracteristic_user_template="caracteristic_user_template"

                    >
                    </BlockImageComponent>

                  </div>
                </div>
                <div class="col-md-12">
                  <p class="fs-2" style="font-weight:bold;font-size:15px">Elle a dit oui</p>
                  <div class="form-group" style="width: 100%">
                    <textarea @change="updateSheSaidYesContent" v-model="she_said_yes_content"
                      class="form-control"
                      placeholder="Dîtes quelque chose..."
                      id="floatingTextarea2"
                      style="height: 100px;margin-bottom:10px;"
                    ></textarea>
                    <VueDatePicker v-model="she_said_yes_date" month-picker ></VueDatePicker>
                    <label
                      class="text-danger"
                      >{{
                        `*Dimensions: ${
                          configTemplate.find(
                            (element) => element.code === "photo_story_dimensions"
                          ).value
                        }`
                      }}</label>
                    <BlockImageComponent v-if="JSON.parse(configTemplate.find((element)=>element.code==='photo_story').value)===true"
                      :key="index"
                      :identifier="num"
                      :name="`photo_story_she_said_yes_photo`"
                      :allowMultiples="false"
                      :caracteristic_user_template="caracteristic_user_template"
                      @save-img="saveImg"
                      @delete-img="deleteImg"
                    >
                    </BlockImageComponent>
                  </div>
                </div>
                

                <div class="col-md-12 d-flex justify-content-end">
                  <button
                    class="primary-button"
                    @click="saveStory"
                  >
                    ENREGISTRER</button
                  >&nbsp;
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BlockImageComponent from "./BlockImageComponent.vue";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
// import {fr} from 'vuejs-datepicker/dist/locale';
// import Datepicker from 'vuejs-datepicker';

export default {
  name: "StoryComponent",
  components:{
    BlockImageComponent
  },
  data: function () {
    return {
      our_first_meet_date:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.our_first_meet_date ,
      our_first_date_date:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.our_first_date_date ,
      she_said_yes_date:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.she_said_yes_date ,
      our_first_meet_content:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.our_first_meet_content ,
      our_first_date_content:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.our_first_date_content ,
      she_said_yes_content:this.caracteristic_user_template.story===null ? null : this.caracteristic_user_template.story.she_said_yes_content ,
    };
  },
  props: ["configTemplate","caracteristic_user_template"],
  mounted() {

    this.$emit("set-loading", false);

    if(this.our_first_date_content.length > 0&& this.our_first_meet_content.length>0 && this.she_said_yes_content.length>0) {
      
  }else{

  }

},
  beforeUnmount() {
    this.$emit("set-loading", true);
  },
  methods: {
    deleteImg: function (payload) {
      this.$emit("delete-img", payload);
    },
    saveImg: function (payload) {
      // console.log('save img',payload)
      this.$emit("save-img", payload);
    },
    changeU:function(){
      console.log('CHangement')
    },
    saveStory:function(event){
      this.$emit('save-story',{
        our_first_meet_date:this.our_first_meet_date,
        our_first_date_date:this.our_first_date_date,
        she_said_yes_date:this.she_said_yes_date,
        our_first_meet_content:this.our_first_meet_content,
        our_first_date_content:this.our_first_date_content,
        she_said_yes_content:this.she_said_yes_content,
      })
    },

  },
};
</script>
<style>
.semipolar-spinner,
.semipolar-spinner * {
  box-sizing: border-box;
}

.semipolar-spinner {
  height: 65px;
  width: 65px;
  position: relative;
}

.semipolar-spinner .ring {
  border-radius: 50%;
  position: absolute;
  border: calc(65px * 0.05) solid transparent;
  border-top-color: #ff1d5e;
  border-left-color: #ff1d5e;
  animation: semipolar-spinner-animation 2s infinite;
}

.semipolar-spinner .ring:nth-child(1) {
  height: calc(65px - 65px * 0.2 * 0);
  width: calc(65px - 65px * 0.2 * 0);
  top: calc(65px * 0.1 * 0);
  left: calc(65px * 0.1 * 0);
  animation-delay: calc(2000ms * 0.1 * 4);
  z-index: 5;
}

.semipolar-spinner .ring:nth-child(2) {
  height: calc(65px - 65px * 0.2 * 1);
  width: calc(65px - 65px * 0.2 * 1);
  top: calc(65px * 0.1 * 1);
  left: calc(65px * 0.1 * 1);
  animation-delay: calc(2000ms * 0.1 * 3);
  z-index: 4;
}

.semipolar-spinner .ring:nth-child(3) {
  height: calc(65px - 65px * 0.2 * 2);
  width: calc(65px - 65px * 0.2 * 2);
  top: calc(65px * 0.1 * 2);
  left: calc(65px * 0.1 * 2);
  animation-delay: calc(2000ms * 0.1 * 2);
  z-index: 3;
}

.semipolar-spinner .ring:nth-child(4) {
  height: calc(65px - 65px * 0.2 * 3);
  width: calc(65px - 65px * 0.2 * 3);
  top: calc(65px * 0.1 * 3);
  left: calc(65px * 0.1 * 3);
  animation-delay: calc(2000ms * 0.1 * 1);
  z-index: 2;
}

.semipolar-spinner .ring:nth-child(5) {
  height: calc(65px - 65px * 0.2 * 4);
  width: calc(65px - 65px * 0.2 * 4);
  top: calc(65px * 0.1 * 4);
  left: calc(65px * 0.1 * 4);
  animation-delay: calc(2000ms * 0.1 * 0);
  z-index: 1;
}

@keyframes semipolar-spinner-animation {
  50% {
    transform: rotate(360deg) scale(0.7);
  }
}
.box-thoughts {
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
  padding: 25px;
  margin-bottom: 25px;
}
</style>
