<template>
    <div class="modal-overlay">
      <div class="verif-account">
        <img class="check" src="" alt="" />
        <div>
            <div class="text-center h3 py-3">
                Activation de compte
            </div>
            <hr />
            <div class="text-center py-2">
                <img src="@/images/auth-alert.png" alt="" width="50%" /> <br />
            </div>
            <div class="text-center py-2" style="font-size: 16px;">
                Veuillez consulter votre adresse mail ({{ user.email }})<br />
                pour activer votre compte
            </div>
            <div class="text-center py-2">
                <button
                class="primary-button"
                @click="$emit('resendMailAccountVerification', user.email)"
                >
                Renvoyer le mail
                </button>
            </div>
        </div>
      </div>
      <div class="close">
        <img class="close-img" src="" alt="" />
      </div>
    </div>
</template>
  
  <script>
    export default {
        props: ['user']
  }
  </script>
  <style scoped>

  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #b9b9b9da;
  }
  
  .verif-account {
    z-index: 1;
    text-align: center;
    background-color: rgb(255, 255, 255);
    height: 500px;
    width: 500px;
    margin-top: 10%;
    padding: 60px 0;
    border-radius: 20px;
  }
  .close {
    margin: 10% 0 0 16px;
    cursor: pointer;
  }
  
  .close-img {
    width: 25px;
  }
  
  .check {
    width: 150px;
  }
  
  h6 {
    font-weight: 500;
    font-size: 28px;
    margin: 20px 0;
  }
  
  p {
    font-size: 16px;
    margin: 20px 0;
  }
  
  button {
    background-color: #ac003e;
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    border-radius: 16px;
    margin-top: 50px;
  }
  </style>