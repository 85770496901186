<template>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="payment-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="ENVOIE DE MESSAGE EN COURS" gender="F" />
          </div>
        </div>
        <div v-if="isLoading == true && statusSending == null">
            <spinner  /><br /><br />
          </div>
        <div v-else class="">
            <div class="alert alert-success text-center" v-if="statusSending == true" role="alert">
                JOY CARD Envoyé avec succès
            </div>
            <div class="alert alert-danger text-center" v-if="statusSending == false" role="alert">
                JOY CARD Echoué
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="secondary-button pull-left"
              @click="returnToTargetChoice"
            >
              <i class="fa fa-chevron-left" style="color:#fff;"></i> PRÉCÉDENT
            </button>
            
            <button @click="payment" class="primary-button pull-right">
              <i class="fa fa-shopping-cart" style="color:#fff;"></i> PAYER
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </template>
  <script>
  import { COMMUNICATION, API_PREFIX, API_VERSION } from "@/config";
  import axios from 'axios';
  
  
  export default {
    name: "Sending",
    props: ["message"],
    data() {
      return {
        isLoading:false,
        statusSending:null
      };
    },
    mounted() {
        this.payment()
    },  
    methods: {
      returnToTargetChoice() {
        this.$emit("return-to-message-summary");
      },
      changePaymentMethod(paymentMethod) {
        this.paymentMethod = paymentMethod;
      },
      payment(){
          let app = this
          this.isLoading = true
          let idMessage = this.$route.params.idMessage;
          let idTransaction = this.$route.params.idTransaction;
          axios.get(
                COMMUNICATION.TEST_URL + API_PREFIX + API_VERSION + "messageSend/"+idMessage+"/"+idTransaction,
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token
                  }
                }
              )
              .then(response => {
               console.log(response.data)
                  if (response.data.success == true ) {
                    app.statusSending =  true
                  }else{
                    app.statusSending =  false
                  }
              })
              .catch(error => {
  
              }).finally(function(){
                // setTimeout(() => {
                //     app.$router.push(
                //         "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage"
                //     );
                // }, 5000);
              });
            
      }
    }
  };
  </script>
  
  <style scoped>
  .payment-container {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
    -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
    box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  }
  
  </style>
  