<template>
    <div v-if="inProgress == false" class="accordion" id="accordionExample" style="padding-bottom:15px;">
        <div class="card">
            <div 
                class="card-header text-white" 
                style="background-color: #b71c1c;" 
                :id="'heading' + index" data-toggle="collapse" 
                :data-target="'#collapsePlanning' + index" aria-expanded="true" 
                :aria-controls="'collapse' + index"
            >
                <strong>{{ planningProps.content.toUpperCase()}}</strong>
                <div class="pull-right">
                    <i style="color: #fff;" class="fa"></i>
                </div>
            </div>

            <div :id="'collapsePlanning' + index" class="collapse show" :aria-labelledby="'heading' + index" data-parent="#accordionExample">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-sm" style="font-size: 12px;">
                        <thead>
                            <th style="color:#b71c1c;">
                                Actions {{ planningProps.children.length }} / Vous avez réalisé {{ planningProps.doneTasks }} action(s) sur {{ planningProps.children.length }}
                            </th>
                            <th style="color:#b71c1c;">Début</th>
                            <th style="color:#b71c1c;">Fin</th>
                            <th style="color:#b71c1c;text-align:center;">
                                <div class="form-inline">
                                    <label>Fait</label>
                                </div>
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="(child, childIndex) in planningProps.children" :key="childIndex">
                                <td style="width: 50%;">{{ child.content }}</td>
                                <td style="width: 22.5%;">
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <input 
                                                type="date" v-model="planningsObj[childIndex].start_date" :max="planningsObj[childIndex].end_date"
                                                :name="'start_date' + child" :id="'start_date' + child" class="form-control"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 22.5%;">
                                    <div class="form-group">
                                        <div class="input-group mb-3">
                                            <input 
                                                type="date" v-model="planningsObj[childIndex].end_date" :min="planningsObj[childIndex].start_date"
                                                :name="'end_date' + child" :id="'end_date' + child" class="form-control"
                                            >
                                        </div>
                                    </div>
                                </td>
                                <td style="width: 5%;">
                                    <input type="checkbox" v-model="planningsObj[childIndex].status" class="form-control">
                                </td>
                            </tr>
                            <tr class="no-print">
                                <td colspan="4">
                                    <div class="pull-left">
                                        <button class="secondary-button no-print" data-toggle="modal" :data-target="'#chartsModal' + index">
                                            OÙ EN ÊTES-VOUS ?
                                        </button>
                                        <!-- Charts modal -->
                                        <div class="modal fade" :id="'chartsModal' + index" tabindex="-1" role="dialog" aria-labelledby="chartsModalLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <div class="col-md-12">
                                                            <h5 style="text-align: center;" class="modal-title" id="chartsModalLabel">
                                                                {{ planningProps.content.toUpperCase()}}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div class="row">
                                                            <div class="col-md-12 text-center">
                                                                <p> 
                                                                    Vous avez effectué <strong>{{ planningProps.doneTasks }}</strong> 
                                                                    Tâche(s) sur les <strong>{{ planningProps.children.length }}</strong> Tâches prévus.
                                                                </p>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div style="width:50%; margin: 0 auto;">
                                                                    <doughnut-chart 
                                                                        :chart-data="getDoughnutData(planningProps.doneTasks, planningProps.children.length)"
                                                                        :options="options"
                                                                    >
                                                                    </doughnut-chart>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="secondary-button" data-dismiss="modal">FERMER</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pull-right">
                                        <button class="primary-button" @click="updatePlanning">ENREGISTRER</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    [data-toggle="collapse"] .fa:before {
        content: "\f068";
    }

    [data-toggle="collapse"].collapsed .fa:before {
        content: "\f067";
    }
</style>

<script>
    import moment from 'moment';
    import DoughnutChart from '../common/charts/DoughnutChart';

    export default {
        name: 'PlanningComponent',
        props: ['planningProps', 'index'],
        components: {
            DoughnutChart
        },
        filters: {
            uppercase: function(text) {
                return text.toUpperCase();
            }
        },
        data: function() {
            return {
                planning: {
                    event_id: null,
                    planning_id: null,
                    start_date: null,
                    end_date: null,
                    status: false
                },
                planningsObj: [],
                inProgress: true,
                dataCollection: null,
                options: {
                    responsive: true,
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        },
        mounted: function() {
            this.preparePlanningsObj();
        },
        computed: {
            //
        },
        methods: {
            customFormatter(date, to) {
                return (date != null) ? moment(date).format(to) : null;
            },
            preparePlanningsObj: function() {
                this.inProgress = true;
                this.planningProps.children.forEach(item => {
                    let planningObj = {
                        event_id: item.pivot.event_id,
                        planning_id: item.pivot.planning_id,
                        start_date: item.pivot.start_date,
                        end_date: item.pivot.end_date,
                        status: (item.pivot.status == 1) ? true : false
                    }
                    this.planningsObj.push(planningObj);
                });
                this.inProgress = false;
            },
            updatePlanning: function() {
                let planningsFObj = [];
                this.planningsObj.forEach(item => {
                    let planningFObj = {
                        event_id: item.event_id,
                        planning_id: item.planning_id,
                        start_date: this.customFormatter(item.start_date, 'YYYY-MM-DD'),
                        end_date: this.customFormatter(item.end_date, 'YYYY-MM-DD'),
                        status: (item.status == true) ? 1 : 0
                    }
                    planningsFObj.push(planningFObj);
                });
                this.$emit('update-planning', planningsFObj);
            },
            getDoughnutData: function(doneTasks, allTasks) {
                let progressPercent = (doneTasks * 100) / allTasks;
                let datacollection = {
                    labels: [
                        'Tâches restantes',
                        'Tâches effectuées'
                    ],
                    datasets: [
                        {
                            label: 'OÙ EN ÊTES-VOUS ?',
                            backgroundColor: [
                                '#9E9E9E',
                                '#1faa00'
                            ],
                            data: [
                                Number(100 - progressPercent),
                                progressPercent
                            ],
                        }
                    ]

                }

                return datacollection;
            }
        }
    }
</script>