<template>
  <div class="container" style="padding:25px;">
    <big-title label="DATES ET LIEUX" />
    <br /><br />
    <div class="date-place-container">
      <div class="row" v-if="isLoading == false">
        <div class="col-md-4">
          <img
            style="width:100%;height:100%;"
            :src="
              datePlaceImage != null
                ? url + datePlaceImage
                : 'https://via.placeholder.com/514x587'
            "
            alt=""
          />
        </div>
        <div class="p-3 col-md-8">
          <div class="col-md-12">
            <sub-title
              :label="datePlaceName ? datePlaceName : 'Mariage'"
              :gender="'F'"
            />
          </div>
          <div class="col-md-12" v-if="datePlace.isrequired == false">
            <label for="">Statut <span class="text-danger">*</span></label
            ><br />
            <vue-toggle
            title=""
            name="nom"
              v-model="datePlace.is_activate"
              @toggle="(value) => datePlace.is_activate = value"
            />
          </div>
          <div class="col-md-12" v-else>
            <label for="">Statut <span class="text-danger">*</span></label
            ><br />
            <vue-toggle
            title=""
            name="nom"
              v-model="datePlace.is_activate"
              toggled="true"
              @toggle="true"
            />
          </div>
          <div
            class="col-md-12"
            v-if="datePlace.is_activate == true || datePlace.isrequired == true"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <text-input
                    label="Date"
                    name="wedding_date"
                    type="date"
                    :rules="'required'"
                    :isRequired="true"
                    v-model="datePlace.wedding_date"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <masked-input
                  label="Heure"
                  :name="'wedding_time'"
                  type="text"
                  :rules="'required|min:5|max:5'"
                  :isRequired="true"
                  v-model="datePlace.wedding_time"
                  placeholder="00:00"
                  :mask="'00:00'"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label"
                    >Lieu <span class="text-danger">*</span></label
                  >
                  <div class="row">
                    <div class="col-md-2">
                      <select
                        class="form-control"
                        
                        v-model="datePlace.wedding_location_prefix"
                        name="weeding_location_prefix"
                        id="weeding_location_prefix"
                        v-validate="'required'"
                        data-vv-as="Préfix"
                      >
                        <option value="">--</option>
                        <option value="à">à</option>
                        <option value="au">au</option>
                      </select>
                    </div>
                    <div class="col-md-10">
                      <input
                        type="text"
                        v-model="datePlace.wedding_location"
                        class="form-control"
                        
                        v-validate="'required'"
                        name="wedding_location"
                        data-vv-as="Lieu"
                      />
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button class="primary-button" @click="updateDatePlace">
              ENREGISTRER</button
            >&nbsp;
            <button class="secondary-button">
              ANNULER
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <spinner />
      </div>
    </div>
    <br />
    <center>
      <button class="default-button" @click="goToBack">RETOUR</button>
    </center>
  </div>
</template>

<style scoped>
.date-place-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}
</style>

<script>
import axios from "axios";
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import moment from "moment";
import VueToggle from 'vue-toggle-component';
import { useMeta } from 'vue-meta'
export default {
  name: "DatesPlacesEdit",
  setup () {
    useMeta({
      title:
      "Carnet Nuptial - Dates et lieux - Fixer les dates et lieux pour la célébration de votre mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Comment choisir le lieu de célébration de son mariage",
        },
        {
          name: "keywords",
          content:
            "Mariage en Côte d’Ivoire, salle de réception en Côte d’Ivoire, espace événementiel à Abidjan pas cher, organisation de mariage en Côte d’Ivoire, carnet nuptial, outil de planification, wedding planner, inspiration de mariage, prestataire de votre choix",
        },
      ],
    })
  },

  components: {
    VueToggle 
  },
  data: function() {
    return {
      datePlaceName: null,
      datePlaceImage: null,
      weddingDate: null,
      url: NUPTIAL_NOTEBOOK.TEST_URL,
      isLoading: false,
      datePlace: {
        event_id: null,
        date_place_id: null,
        wedding_date: null,
        wedding_time: null,
        wedding_location_prefix: null,
        wedding_location: null,
        is_activate: null,
      },
    };
  },
  mounted: function() {
    this.getDatePlace();
  },
  methods: {
    customFormatter: function(date) {
      return date != null ? moment(date).format("YYYY-MM-DD") : null;
    },
    goToBack: function() {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage"
      );
    },
    getDatePlace: function() {
      this.isLoading = true;
      let eventId = this.$route.params.eventId;
      let datePlaceId = this.$route.params.datePlaceId;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "dates-places-events/" +
            eventId +
            "/" +
            datePlaceId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.datePlace = {
            event_id: response.data.pivot.event_id,
            date_place_id: response.data.pivot.date_place_id,
            wedding_date: response.data.pivot.wedding_date,
            wedding_time: response.data.pivot.wedding_time,
            wedding_location_prefix:
              response.data.pivot.wedding_location_prefix,
            wedding_location: response.data.pivot.wedding_location,
            is_activate: response.data.pivot.is_activate == 1 ? true : false,
            isrequired: response.data.isrequired == 1 ? true : false,
          };
          this.datePlaceName = response.data.name;
          this.datePlaceImage = response.data.image;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isLoading = false;
        });
    },
    updatedPercentage(customerId) {
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "percentage/" +
            customerId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {
          console.log("Error percentage", error);
        });
    },
    archivated: function(customerId) {
      var app = this;
      this.$store
        .dispatch("archiveAccount", customerId)
        .then((response) => {})
        .catch((error) => {});
    },
    updateDatePlace: function() {
      // this.$validator.validateAll().then((result) => {
      //   if (result) {
          this.isLoading = true;
          this.datePlace = {
            event_id: this.datePlace.event_id,
            date_place_id: this.datePlace.date_place_id,
            wedding_date: this.customFormatter(this.datePlace.wedding_date),
            wedding_time: this.datePlace.wedding_time,
            wedding_location_prefix: this.datePlace.wedding_location_prefix,
            wedding_location: this.datePlace.wedding_location,
            is_activate: this.datePlace.is_activate == true ? 1 : 0,
          };
          axios
            .post(
              NUPTIAL_NOTEBOOK.TEST_URL +
                API_PREFIX +
                API_VERSION +
                "dates-places/events",
              this.datePlace,
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.isLoading = false;
              this.updatedPercentage(response.data.customer_id);
              this.archivated(response.data.customer_id);
              this.$createToast(
                                    {
                                    title: 'Operation reussie !',
                                    description: "Informations enregistrées avec succès !  !"
                                    },
                                    {
                                    position: 'top-center',
                                    type: 'success',
                                    timeout: 10000,
                                    transition: 'bounce',
                                    });
              this.$router.push(
                "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage"
              );
            })
            .catch((error) => {
              this.isLoading = false;
             
              this.$createToast(
                  {
                  title: 'Attention !',
                  description: "Erreur lors de la sauvegarde des informations !"
                  },
                  {
                  position: 'top-center',
                  type: 'error',
                  timeout: 10000,
                  transition: 'bounce',
                  })
            });
      //   }
      // });
    },
  },
};
</script>
