<template>
  <div class="flex w-full py-4 px-2">
    <button @click="onAction" class="w-full bg-red-800 hover:bg-red-900 text-white">
      <span class="flex justify-center items-center w-full h-full py-2 rounded-md shadow-md">
        Demander un devis
      </span>
    </button>
  </div>
</template>

<script>
import { LOGIN_URL, QUOTE_REQUEST_TYPE } from "@/views/wedding-provider/utils";
export default {
  name: "banner-bottom-mobile",
  props: ['provider'],
  data: () => ({
    formTypes: QUOTE_REQUEST_TYPE,
    component: 'contact-form-component',
  }),
  methods: {
    redirectToLogin() {
      window.location.href = LOGIN_URL;
    },
    requestQuote(type) {
      this.$store.dispatch('quote/setQuoteRequestType', type);
      this.$router.push({name: 'quote-request'});
    },
    onAction() {
      this.$emit('scroll-to', this.component);
    },
  },
}
</script>

<style scoped>

</style>