<template>
  <div class="form-group" v-if="name">
      <label class="control-label">{{ label }} <span v-show="isRequired" class="text-danger">*</span></label>
      <!-- <Field :name="label"  :type="(type) ? type : 'text'" v-slot="{ rules }">
        <input
            :value="modelValue" :rules="rules"  @input="$emit('input', $event.target.value)"
            class="form-control" :readonly="(isReadonly) ? isReadonly : false"
        >
        <div style="color:red">
          <ErrorMessage   :name="label" />
        </div>
      </Field> -->

        <Field :type="(type) ? type : 'text'"   :value="modelValue" class="form-control"  
               :name="name"  :rules="rules"  :readonly="(isReadonly) ? isReadonly : false" @input="$emit('update:modelValue', $event.target.value)"/>
        <div style="color:red">
          <ErrorMessage   :name="name" />
        </div>     
  </div>
</template>

<script>
import { Form, Field, ErrorMessage  } from 'vee-validate';
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { IMaskDirective } from 'vue-imask';
Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});

  export default {
    components: {
    Form,
    Field,
    ErrorMessage,
  },
    name: 'textInput',
    // inject: ['$validator'],
    props: ['label', 'name', 'modelValue', 'rules', 'isRequired', 'type', 'isReadonly'],
    data: function() {
    return {
      mask: {
          mask: '+{(225)} 00 00 00 00 00',
          lazy: false
      },
    };
  },
    directives: {
      imask: IMaskDirective
    },  
    methods:{
    

    }
  }
  
</script>
