<template>
  <div id="main" class="bg-container-home">
    <slider-component
      v-if="slides != null && slides.length >= 0"
      :slides="slides"
    />
    <div class="pt-4" v-else>
      <br />
      <br />
      <br />
      <spinner/>
    </div>
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-md-4">
          <img
            class="img-fluid mx-auto shadow"
            src="/images/home/welcome-left.jpg"
            style="width:100%;"
            alt=""
          />
        </div>
        
        <div
          class="col-md-4 d-flex justify-content-between flex-column align-items-center"
          style="text-align : center; padding-top:20px; padding-bottom:20px;"
        >
          <img
            class="img-fluid mx-auto"
            src="/images/home/bienvenue.png"
            style="width:200px;height:auto;margin-bottom:-25px;"
          />
          <img
            class="img-fluid mx-auto"
            src="/images/icon-flower.png"
            style="width:100px;height:auto;"
          />
          <div
            style="text-align:justify; text-align-last: center; font-size: 16px; color:#666666;margin-top:-25px;line-height:-1;"
          >
            Nous sommes ravis de vous rencontrer ! Jours de joie est l’assistant
            idéal pour organiser sereinement votre mariage. Lorsque vous créez
            votre compte sur Jours de joie, vous bénéficiez, automatiquement, de
            la garantie d'un assistant digital qui vous suit partout ...
          </div>
          <br />
          <button class="default-button" @click="goToWelcome">
            LIRE PLUS
            <i style="font-size: 10px;" class="lnr lnr-chevron-right"></i>
          </button>
        </div>
        <div class="col-md-4">
          <img
            class="img-fluid mx-auto shadow"
            src="/images/home/welcome-right.jpg"
            style="width:100%;"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="backgroundStyleVideoBloc"
      :style="{ backgroundImage: `url(${image})` }"
    >
      <div class="container">
        <a href="javascript:void(0);" data-toggle="modal" data-target=".bd-example-modal-lg">
          <i
            class="fa fa-play-circle"
            style="font-size:60px;color:white;opacity:0.5;"
          ></i>
        </a>
        <p class="h4" style="color:white;">
          Plus de panique,
        </p>
        <p style="text-align:center; color:white;">
          votre Digital Event Planner, l’assistant idéal pour organiser <br />
          et planifier votre mariage se charge de tout.
        </p>
      </div>
    </div>
    <!-- YouTube Video Modal -->

  
    <div class="modal fade bd-example-modal-lg" v-if="video" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="text-center h3 py-3">{{ video.label }}</div>
                <iframe
                  width="100%"
                  height="500"
                  :src="'https://www.youtube.com/embed/' + video.url"
                >
                </iframe>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row" style="text-align:center;">
        <div class="col-md-12">
          <div
            class="h2"
            style="color:#b71c1c;font-weight:600;padding-top:50px;"
          >
            NOS SERVICES<br />
            <img
              class="img-fluid"
              src="/images/icon-flower.png"
              style="width:100px;height:auto;margin-top:-25px;"
            />
          </div>
        </div>
        <div class="row pt-3 pb-5">
          <div class="col-md-3">
            <div class="pb-4">
              <img
                src="/images/home/icon-town-hall.png"
                style="width:30%;"
                alt=""
              />
            </div>
            <h2 class="font-weight-normal">Mairies</h2>
            <p class="pt-2 pb-3">
              Parce que le mariage c’est d’abord administratif, il est important
              d'avoir les bonnes informations et de façon efficace.
            </p>
            <button
              @click="
                $router.push(
                  '/choisir-mairie-pour-organiser-son-mariage-cote-d-ivoire'
                )
              "
              class="default-button"
            >
              Parcourez
              <i style="font-size: 10px;" class="lnr lnr-chevron-right"></i>
            </button>
          </div>
          <div class="col-md-3">
            <div class="pb-4">
              <img
                src="/images/home/icon-nuptial-notebook.png"
                style="width:30%;"
                alt=""
              />
            </div>
            <h2 class="font-weight-normal">Carnet Nuptial</h2>
            <p class="pt-2 pb-3">
              C’est l’outil idéal pour répondre à vos interrogations concernant
              l’organisation de votre mariage. Créez votre compte,
              connectez-vous
            </p>
            <button
              @click="
                $router.push(
                  '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/fiances'
                )
              "
              class="default-button"
            >
              Découvrez
              <i style="font-size: 10px;" class="lnr lnr-chevron-right"></i>
            </button>
          </div>
          <div class="col-md-3">
            <div class="pb-4">
              <img
                src="/images/home/icon-wedding-invitation.png"
                style="width:30%;"
                alt=""
              />
            </div>
            <h2 class="font-weight-normal">Joy Cards</h2>
            <p class="pt-2 pb-3">
              Joy Cards est l'interface dédiée pour toutes les communications à
              propos de votre mariage. Restez en contact avec vos invités.
            </p>
            <button
              @click="
                $router.push(
                  '/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage'
                )
              "
              class="default-button"
            >
              Partagez
              <i style="font-size: 10px;" class="lnr lnr-chevron-right"></i>
            </button>
          </div>
          <div class="col-md-3">
            <div class="pb-4">
              <img src="/images/home/icon-blog.png" style="width:30%;" alt="" />
            </div>
            <h2 class="font-weight-normal">Blog</h2>
            <p class="pt-2 pb-3">
              JDJ pour la réussite de votre mariage partage dans son blog des
              articles sur l'organisation avant, pendant et après votre mariage
            </p>
            <button @click="goToBlog" class="default-button">
              Savourez
              <i style="font-size: 10px;" class="lnr lnr-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="text-align:center;">
      <div class="row">
        <div class="col-md-12">
          <div
            class="h2"
            style="color:#b71c1c;font-weight:600;padding-top:50px;"
          >
            TROUVER LA MAIRIE DE VOTRE MARIAGE<br />
            <img
              class="img-fluid"
              src="/images/icon-flower.png"
              style="width:100px;height:auto;margin-top:-25px;"
            />
          </div>
        </div>
      </div>
    </div>
    <town-hall-component></town-hall-component>
    <div class="container">
      <div
        class="row text-center"
        style="text-align:center;padding-top:50px;padding-bottom:50px;"
      >
        <div class="col-md-12">
          <div
            class="h2"
            style="color:#b71c1c;font-weight:600;padding-top:50px;"
          >
            BLOG<br />
            <img
              class="img-fluid"
              src="/images/icon-flower.png"
              style="width:100px;height:auto;margin-top:-25px;"
            />
          </div>
        </div>
        <div class="col-md-12">
          <Carousel :items-to-show="4" :autoplay="2000" :breakpoints="breakpoints" :wrap-around="true" v-if="blogArticles != null">
            <Slide v-for="(article, key) in blogArticles" :key="key" >
              <blog-article-item
              :key="key"
              :title="article.title.rendered"
              :link="article.link"
              :image="
                article._embedded
                  ? article._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url
                  : 'https://via.placeholder.com/300x300.png?text=JoursdeJoie.com'
              "
            >
            </blog-article-item>
            <!-- <a href="https://blog.joursdejoie.com" target="_blank">
              <div class="card">
                <div class="header-show-all">
                  <small class="header-content-show-all">
                    <i class="fa fa-plus-square-o" style="color:#fff;"></i> Voir
                    tout
                  </small>
                </div>
              </div>
            </a> -->
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
          <!-- <carousel
            v-if="blogArticles != null"
            class="card-deck"
            :responsive="{
              0: { items: 1, nav: false },
              600: { items: 3, nav: false },
              1200: { items: 4, nav: false },
            }"
            :items="4"
            :autoplay="true"
            :nav="false"
          >
            <blog-article-item
              v-for="(article, key) in blogArticles"
              :key="key"
              :title="article.title.rendered"
              :link="article.link"
              :image="
                article.better_featured_image
                  ? article.better_featured_image.media_details.sizes.medium
                      .source_url
                  : 'https://via.placeholder.com/300x300.png?text=JoursdeJoie.com'
              "
            >
            </blog-article-item>
            <a href="https://blog.joursdejoie.com" target="_blank">
              <div class="card">
                <div class="header-show-all">
                  <small class="header-content-show-all">
                    <i class="fa fa-plus-square-o" style="color:#fff;"></i> Voir
                    tout
                  </small>
                </div>
              </div>
            </a>
          </carousel> -->
          <div class="text-center" v-else>
            <div class="text-center">
              <spinner/>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
    <div
      style="margin-bottom:50px;padding-bottom:50px;padding-top:50px;background-color:red;background-image: url('/images/home/counter-bg.jpg'); background-size:cover;background-repeat:no-repeat;"
    >
      <div class="container">
        <div class="row" style="text-align:center;" v-if="counters != null">
          <div class="col-md-3" v-for="(counter, key) in counters" :key="key">
            <img
              class="img-fluid"
              :src="contactURL + counter.image"
              alt=""
            />
            <h1 style="color:#fff;" v-if="counter.id == 2">
              {{ getDateDiff(counter.count) }} ans
            </h1>
            <h1 style="color:#fff;" v-else>{{ counter.count }}</h1>
            <p style="color:#fff;font-size:17px;font-weight:500;">
              {{ counter.label }}
            </p>
          </div>
        </div>
        <div class="row text-center" v-else>
          <spinner></spinner>
          <br />
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade p-4" id="myModal" role="dialog" style="margin-top: 10%;" >
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h4 class="modal-title">Jours de joie</h4>
        </div> -->
        <div class="modal-body">
          <img src="/images/teaser.jpg" alt="">
          <!-- <p>The <strong>show</strong> method shows the modal and the <strong>hide</strong> method hides the modal.</p> -->
        </div>
      </div>
      
    </div>
  </div>
  <!-- <div class="modal fade pt-4" id="exampleModalCenterPopUp" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style=" background-color: transparent !important;">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  <modal-promo></modal-promo>
                </div>
            </div>
        </div>
    </div> -->
</template>
<style scoped>

.test select {
  box-shadow: none !important;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  border-radius: 1px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  line-height: 1.42857;
  outline: medium none;
  padding: 0 9px;
  width: 100%;
  border-radius: 4px;
  color: #b71c1c;
}

.read-more:after {
  content: "";
  position: absolute;
  width: 7%;
  left: 46.5%;
  border-top: 1px solid #b71c1c;
}

.carousel-item {
  padding-left: 10px;
  padding-right: 10px;
}

.carousel-item::before {
  background-size: cover;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.4;
}

.header-show-all {
  position: relative;
  /* min-height: 150px; */
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("@/images/home/blog-all-posts.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
  padding-left: 1px;
  padding-right: 1px;
}

.header-content-show-all {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 25%;
  /* -ms-transform: translateY(-50%);
        transform: translateY(-50%); */
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  text-align: center;
}

.backgroundStyleVideoBloc {
  background: url("@/images/old_img/accueil/zik.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.bg-container-home {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-home .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-home::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/home/home.png");
  background-size: cover;
  opacity: 0.1;
}

.iCountUp {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
<script>

import axios from "axios";
import { CONTACT, COMMUNICATION } from "@/config";
import TownHallComponent from "@/components/town-hall/TownHallComponent";
// import carousel from "vue-owl-carousel";
import BlogArticleItem from "@/components/common/ui/BlogArticleItem";
import SliderComponent from "@/components/common/ui/SliderComponent";
import ModalPromo from "@/components/popup.vue";
import moment from "moment";
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import spinner from '@/components/common/ui/spinner'
import { useMeta } from 'vue-meta'
// import VueAnalytics from "vue-ua";
export default {
  name: "Accueil",
  setup () {
    useMeta({
      title:
      "Jours de Joie - Assistant digital d'organisation de mariage en Côte D'Ivoire",
      titleTemplate: "",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "keywords",
          content:
            "Agence d’organisation de mariage en Côte d’Ivoire, wedding planner, choix de mairies, définition du budget de mariage en Côte d'Ivoire, carnet nuptial, robe de mariée, prestataire de mariage",
        },
        {
          name: "description",
          content: "Agence d’organisation de mariage en Côte d’Ivoire, prestataire de mariage",
        },
      ],
    })
  },
  // mixins: [createToast],
  components: {
    Carousel,
    Slide,
    Navigation,
    TownHallComponent,
    BlogArticleItem,
    SliderComponent,
    spinner,
    ModalPromo
  },
  data: function() {
    return {
      blogArticles: null,
      blogArticles: null,
      slides: null,
      video: null,
      counters: null,
      contactURL: CONTACT.TEST_URL,
      delay: 5000,
      COMMUNICATION: COMMUNICATION.TEST_URL,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ",",
        prefix: "",
        suffix: "",
      },
      image: null,
      breakpoints: {
        // 700px and up
        200: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: 'start',
        },
      },

    };
  },
  mounted: function() {
    
    this.$createToast(
      {
        title: 'Bienvenu(e) sur Jours de joie !',
        description: "Votre assistant digital event planner !"
      },
      {
        position: 'top-center',
        type: 'success',
        timeout: 5000,
        transition: 'bounce',
      }
    )
    this.getSlides();
    this.getBlogArticles();
    this.getCounters();
    this.getVideo();
  },
  methods: {

    goToWelcome: function() {
      this.$router.push("/organisation-de-mariage-cote-d-ivoire");
    },
    getBlogArticles: function() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "https://blog.joursdejoie.com/wp-json/wp/v2/posts?categories=84&_embed"
          )
          .then((response) => {
            
            this.blogArticles = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSlides: function() {
      return new Promise((resolve, reject) => {
        axios
          .get("https://contact.joursdejoie.com/api/v1/sliders")
          .then((response) => {
            this.slides = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getVideo: function() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "https://communication.joursdejoie.com/api/v1/video-is_activated"
          )
          .then((response) => {
            this.video = response.data[0];
            this.image = this.COMMUNICATION + this.video.cover;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    goToBlog: function() {
      window.open("https://blog.joursdejoie.com/", "_blank");
    },
    getCounters() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "https://contact.joursdejoie.com/api/v1/social-count"
          )
          .then((response) => {
            this.counters = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getDateDiff(counterDate) {
      let now = moment().format("YYYY-MM-DD");
      let a = moment(counterDate, "YYYY-MM-DD");
      let b = moment(now, "YYYY-MM-DD");
      let diffDays = b.diff(a, "years");
      return diffDays;
    },
  },
};
</script>
