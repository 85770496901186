<template>
  <div>
    <div id="main-wrapper">
      <Header />
      <BreadCrumb
        bgClass="bg-image--nuptial-notebook-1"
        titre="ERREUR 404"
      ></BreadCrumb>
      <div class="page-wrapper">
        <router-view></router-view>
      </div>
    </div>
    <Newsletter></Newsletter>
    <div class="bg-container-labulle">
      <hr style="box-shadow:1px 3px 5px gray;" />
      <div class="h1 d-flex justify-content-center">
        <div
          class="half-circle"
          title="Jours de joie"
          style="text-align:center;"
        >
          <img class="mx-auto" src="/images/logo.png" alt="" />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<style scoped>
.half-circle {
  height: 190px;
  width: 380px;
  background-color: white;
  border-bottom-left-radius: 300px;
  border-bottom-right-radius: 300px;
  box-shadow: 1px 15px 15px 0px rgb(226, 226, 226);
}
.bg-container-labulle {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-labulle::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/home/home.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Header from "@/components/Header.vue";
import BreadCrumb from "@/components/Breadcrumb.vue";
import Bar from "@/components/nuptial-notebook/Bar.vue";
import Footer from "@/components/Footer.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "error404",
  components: {
    Header,
    BreadCrumb,
    Bar,
    Footer,
    Newsletter
  }
};
</script>
