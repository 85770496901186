<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br /><br />
      <div class="design-maker-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="CHOISISSEZ VOTRE DESIGN DE PAGE" gender="F" />
          </div>
        </div>
        <div class="row" style="position: relative; z-index: 1" v-if="layouts">
          <div
            :style="'position:absolute;z-index:-1;top:-50%;bottom:0;left:85%;right:0;background: url(/images/communication/card.png) no-repeat;background-size:200px;background-repeat:no-repeat;opacity:0.2;'"
          ></div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="" style="font-weight: bold">Design de la page</label>

              <div
                class="d-flex flex-row col-lg-12 flex-wrap bg-light"
                style="padding: 50px"
              >
                <div
                  class="box-model col-md-12 col-sm-12 col-lg-6"
                  v-for="(layout, key) in layouts"
                  style="margin-top: 5%"
                  :key="key"
                >
                  <div class="card" style="">
                    <img
                      :for="key"
                      class="card-img-top pointer_p"
                      @click="goToPageMaker(layout)"
                      :src="layout.path_image"
                      alt="Card image cap"
                    />
                    <div
                      class="card-body border border-danger"
                      style="display: flex; flex-direction: column"
                    >
                      <label
                        :for="key"
                        @click="goToPageMaker(layout)"
                        class="nameTemplate pointer_p"
                        style="font-size: 150%; font-weight: 300"
                      >
                        {{ layout.name }}
                      </label>
                      <a
                        @click="previewTemplate"
                        :href="layout.link_preview_site"
                      >
                        <button
                          class="btn btn-outline-danger"
                          style="padding: 3%; width: 50%; align-self: flex-end"
                        >
                          Aperçu en direct
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <button
              class="secondary-button pull-left"
              @click="returnToChannelChoice"
            >
              <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
            </button>
          </div>
        </div>
        <div class="row" v-else><div class="half-circle-spinner">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
        </div></div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        bottom: 0;
        margin-bottom: 10px;
        transform: scale(4);
      "
      class="click-bottom"
    >
      <div
        @click="scrollToBottom"
        style="margin-left: 8px; padding: 20px; transform: scale(4)"
        class="text-danger"
      >
        <!-- <i class="bi bi-arrow-down-short"></i> -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-arrow-down-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
          />
        </svg>
      </div>
    </div>
    <div ref="bottomEl"></div>
  </div>
</template>
<!-- transform:translate(-10px,-10px); -->

<style scoped>
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.click-bottom {
  -webkit-animation: click-bottom 0.8s infinite;
  animation: click-bottom 0.8s infinite;
  cursor: pointer;
}
.pointer_p {
  cursor: pointer;
}
@-webkit-keyframes click-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

@keyframes click-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
    transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
    transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
    transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
    transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
    transform: translateX(-6px) rotate(1.2deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Koulen&family=Lato&family=Nunito&family=Playfair+Display:ital@1&family=Prata&family=Raleway:ital,wght@1,100&family=Roboto&family=Roboto+Condensed&family=Teko&display=swap");

.btn-click-template {
  font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: #b71c1c;
  background-color: #ffffff;
  padding: 10px 30px;
  border: solid #b71c1c 2px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 2px;
  transition: 1438ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 50%;
}

.btn-click-template:hover {
  transition: 1438ms;
  padding: 10px 34px;
  transform: translateY(-0px);
  background-color: #b71c1c;
  color: #ffffff;
  border: solid 2px #b71c1c;
}

.down-arrow {
  position: absolute;
  top: calc(100vh - 60px);
  left: calc(50% - 14px);
  width: 0;
  height: 30px;
  border: 2px solid;
  border-radius: 2px;
}

.down-arrow:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -10px;
  width: 16px;
  height: 16px;
  border-bottom: 4px solid;
  border-right: 4px solid;
  border-radius: 4px;
  transform: rotateZ(45deg);
}

.clickTemplate {
  color: #b71c1c;
  padding: 5%;
  border: 1px solid #b71c1c;
  background-color: white;
  border-radius: 5%;
  font-weight: bold;
  margin-bottom: 20px;
}
.clickTemplate:hover {
  background-color: #b71c1c;
  color: white;
}
.nameTemplate {
  margin-bottom: 20px;
}
.nameTemplate:hover {
  text-decoration: underline;
  cursor: pointer;
}
.box-model {
  transition: transform 0.3s;
}
.box-model:hover {
  transform: translate(0px, -15px);
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.design-maker-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}

.dm-container {
  position: relative;
  text-align: center;
  /* padding: 100px; */
  /* color: white; */
}

/* Centered text */
.dm-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: cursive;
  font-size: 20px;
}

.selected-box {
}

.pagination li a {
  color: yellow;
}
</style>

<script>
import axios from "axios";
import { defineComponent, ref } from "vue";
import {
  COMMUNICATION,
  API_PREFIX,
  API_VERSION,
  URL_API_JOY,
  NUPTIAL_NOTEBOOK,
  JOYCARD,
} from "@/config";
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import store from "@/store";

export default defineComponent({
  name: "SitewebChoiceComponent",
  setup() {
    return {};
  },
  data: function () {
    return {
      layouts: null,
      base_url: COMMUNICATION.TEST_URL,
      customerChannels: null,
      page: null,
      currentPage: null,
      exampleItems: [],
      pageOfItems: [],
      configTemplate:null
    };
  },
  computed: {
    computedItems() {
      return this.exampleItems;
    },
  },
  mounted() {
    // this.getChannels()
    this.exampleItems = [...Array(150).keys()].map((i) => ({
      id: i + 1,
      name: "Item " + (i + 1),
    }));
    this.getLayouts();
    // console.log("design page", this.page);
  },
  components: {},
  methods: {
    scrollToBottom: function () {
      this.$refs.bottomEl?.scrollIntoView({ behavior: "smooth" });
    },
    returnToChannelChoice: function () {
      this.$emit("return-to-choice-mode");
    },
    // goToPageMaker(){
    //   let app = this;
    //   if(app.currentPage === null){
    //     const toast = () => {
    //     createToast('Choisissez un design de page.')
    //   }
    //  toast();
    //   }else{
    //     this.$emit("go-to-page-maker", this.currentPage);
    //   }

    // },
    addTextBeforePoint: function (phrase, texteAjoute) {
      // Utilisez la méthode split() pour diviser la phrase en morceaux en fonction des points.
      var morceaux = phrase.split(".");

      // Utilisez la méthode join() pour réassembler les morceaux en ajoutant le texte avant chaque point.
      var nouvellePhrase = morceaux.join(texteAjoute + ".");

      return nouvellePhrase;
    },
    previewTemplate(event) {
      event.preventDefault();
      let windowFeatures = "popup";
      let por = event.target.parentNode.getAttribute("href");
      // let url = new URL(por)
      //   console.log('PREVIEW TEMPLATE :', event.target.parentNode.getAttribute('href'))
      window.open(por, "URL", windowFeatures);
    },
    getChannels() {
      let app = this;
      axios
        .get(
          COMMUNICATION.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "customer-channels",
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(function (response) {
          app.customerChannels = response.data;
          console.log(app.customerChannels);
          app.customerChannels.forEach((item) => {
            if (item.id == app.message.channel.id) {
              app.customerChannel = item;
              app.message.customerChannel = app.customerChannel;
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getLayouts: function () {
      let app = this;

      axios
        .get(NUPTIAL_NOTEBOOK.TEST_URL+API_PREFIX+API_VERSION+JOYCARD.GETMODELTEMPLATEURL, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(function (response) {
          app.layouts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getConfigTemplates() {
      axios
        .get(NUPTIAL_NOTEBOOK.TEST_URL+API_PREFIX+API_VERSION+JOYCARD.GETCONFIGTEMPLATE + this.currentPage.id_model_templates)
        .then((response) => {
          let app = this;
          app.configTemplate = response.data;
          this.$emit("go-to-site-web-maker", this.currentPage,this.configTemplate);

          if (
            app.configTemplate.find(
              (element) => element.code === "nbr_photo_fiances"
            ) === undefined
          ) {
            console.log("Resolve ", false);
          } else {
            console.log("Resolve ", true);
            // app.extractPhotoFiances();
            // app.multiple_photo_fiances = JSON.parse(
            //   this.configTemplate.find(
            //     (element) => element.code === "multiple_photo_fiances"
            //   ).value
            // );
            // console.log("MULTIPLE PHOTO FIANCES ", app.multiple_photo_fiances);
            
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    },
    goToPageMaker: function (page) {
      this.currentPage = page;
      let app = this;
      console.log('PAGE CHOSEN ',page)

      if (app.currentPage === null) {
        const toast = () => {
          createToast("Choisissez un design de page.");
        };
        toast();
      } else {
        this.getConfigTemplates();
      }
    },
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
  },
});
</script>
