<template>
  <div>
    <div class="container p-3">
      <big-title label="JOY CARDS" />
      <br />
      <br />
      <div class="message-summary-container p-3">
        <div class="row">
          <div class="col-md-12">
            <sub-title label="Résumé de votre envoi" gender="F" />
          </div>
        </div>
        <div
          class="row"
          style="position: relative; z-index: 1"
          v-if="isLoading == false && message != null"
        >
          <div
            :style="'position:absolute;z-index:-1;top:-15%;bottom:0;left:75%;right:0;background: url(/images/communication/wedding-planning.png) no-repeat;background-size:300px;background-repeat:no-repeat;opacity:0.2;'"
          ></div>
          <div class="col-md-6">
            <div class="col-md-12">
              <div class="form-group">
                <label for>Canal</label>
                <input
                  type="text"
                  class="form-control"
                  :value="message.channel.name"
                  readonly
                />
              </div>
            </div>

            <div
              class="col-md-12"
              v-if="message.targets && message.targets.length > 0"
            >
              <div class="form-group">
                <label for>Cible</label>
                <br />
                <span
                  v-for="(target, key) in message.targets"
                  :key="key"
                  style="margin-left: 5px; margin-right: 5px"
                  class="badge badge-primary"
                >
                  <div style="margin: 10px">{{ target.name }}</div>
                </span>
              </div>
            </div>

            <div v-if="message.mode != undefined">
              <div class="col-md-12" v-if="message.mode.id_mode_joycard != 2">
                <div class="form-group">
                  <label for>Catégorie de message</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="
                      message.category.id != null
                        ? message.category.name
                        : 'Aucun'
                    "
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-12" v-else>
                <div class="form-group">
                  <label for>Le lien du site web</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="message.url"
                    readonly
                  />
                </div>
              </div>
            </div>

            <div v-if="message.mode != undefined">
              <div class="col-md-12" v-if="message.mode.id_mode_joycard != 2">
                <div class="form-group">
                  <label for>Modèle</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="
                      message.model != null ? message.model.name : 'Aucun'
                    "
                    readonly
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <label for>Expéditeur</label>
                <input
                  type="text"
                  class="form-control"
                  v-if="message.from"
                  :value="message.from"
                  readonly
                />
                <input
                  type="text"
                  class="form-control"
                  v-else
                  value="Moi même"
                  readonly
                />
              </div>
            </div>

            <div class="col-md-12" v-if="message.subject">
              <div class="form-group">
                <label for>Sujet</label>
                <input
                  type="text"
                  class="form-control"
                  :value="message.subject"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-md-6 pb-3">
            <div
              class="col-md-12"
              v-if="
                message &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              "
            >
              <div class="alert alert-danger text-center">
                Le nombre total de message à envoyer est :
                <span class="badge badge-secondary">{{
                  message.messageNumber +
                  " message(s)" +
                  " x " +
                  message.tab.length +
                  " invité(s) = " +
                  Number(message.messageNumber) * message.tab.length +
                  " message(s)"
                }}</span>
              </div>
            </div>
            <div
              class="col-md-12"
              v-if="
                message &&
                message.peopleCount !== null &&
                message.messageNumber !== null
              "
            >
              <!-- <div class="alert alert-danger text-center"   >
                Vous aurez à payer :
                <span class="badge badge-secondary" v-if="messageSellingPrice == 0 ">{{
                  message.channel.selling_price +
                    " FCFA x " + Number(message.messageNumber) *
                        message.tab.length +
                     
                    " message(s) " +
                    "  = " +
                    messageSellingPrice +
                    " FCFA"
                }}</span>
                <span class="badge badge-secondary" v-else>{{
                  message.channel.selling_price +
                    " FCFA x " +
               ((Number(message.messageNumber) *  message.tab.length ) - Number(message.customerChannel.pivot.remaining_messages))  +
                    " message(s) " +
                    "  = " +
                    messageSellingPrice +
                    " FCFA"
                }}</span>
              </div> -->
            </div>
            <i class="text-danger">
              (Toutes les informations personnelles que vous verrez ne sont que
              des valeurs de tests. Lors de l'envoi du message à votre invité ce
              sont les valeurs renseignées au préalable dans votre carnet
              nuptial qui apparaitront.)
            </i>
            <div v-if="message && message != null">
              <div
                class=""
                v-if="message.channel.id === 3 || message.mode.id_mode_joycard===2"
                style="margin-bottom: 20px"
              >
                <div
                  style="
                    border: 1px solid;
                    padding: 20px;
                    background-color: #fff;
                  "
                >
                  {{ message.displayMessage }}
                </div>
              </div>
              <div v-else>
                <div
                  style="position: relative; text-align: center"
                  v-if="
                    message.layout &&
                    message.layout != null &&
                    message.displayMessage != null
                  "
                >
                  <img
                    :src="base_url + message.layout.image"
                    style="width: 100%; height: auto"
                    alt
                  />
                  <div
                    class="dm-centered"
                    :style="'color:'"
                    v-html="message.displayMessage"
                  ></div>
                </div>
              </div>
            </div>
            <div v-else>Chargement en cours ...</div>
          </div>
          <div class="col-md-12">
            <!-- <button
              class="secondary-button pull-left"
              @click="returnToTargetChoice"
            >
              <i class="fa fa-chevron-left" style="color: #fff"></i> PRÉCÉDENT
            </button>
            <button class="primary-button pull-right" @click="goToPayment">
              SUIVANT
              <i class="fa fa-chevron-right" style="color: #fff"></i>
            </button> -->

            <!-- <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                message.customerChannel.id != parseInt('1')
              "
              class="primary-button pull-right"
              @click="sendMessage"
            >
              <i class="fa fa-check" style="color: #fff"></i> ENVOYER
            </button> -->
            <!-- <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                (message.customerChannel.id === parseInt('1'))
              "
              class="primary-button pull-right"
              @click="sendMessageByWhatsapp"
            >
              <div v-if="success && message.customerChannel.id===parseInt('1')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                  />
                </svg>

                <ShareNetwork
                  :network="conf.network"
                  :url="sharing.url"
                  :title="sharing.title"
                  :description="sharing.description"
                  ref="shareNetworkRef"
                  :background="{ color: 'white', backgroundColor: conf.color }"
                  @open="runWorkaround"
                >
                  {{ conf.name }}
                </ShareNetwork>
              </div>
              <div v-else>Valider la carte digitale</div>
            </button> -->
            <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                (message.customerChannel.id === parseInt('4'))
              "
              @click="sendMessage" 
              class="primary-button pull-right"
            >
            ENVOYER
          </button>
          <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                (message.customerChannel.id === parseInt('2'))
              "
              @click="sendMessage" 
              class="primary-button pull-right"
            >
            ENVOYER
          </button>
          <!-- <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                message.customerChannel.id != parseInt('1') && parseInt(message.customerChannel.pivot.remaining_messages)>0 && success === false
              "
              class="primary-button pull-right"
              @click="sendMessageByWhatsapp" 
            >
              <i class="fa fa-check" style="color: #fff"></i> Valider la carte digitale
            </button> -->
            <button
              v-if="
                message.customerChannel.selling_price === '0' &&
                message.customerChannel.id === parseInt('1') && parseInt(message.customerChannel.pivot.remaining_messages)>0
              "
              class="primary-button pull-right"
              @click="sendMessageByWhatsapp" 
            >
            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                  v-if="success===true"
                >
                  <path
                    d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                  />
                </svg>

                <ShareNetwork
                  v-if="success===true"
                  :network="conf.network"
                  :url="sharing.url"
                  :title="sharing.title"
                  :description="sharing.description"
                  ref="shareNetworkRef"
                  :background="{ color: 'white', backgroundColor: conf.color }"
                  @open="runWorkaround"
                >
                  <span style="margin-left:10px;color:white">{{ conf.name }}</span>
                </ShareNetwork>
                <div v-if="success===false">
                  Valider la carte digitale
                </div>
            </button>
              

            <button
              v-if="message.customerChannel.id===3 && parseInt(message.customerChannel.selling_price)>0 && parseInt(message.customerChannel.pivot.remaining_messages)<=0"
              class="primary-button pull-right"
              @click="goToPayment"
            >
              <i class="fa fa-cart" style="color: #fff"></i> Passer au paiement
            </button>
          </div>
        </div>
        <div v-else>
          <spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.message-summary-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
}

.dm-container {
  position: relative;
  text-align: center;
  /* padding: 100px; */
  /* color: white; */
}

/* Centered text */
.dm-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: cursive;
  font-size: 12px;
}
</style>

<script>
import { defineComponent } from "vue";
import {
  COMMUNICATION,
  API_PREFIX,
  API_VERSION,
  URL_SITE_WEB,
  JOYCARD,
  JOURSDEJOIE,
  NUPTIAL_NOTEBOOK,
} from "@/config";
import axios from "axios";
import { createToast } from "mosha-vue-toastify";
import { ref } from "vue";

export default defineComponent({
  name: "MessageSummaryComponent",
  props: ["message"],
  setup() {
    const shareNetworkRef = ref(null);

    function runWorkaround() {
      try {
        if (shareNetworkRef.value === null) return;

        clearInterval(shareNetworkRef.value.popupInterval);
        shareNetworkRef.value = undefined;
        window.location.href =
              "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";

      } catch (e) {}
    }

    return {
      shareNetworkRef,
      runWorkaround,
    };
  },
  data: function () {
    return {
      base_url: COMMUNICATION.TEST_URL,
      isLoading: false,
      displayMessage: null,
      clientcounter: 0,
      countDown: 1,
      createdMessage: null,
      success: false,
      sharing: {
        url: "",
        title:
          this.message === undefined
            ? null
            : this.message.mode.id_mode_joycard === 1
            ? this.message.subject
            : "Annonce de mariage couple heureux !",
        description:
          this.message === undefined
            ? null
            : this.message.mode.id_mode_joycard === 1
            ? this.message.displayMessage
            : `C'est avec un immense bonheur que nous vous annonçons notre mariage ! Nous serions heureux de partager ce moment unique avec vous et de célébrer notre amour entourés de nos proches. Cliquez sur ce lien pour plus d'informations ${this.message.url}`,
      },
      conf: {
        network: "whatsapp",
        name: "Whatsapp",
        icon: "fab fah fa-lg fa-whatsapp",
        color: "#25d366",
      },
    };
  },
  computed: {
    messageSellingPrice: {
      get: function () {
        if (
          Number(this.message.customerChannel.pivot.remaining_messages) <
          Number(this.message.messageNumber) * this.clientcounter
        ) {
          return (
            Number(this.message.channel.selling_price) *
            (Number(this.message.messageNumber) * this.clientcounter -
              Number(this.message.customerChannel.pivot.remaining_messages))
          );
        } else {
          return 0;
        }
      },
      set: function (message) {
        this.clientcounter = message.tab.length;
      },
    },
  },
  mounted() {
    if (this.message) {
      this.displayMessage = this.message.body
        .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "01/12/2020")
        .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE", "20h00")
        .replace(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_FILLE",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "01/12/2020")
        .replace("HEURE_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON", "20h00")
        .replace(
          "LIEU_ENTERREMENT_DE_VIE_DE_JEUNE_GARCON",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_RECEPTION_DE_MARIAGE_CIVIL", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_MARIAGE_CIVIL", "08h00")
        .replace("LIEU_RECEPTION_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

        .replace("DATE_RECEPTION_DE_DOT", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_DOT", "10h00")
        .replace(
          "LIEU_RECEPTION_DE_DOT",
          "à Angré, Star 11, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_RECEPTION_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replace("HEURE_RECEPTION_DE_MARIAGE_RELIGIEUX", "12h00")
        .replace(
          "LIEU_RECEPTION_DE_MARIAGE_RELIGIEUX",
          "à Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_MARIAGE_RELIGIEUX", "12h00")
        .replace(
          "LIEU_CEREMONIE_DE_MARIAGE_RELIGIEUX",
          "à la Paroisse Ste Cécile, Vallons"
        )

        .replace("DATE_CEREMONIE_DE_DOT", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_DOT", "08h30")
        .replace(
          "LIEU_CEREMONIE_DE_DOT",
          "à Angré, Star 14, Abidjan, Côte d'ivoire"
        )

        .replace("DATE_CEREMONIE_DE_MARIAGE_CIVIL", "01/12/2020")
        .replace("HEURE_CEREMONIE_DE_MARIAGE_CIVIL", "09h00")
        .replace("LIEU_CEREMONIE_DE_MARIAGE_CIVIL", "à la Mairie de Cocody")

        .replace("CIVILITE_INVITE", "M.")
        .replace("NOM_INVITE", "Guépié")
        .replace("PRENOMS_INVITE", "Freddy")
        .replace("EMAIL_INVITE", "freddy.guepie@gmail.com")
        .replace("CONTACT_1_INVITE", "(+225) 02 00 00 01")
        .replace("CONTACT_2_INVITE", "(+225) 02 00 00 0é")

        .replace("CIVILITE_PCO", "M.")
        .replace("NOM_PCO", "Doe")
        .replace("PRENOMS_PCO", "Cedric")
        .replace("EMAIL_PCO", "cedric.doe@gmail.com")
        .replace("CONTACT_1_PCO", "(+225) 01 00 00 01")
        .replace("CONTACT_2_PCO", "(+225) 01 00 00 01")

        .replace("CIVILITE_FIANCEE", "Mlle")
        .replace("NOM_FIANCEE", "Doe")
        .replace("PRENOMS_FIANCEE", "Jane")
        .replace("EMAIL_FIANCEE", "jane.doe@gmail.com")
        .replace("CONTACT_1_FIANCEE", "(+225) 01 00 10 01")
        .replace("CONTACT_2_FIANCEE", "(+225) 01 00 11 01")

        .replace("CIVILITE_FIANCE", "M")
        .replace("NOM_FIANCE", "Doe")
        .replace("PRENOMS_FIANCE", "John")
        .replace("EMAIL_FIANCE", "john.doe@gmail.com")
        .replace("CONTACT_1_FIANCE", "(+225) 01 10 10 01")
        .replace("CONTACT_2_FIANCE", "(+225) 01 01 11 01");
    }

    this.countDownTimer();
  },
  methods: {
    sendMessageByWhatsapp: function () {
      this.sendMessage();
      // this.$emit('open')
      console.log("Send Message ByWhatsapp");
    },
    returnToTargetChoice: function () {
      this.$emit("return-to-target-choice");
    },
    goToChannelChoice: function () {
      this.$emit("go-to-channel-choice", this.message);
    },
    goToPayment() {
      let app = this;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });

      // app.createdMessage = {
      //   channel_id: app.message.channel.id,
      //   label: app.message.label,
      //   message_category_id: app.message.category.id,
      //   message_model_id: app.message.model.id,
      //   message_design_id:
      //   app.message.layout != null ? app.message.layout.id : null,
      //   from: app.message.from,
      //   subject: app.message.subject,
      //   body: app.message.body,
      //   tab: app.message.tab,
      //   target: targetIds.join(";")
      // };
      if (app.message.mode.name === "Site web") {
        (app.message.channel_id = app.message.channel
          ? app.message.channel.id
          : null),
          (app.message.label = app.message.label),
          (app.message.message_category_id = app.message.category.id),
          (app.message.message_model_id = null),
          (app.message.message_design_id = null),
          (app.message.from = app.message.from),
          (app.message.model = app.message.model);
        (app.message.subject = app.message.subject),
          (app.message.body = app.message.body),
          (app.message.tab = app.message.tab),
          (app.message.target = targetIds.join(";"));

        this.message.sellingPrice = this.messageSellingPrice;

        this.$emit("go-to-payment", app.message);
      } else {
        (app.message.channel_id = app.message.channel
          ? app.message.channel.id
          : null),
          (app.message.label = app.message.label),
          (app.message.message_category_id = app.message.category.id),
          (app.message.message_model_id = app.message.model
            ? app.message.model.id
            : null),
          (app.message.message_design_id = app.message.layout
            ? app.message.layout.id
            : null),
          (app.message.from = app.message.from),
          (app.message.model = app.message.model);
        (app.message.subject = app.message.subject),
          (app.message.body = app.message.body),
          (app.message.tab = app.message.tab),
          (app.message.target = targetIds.join(";"));

        this.message.sellingPrice = this.messageSellingPrice;

        this.$emit("go-to-payment", app.message);
      }
    },
    showPaymentModal() {
      this.$modal.show("payment-modal");
    },
    checkout() {
      this.$modal.hide("payment-modal");
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown += 1;
          this.countDownTimer();
        }, 1000);
        if (this.message) {
          this.clientcounter = this.message.tab.length;
          this.message.tab.length = this.clientcounter;
        }
      }
    },
    sendMessage: function () {
      let app = this;
      app.isLoading = true;
      let targetIds = [];
      app.message.targets.forEach((target) => {
        targetIds.push(target.id);
      });
      if (app.message.mode.name === "Site web") {
        app.createdMessage = {
          channel_id: app.message.channel.id,
          label: app.message.label,
          message_category_id: app.message.category.id,
          message_model_id: 7,
          message_design_id: 28,
          from: app.message.from,
          subject: app.message.subject,
          customerChannel: app.message.customerChannel,
          body: app.message.body,
          tab: app.message.tab,
          messageLength: app.message.messageLength,
          messageNumber: app.message.messageNumber,
          target: targetIds.join(";"),
        };
          axios
            .post(
              NUPTIAL_NOTEBOOK.TEST_URL +
                API_PREFIX +
                API_VERSION +
                JOYCARD.POSTCARACTERISTICTEMPLATE,
              app.message.ipsum,
              {
                "content-type": "multipart/form-data",
              }
            )
            .then((res) => {
              console.log("Reponses :", res);
            })
            .catch((err) => {
              console.log(err);
            });

          axios
            .post(
              NUPTIAL_NOTEBOOK.TEST_URL +
                API_PREFIX +
                API_VERSION +
                JOYCARD.POSTCARACTERISTICTEMPLATE,
              app.message.lorem,
              {
                "content-type": "multipart/form-data",
              }
            )
            .then((res) => {
              console.log("Reponses :", res);
            })
            .catch((err) => {
              console.log(err);
            });
      } else {
        app.createdMessage = {
          channel_id: app.message.channel.id,
          customerChannel: app.message.customerChannel,
          label: app.message.label,
          message_category_id: app.message.category.id,
          message_model_id:
            app.message.model != null ? app.message.model.id : null,
          message_design_id:
            app.message.layout != null ? app.message.layout.id : null,
          from: app.message.from,
          subject: app.message.subject,
          body: app.message.body,
          tab: app.message.tab,
          messageNumber: app.message.messageNumber,
          target: targetIds.join(";"),
        };
      }

        if(app.message.mode.id_mode_joycard){
          axios
        .post(
          COMMUNICATION.TEST_URL + API_PREFIX + API_VERSION + "messages",
          app.createdMessage,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.isLoading = false;
          let result = response.data;
          console.log('RESPONSE ',response.data);
          if (app.message.channel.id != 1) {
            // let url =

            //   "https://joursdejoie.com/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
            //   result.data.id;
            
            let url =
              JOURSDEJOIE +
              "carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;
            window.open(
              "https://www.facebook.com/sharer/sharer.php?app_id=548126212259891&sdk=joey&u=" +
                url,
              "_blank"
            );
            // app.$Progress.finish();
            window.location.href =
              "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";

            this.$createToast(
              {
                title: "Operation reussie !",
                description: "Message envoyé avec succès  !",
              },
              {
                position: "top-center",
                type: "success",
                timeout: 10000,
                transition: "bounce",
              }
            );
          }
          if (app.message.channel.id === 1&&app.message.mode.id_mode_joycard===1) {
            let url =
              JOURSDEJOIE +
              "carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/wedding-card/" +
              result.data.id;

            this.sharing.url = url;
            if (response.data.success != 0 && response.data.total != 0) {
              this.success = true;
            }
            // window.location.href =
            //   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";

            this.$createToast(
              {
                title: "Operation reussie !",
                description: "Message enregistré avec succès  ! Cliquez sur le bouton en bas pour l'envoyer à vos contacts !",
              },
              {
                position: "top-center",
                type: "success",
                timeout: 10000,
                transition: "bounce",
              })
          }if(app.message.channel.id === 1&&app.message.mode.id_mode_joycard===2){

            let url = this.message.url;
 
            this.sharing.url = url;
            if (response.data.success != 0 && response.data.total != 0) {
              this.success = true;
            }
            // window.location.href =
            //   "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/carte-d-invitation-mariage";

            this.$createToast(
              {
                title: "Operation reussie !",
                description: "Message enregistré avec succès  ! Cliquez sur le bouton en bas pour l'envoyer à vos contacts !",
              },
              {
                position: "top-center",
                type: "success",
                timeout: 10000,
                transition: "bounce",
              })

          }
        })
        .catch((error) => {
          console.log(error);
          app.isLoading = false;
          this.success = false;
          // app.$Progress.fail();
          app.$moshaToast("Erreur détectée lors de l'envoie du message !");
        });

        }
        // else{
        //   app.isLoading = false;
        //   this.success=true;
        //   this.sharing.url= app.message.url ===undefined ? this.site_web_url+app.message.url_site_web : this.site_web_url+app.message.url
        // }
      
    },
  },
});
</script>
