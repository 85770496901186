import { getQuoteRequestsAPI, storeQuoteRequestAPI } from "@/views/wedding-provider/apis";

export const state = () => ({
    isLoading: false,
    errors: {},
    errorMessage: {},
    quoteRequests: [],
    isSuccess: false
})

export const actions = {
    async getQuoteRequests({ commit }, params) {
        commit('setLoading', true);
        try {
            const response = await getQuoteRequestsAPI(params);
            // console.log('testerdata',response)
            commit('setQuoteRequests', response.data);
            commit('setLoading', false);
        } catch (error) {
            console.log('tester',error)
            commit('setLoading', false);
            commit('setQuoteRequests', []);
            // commit('setErrors', error.response.data);
        }
    },
    async storeQuoteRequest({ commit }, params = {}) {
        commit('setLoading', true);
        try {
            const response = await storeQuoteRequestAPI(params);
            commit('setQuoteRequests', response.data);
            commit('setLoading', false);
            commit('setSuccess', true);
        } catch (error) {
            console.log(error)
            commit('setLoading', false);
            commit('setQuoteRequests', []);
            commit('setErrors', error.errors);
            commit('setErrorMessage', error.message);
            commit('setSuccess', false);
        }
    }
}

export const mutations = {
    setLoading(state, value) {
        state.isLoading = value
    },
    setErrors(state, value) {
        state.errors = value
    },
    setErrorMessage(state, value) {
        state.errorMessage = value
    },
    setQuoteRequests(state, value) {
        state.quoteRequests = value
    },
    setSuccess(state, value) {
        
        state.isSuccess = value
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}