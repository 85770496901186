<template>
  <div>
    <!-- <vue3-progress-bar></vue3-progress-bar> -->
    <Header></Header>
    <div class="wrapper">
      <router-view></router-view>
    </div>
    <Newsletter></Newsletter>
    <div class="bg-container-labulle">
      <hr style="box-shadow:1px 3px 5px gray;" />
      <div class="h1 d-flex justify-content-center" style="position:relative; top:0px;">

        <div
          class="half-circle"
          title="Jours de joie"
          style="text-align:center;"
        >
        
          <img
            class="mx-auto"
            style="width: 200px;"
            src="/images/logo.png"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<style scoped>
.half-circle {
  height: 190px;
  width: 380px;
  background-color: white;
  border-bottom-left-radius: 300px;
  border-bottom-right-radius: 300px;
  box-shadow: 1px 15px 15px 0px rgb(226, 226, 226);
}
.bg-container-labulle {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
  top: 0px;
}

.bg-container-labulle::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/home/home.png");
  background-size: cover;
  opacity: 0.1;
}
</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Newsletter from "@/components/Newsletter.vue";
import store from '@/store';
export default {
  name: "mairie",
  components: {
    Header,
    Footer,
    Newsletter
  },

  mounted() {
    store
      .dispatch("get_customer_info")
      .then(response => {})
      .catch(error => {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }
};
</script>