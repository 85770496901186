<template>
  <div v-if="icon && action">
    <button :class="`rounded-full ${bg_color_class} px-2 py-2 shadow flex justify-center items-center`" @click="action">
      <!-- <font-awesome-icon :class="icon_color_class" :icon="icon"></font-awesome-icon> -->
      <i :class="icon" class="text-red-800 fa"></i>
      <span class="px-2" v-if="text">{{ text }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "PillComponent",
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    icon_color_class: {
      type: String,
      default: 'text-gray-600',
    },
    bg_color_class: {
      type: String,
      default: 'bg-gray-100',
    },
    action: {
      type: Function,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>