<template>
  <div>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor"
        class="text-danger bi bi-2-circle-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z"
        />
      </svg>
    </div>

    <div class="bg-container-dates-places">
      <div v-if="isLoading == true">
        <div class="half-circle-spinner">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
        </div>
      </div>
      <div
        class=""
        v-else-if="user.email_verified_at != null && customer.dob != null"
      >
        <div class="dates-places-container p-3">
          <sub-title
            label="CHOISISSEZ VOS DIFFERENTES CELEBRATIONS A AFFICHER :"
          />
          <div
            v-if="
              isLoading == false &&
              customer != null &&
              columns.length > 0 &&
              rows.length > 0
            "
            class="table-responsive"
          >
            <div style="flex-direction: column; margin-bottom: 10px">
              <button
                class="primary-button"
                type="submit"
                style="justify-self: flex-start"
                @click="saveCelebrations"
                v-if="passToken === true"
              >
                ENREGISTRER</button
              >&nbsp;
            </div>
            <label
              class="text-danger"
              v-if="passToken === false"
              style="font-size: 120%"
              >*L'heure et la date ne sont pas marquées pour certaines
              celebrations ! Retournez à la rubrique
              <a
                href="http://localhost:8080/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/dates-et-lieux-de-mariage"
                >Date et lieux</a
              ></label
            >
            <div class="" style="color: red">
              Vous pouvez choisir seulement {{ nbr_celebrations }} celebrations
              à afficher sur votre site
            </div>
            <select
              @change="addInChoosen"
              class="form-control"
              style="margin-bottom: 50px"
            >
              <option v-for="ceremonie in rows" :value="ceremonie.name">
                {{ ceremonie.name }}
              </option>
            </select>

            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Cérémonie</th>
                  <th scope="col">Date</th>
                  <th scope="col">Heure</th>
                  <th scope="col">Lieu</th>
                  <th scope="col" style="text-align: right">Supprimer</th>
                </tr>
              </thead>
              <tbody v-if="choosen.length === 0">
                <tr v-for="(ceremonie, index) in choosen" :key="index">
                  <th scope="row">{{ ceremonie.name }}</th>
                  <td>{{ ceremonie.date }}</td>
                  <td>{{ ceremonie.time }}</td>
                  <td>{{ ceremonie.location }}</td>
                  <td>
                    <button
                      class="primary-button pull-right"
                      @click="removeFromChoosen"
                      :name="ceremonie.name"
                    >
                      SUPPRIMER
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="(ceremonie, index) in choosen" :key="index">
                  <th scope="row">{{ ceremonie.name }}</th>
                  <td>{{ ceremonie.date }}</td>
                  <td>{{ ceremonie.time }}</td>
                  <td>{{ ceremonie.location }}</td>
                  <td>
                    <button
                      class="primary-button pull-right"
                      @click="removeFromChoosen"
                      :name="ceremonie.name"
                    >
                      SUPPRIMER
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="
                choosen.length > 0 &&
                configTemplate.find(
                  (element) => element.code === 'photo_celebrations'
                )
              "
            >
              <div v-for="(chose, index) in choosen" :key="index">
                <label
              v-if="configTemplate.length != 0"
              class="input-group-text label-file-control text-left"
              for="inputGroupFile01"
              style="font-size: 150%; font-weight: bold"
              >{{
                `Choisissez une image pour cet evenement :${chose.name}`
              }}</label>
              <br/>
                <label
                  class="text-danger"
                  >{{
                    `*Dimensions: ${
                      configTemplate.find(
                        (element) => element.code === "photo_celebrations_dimensions"
                      ).value
                    }`
                  }}</label
                >
                <BlockImageComponent
                  :key="index"
                  :identifier="num"
                  :name="`photo_celebrations_${index + 1}`"
                  :caracteristic_user_template="caracteristic_user_template"
                  :allowMultiples="false"
                  @save-img="saveImg"
                  @delete-img="deleteImg"
                >
                </BlockImageComponent>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION, AUTH } from "@/config";
import Bar from "@/components/nuptial-notebook/Bar.vue";
// import { mdbDatatable, mdbContainer } from "mdbvue";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net";

DataTable.use(DataTablesCore);
import axios from "axios";

import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

import store from "@/store";
import BlockImageComponent from "./BlockImageComponent.vue";

export default {
  name: "CelebrationsComponent",
  setup() {},

  components: {
    BlockImageComponent,
  },
  data() {
    return {
      showModal: false,
      datesPlaces: null,
      isLoading: false,
      user: JSON.parse(store.state.user),
      customer: { dob: null },
      textFullDisplay: false,
      choosen: this.caracteristic_user_template.celebrations === undefined ? [] :this.caracteristic_user_template.celebrations,
      name: "",
      type: "",
      value: "",
      passToken: true,
      nbr_celebrations: 1,
      datatest: [
        [1, 2],
        [3, 4],
      ],
      columns: [
        { label: "Cérémonie", field: "name", sort: "desc" },
        { label: "Date", field: "date", sort: "desc" },
        { label: "Heure", field: "time", sort: "desc" },
        { label: "Lieu", field: "location", sort: "desc" },
        {
          label: "Statut",
          field: "status",
          sort: "desc",
          format: (value) =>
            value == 1
              ? '<span class="badge badge-success">Prévu</span>'
              : '<span class="badge badge-danger">Non prévu</span>',
        },
        {
          label: "Action",
          field: "action",
          sort: "desc",
        },
      ],
      rows: [],
    };
  },
  props: ["configTemplate","caracteristic_user_template"],
  computed: {
    data() {
      return {
        columns: this.columns,
        rows: null,
      };
    },
  },
  beforeUnmount() {
    this.$emit("set-loading", false);
  },
  mounted() {
    this.nbr_celebrations = this.configTemplate.find(
      (element) => element.code === "nbr_celebrations"
    ).value;
    this.$emit("set-loading", true);
    this.getCustomerInfo()
      .then((customer) => {
        this.getDatesPlaces(customer.event.id);
        this.getGuestsCount(customer.event.id);
      })
      .catch((error) => {
        console.log("Mounted error", error);
      });
    this.show();
    // console.log("CELEBRATIONS :", this.getC());
    // console.log("CELEBRATIONS :", store.getters.getCelebrations);
  },
  methods: {
    deleteImg: function (payload) {
      this.$emit("delete-img", payload);
    },
    saveImg: function (payload) {
      // console.log('save img',payload)
      this.$emit("save-img", payload);
    },
    getCustomerInfo: function () {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.TEST_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getGuestsCount: function (eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "guests-by-event-get/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.guestsCount = response.data.length;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response.data);
        });
    },
    objectToString: function (obj) {
      try {
        return JSON.stringify(obj);
      } catch (error) {
        return "Error converting object to string: " + error.message;
      }
    },
    saveCelebrations: function () {
      if (this.passToken === false) {
        const toast = () => {
          createToast(
            "L'heure et la localisation de certaines celebrations ne sont pas marquées !",
            {
              type: "warning",
            }
          );
        };
        toast();
      }

      if (this.choosen.length < parseInt(this.nbr_celebrations)) {
        const toast = () => {
          createToast(
            `Vous n'avez pas choisi le nombre requis de célébrations ${parseInt(
              this.nbr_celebrations
            )}`,
            {
              type: "warning",
            }
          );
        };
        toast();
      } else {
        const toast = () => {
          createToast("Enregistré !", {
            type: "success",
          });
        };
        toast();
        this.$emit("save-celebrations", this.choosen);
        // store.commit("setCelebrations", c);
      }
    },
    replaceDoubleQuotesWithSingleQuotes: function (inputString) {
      return inputString.replace(/"/g, "'");
    },
    goToPrevious: function () {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/planning-de-mariage"
      );
    },
    goToNext: function () {
      this.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/invites-pour-le-mariage"
      );
    },
    edit: function (eventId, datePlaceId) {
      let app = this;
      app.$router.push(
        "/carnet-nuptial-pour-organiser-son-mariage-cote-d-ivoire/modification-dates-et-lieux-de-mariage/" +
          eventId +
          "/" +
          datePlaceId
      );
    },
    addInChoosen: function (event) {
      if (this.choosen.find((row) => row.name === event.target.value)) {
      } else {
        if (this.choosen.length < parseInt(this.nbr_celebrations)) {
          this.choosen.push(
            this.rows.find((row) => row.name === event.target.value)
          );
        } else {
          const toast = () => {
            createToast(
              `Impossible d'ajouter plus de ${this.nbr_celebrations} celebrations!`,
              {
                type: "warning",
              }
            );
          };
          toast();
        }
      }
      this.$emit("save-celebrations", this.choosen);
      // this.$store.commit("setCelebrations", JSON.stringify(this.choosen));
    },
    removeFromChoosen: function (event) {
      this.choosen = this.choosen.filter(
        (item) => item.name != event.target.name
      );
      this.$emit("save-celebrations", this.choosen);
      // this.$store.commit("setCelebrations", JSON.stringify(this.choosen));
    },
    getDatesPlaces: function (eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "dates-places-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          response.data.map((datePlace) =>
            this.rows.push({
              name: datePlace.name,
              date: datePlace.pivot.wedding_date,
              time: datePlace.pivot.wedding_time,
              location:
                datePlace.pivot.wedding_location_prefix +
                " " +
                datePlace.pivot.wedding_location,
              status: Number(datePlace.pivot.is_activate),
              action: datePlace.pivot,
            })
          );
          for (let i = 0; i < this.rows.length; i++) {
            if (
              this.rows[i].time === null ||
              this.rows[i].location === "à null"
            ) {
              console.log("END !");
              this.passToken = false;
              return 0;
            }
          }

          this.isLoading = false;
          this.$emit("mounted", this.rows);
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    show() {
      if (!this.user.email_verified_at) {
        this.showModal = true;
      }
    },
    // getC() {
    //   let app = this;
    //   try {
    //     let over = store.getters.getCelebrations;
    //     let open = JSON.parse(over);
    //     // console.log(typeof over)
    //     console.log("OPEN ", open);
    //     this.choosen = open;
    //   } catch (error) {
    //     console.log("ERROR ", error);
    //   }
    // },
  },
};
</script>

<style scoped>
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dates-places-container {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  -moz-box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  box-shadow: 0px 0px 5px 1px rgba(85, 85, 85, 0.3);
  background: #fff;
}

.icon {
  color: #fff;
}

.bg-container-dates-places {
  width: 100%;
  /* height: 300px; */
  /* border: 1px solid #000; */
  position: relative;
}

.bg-container-dates-places .content {
  position: absolute;
  z-index: 1;
  text-align: center;
  width: 100%;
  opacity: 1;
}

.bg-container-dates-places::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("@/images/backgrounds/nuptial-notebook/dates-places.png");
  background-size: cover;
  opacity: 0.1;
}
</style>
