<template>
    <section class="page_error section-padding--lg bg--white">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <center>
                        <img src="/images/404.jpg" alt="" style="width:400px;">
                        <h2>
                            ERREUR <span style="color:#b71c1c;">404</span>
                        </h2>
                        <p>
                            La page demandé est impossible à trouver ou inexistante !<br><br>
                            <router-link to="/" style="padding:15px;color:#fff;" class="primary-button">
                                Retourner à l'accueil
                            </router-link>
                        </p>
                    </center>
                </div>
            </div>
        </div>
    </section>
</template>