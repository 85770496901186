<template>
  <div>
    <!-- <vue-progress-bar></vue-progress-bar> -->
    <Breadcrumb bgClass="bg-image--contact-1" titre="MON COMPTE"></Breadcrumb>

    <div class="container p-3" style="padding: 25px;">
      <big-title label="INFOS DU COMPTE" />
      <br /><br />
      <div v-if="isLoading == false && customer != null">
        <div class="card-container p-3">
          <div class="row">
            <div class="col-md-6">
              <select-input
                label="Civilité"
                name="civility"
                type="text"
                :rules="'required'"
                :isRequired="true"
                :options="civilities"
                v-model="customer.civility"
              />
            </div>
            <div class="col-md-6">
              <text-input
                label="Nom"
                name="last_name"
                type="text"
                :rules="'required'"
                :isRequired="true"
                v-model="customer.last_name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <text-input
                label="Prénoms"
                name="given_names"
                type="text"
                :rules="'required'"
                :isRequired="true"
                v-model="customer.given_names"
              />
            </div>
            <div class="col-md-6">
              <text-input
                label="Adresse email"
                name="email"
                type="email"
                :rules="'required|email'"
                :isRequired="true"
                v-model="customer.email"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <masked-input
                label="Téléphone principal"
                name="phone_number_1"
                type="text"
                :rules="'required|min:21|max:22'"
                :isRequired="true"
                v-model="customer.phone_number_1"
                placeholder="+(225) 00 00 00 00 00"
                :mask="'+{(000)} 00 00 00 00 00'"
              />
            </div>
            <div class="col-md-3">
              <masked-input
                label="Téléphone secondaire"
                name="phone_number_2"
                type="text"
                :rules="'min:21|max:22'"
                :isRequired="false"
                v-model="customer.phone_number_2"
                placeholder="+(225) 00 00 00 00 00"
                :mask="'+{(000)} 00 00 00 00 00'"
              />
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <text-input
                  label="Date de naissance"
                  name="dob"
                  type="date"
                  :rules="'required'"
                  :isRequired="true"
                  v-model="customer.dob"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="wedding_is_here == false">
              <toggle-button
                v-model="exactDateType"
                :width="180"
                :height="25"
                :font-size="12"
                :labels="{
                  checked: 'DATE EXACTE',
                  unchecked: 'DATE APPROXIMATIVE',
                }"
                :color="{
                  checked: '#B71C1C',
                  unchecked: '#9E9E9E',
                  disabled: '#CCCCCC',
                }"
              />
              <div class="form-group" v-if="exactDateType == true">
                <text-input
                  label="Date de mariage"
                  name="wedding_date"
                  type="date"
                  :rules="'required'"
                  :isRequired="true"
                  v-model="wedding_date"
                />
              </div>
              <div class="form-group" v-else>
                <label for=""
                  >Date de mariage <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <input
                    v-model="wedding_date_number"
                    type="number"
                    placeholder=""
                    class="col-md-2 form-control"
                    name=""
                    id=""
                  />
                  <select
                    v-model="wedding_date_surfix"
                    name=""
                    id=""
                    class="col-md-10 form-control"
                  >
                    <option value="days">Jour(s)</option>
                    <option value="weeks">Semaine(s)</option>
                    <option value="months">Mois</option>
                    <option value="years" selected>Année(s)</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <button @click="updateAccount" class="primary-button">
            <i class="fa fa-check" style="color: #fff;"></i> Envoyer
          </button>
        </div>
      </div>
      <div v-else>
        <spinner /><br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import axios from "axios";
import moment from "moment";
import { AUTH, NUPTIAL_NOTEBOOK, API_PREFIX, API_VERSION } from "@/config";
import { useMeta } from 'vue-meta'
export default {
  name: "AccountInfoEdit",
  setup () {
    useMeta({
      title:
      "Informations de compte, Assistant digital d'organisation de mariage",
      titleTemplate: "%s | Jours de Joie",
      htmlAttrs: {
        lang: "fr",
        amp: true,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Modification des informations du compte",
        },
        {
          name: "keywords",
          content:
            "Agence d’organisation de mariage en Côte d’Ivoire - Agence d’organisation de mariage en Côte d’Ivoire, wedding planner, choix de mairies, définition du budget de mariage en Côte d'Ivoire, carnet nuptial, robe de mariage",
        },
      ],
    })
  },
  components: {
    Breadcrumb,
  },
  data: function() {
    return {
      user: JSON.parse(this.$store.state.user),
      customer: null,
      isLoading: false,
      civilities: [
        {
          id: "Monsieur",
          name: "Monsieur",
        },
        {
          id: "Madame",
          name: "Madame",
        },
        {
          id: "Mademoiselle",
          name: "Mademoiselle",
        },
      ],
      datesPlaces: null,
      wedding_date: null,
      wedding_is_here: false,
      wedding_date_number: null,
      wedding_date_surfix: null,
      exactDateType: true,
      customerDOB: null,
    };
  },
  mounted: function() {
    this.getAccountInfo().then((customer) => {
      this.getDatesPlaces(customer.event.id);
    });
  },

  computed: {
    customer_id() {
      return this.$store.state.customer_id;
    },
  },
  methods: {
    getAccountInfo: function() {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        axios
          .get(
            NUPTIAL_NOTEBOOK.TEST_URL +
              API_PREFIX +
              API_VERSION +
              "customers/" +
              this.customer_id,
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            this.customerDOB =
              response.data.dob && response.data.dob != null
                ? moment(response.data.dob).format("DD/MM/YYYY")
                : null;
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log(error.response.data);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    getDatesPlaces: function(eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "dates-places-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.wedding_date =
            response.data[0].pivot.wedding_date == null
              ? null
              : moment(response.data[0].pivot.wedding_date).format(
                  "DD/MM/YYYY"
                );
          this.wedding_is_here =
            response.data[0].pivot.wedding_date != null ? true : false;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    updateAccount: function() {

            let weddingDateFormatted = null;
            if (this.exactDateType == true) {
              // weddingDateFormatted = this.wedding_date
              weddingDateFormatted =
                this.wedding_date != null && this.wedding_is_here == false
                  ? this.wedding_date
                  : null;
              // console.log(weddingDateFormatted)
            } else {
              let currentDate = moment("YYYY-MM-DD");
              let futureDate = moment()
                .add(Number(this.wedding_date_number), this.wedding_date_surfix)
                .calendar();
              weddingDateFormatted =
                this.wedding_date == null && this.wedding_is_here == false
                  ? moment(futureDate, "DD/MM/YYYY").format("YYYY-MM-DD")
                  : null;
            }
            let updatedCustomer = {
              last_name: this.customer.last_name,
              given_names: this.customer.given_names,
              email: this.customer.email,
              phone_number_1: this.customer.phone_number_1,
              phone_number_2: this.customer.phone_number_2,
              dob: moment(this.customer.dob).format("YYYY-MM-DD"),
              civility: this.customer.civility,
              wedding_date: weddingDateFormatted,
              // wedding_date: (this.wedding_date != null) ? moment(this.wedding_date).format('YYYY-MM-DD') : null
            };
            
            this.isLoading = true;
            // console.log(updatedCustomer, 'updatedCustomer')
            axios
              .put(
                NUPTIAL_NOTEBOOK.TEST_URL +
                  API_PREFIX +
                  API_VERSION +
                  "customers/" +
                  this.customer_id,
                updatedCustomer,
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                
                this.isLoading = false;
                this.$createToast(
                    {
                    title: 'Operation reussie !',
                    description: "Infos du compte modifié avec succès!"
                    },
                    {
                    position: 'top-center',
                    type: 'success',
                    timeout: 10000,
                    transition: 'bounce',
                    }
                )
                window.location.href = "/infos-compte-de-mariage-cote-d-ivoire";
              })
              .catch((error) => {
                console.log(error.response.data);
                this.isLoading = false;
                this.$createToast(
                            {
                            title: 'Attention !',
                            description: "Erreur lors de la recuperation des informations!"
                            },
                            {
                            position: 'top-center',
                            type: 'error',
                            timeout: 10000,
                            transition: 'bounce',
                            })
              });
         
    },
  },
};
</script>
