<template>
  <div>
    <HeaderTemplateComponent
      v-if="viewer === 'true'"
      @click-on-pre="clickOnPre"
      @click-on-sui="clickOnSui"
      :nav="nav"
    />

    <!-- <Rythmedelamour :photo_marrieds="photo_marrieds" v-if="code_model_ == 'Rythmedelamour'">

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" style="border-radius:100%;" class="object-fit-cover border rounded-circle w-100 h-100"/>
      </template>

      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" />
      </template>

      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" style="border-radius:100%;" class="object-fit-cover border rounded-circle w-100 h-100"/>
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[1]}
              ${celebrations_1.date[2]} ${celebrations_1.date[3]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-2-place><span v-if="celebrations_1.place === null">Inconnu</span>
        <span v-if="celebrations_1.place != null">{{ celebrations_1.place }}</span></template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[1]}
              ${celebrations_2.date[2]} ${celebrations_2.date[3]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_1.date[3] }}</template>

       <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:given-names-woman>{{ woman.given_names }}</template>

      <template v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>

    </Rythmedelamour> -->

    <!-- <Rayondemiel v-if="code_model_ == 'Rayondemiel'">

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover border rounded-circle w-100 h-100 " />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover border rounded-circle w-100 h-100 " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover border rounded-circle w-100 h-100 " />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>

    </Rayondemiel> -->

    <!-- <Tendres v-if="code_model_ == 'Tendres'">

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover" style="max-width: 100%;
            height: auto; " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    </Tendres> -->

    <!-- <Romance v-if="code_model_=='Romance'" :photo_marrieds="photo_marrieds">
      

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover" style="max-width: 100%;
            height: auto; " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    
    </Romance> -->
    <!-- <Cantiquedescantiques v-if="code_model_ == 'Cantiquedescantiques'">

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover" style="max-width: 100%;
            height: auto; " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    </Cantiquedescantiques> -->
    <!-- <Clairdelune v-if="code_model_=='Clairdelune'" :photo_marrieds="photo_marrieds">
      

      <template v-slot:photo_fiance>
        <img :src="photo_fiance"  style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="" style="width:100%;height:100%" />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee"  style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    
    </Clairdelune> -->

    <!-- <Doucesrejouissances v-if="code_model_ == 'Doucesrejouissances'">

      <template v-slot:photo_fiance>
          <img :src="photo_fiance" style="border-radius:100%;" class="object-fit-cover border rounded-circle w-100 h-100" />
        </template>
        <template v-slot:photo_marrieds>
              <img :src="photo_marrieds" style="max-width: 100%;
              height: auto;" />
        </template>
        <template v-slot:photo_fiancee>
          <img :src="photo_fiancee" style="border-radius:100%;" class="object-fit-cover border rounded-circle w-100 h-100" />
        </template>
  
        <template v-slot:celebrations-1-date>{{`${celebrations_1.date[0]},${celebrations_1.date[3]} ${celebrations_1.date[2]} ${celebrations_1.date[1]}`}}</template>
        <template v-slot:celebrations-1-name>{{celebrations_1.name}}</template>
        <template v-slot:celebrations-1-time>{{celebrations_1.time}}</template>
        <template v-slot:celebrations-1-place>{{celebrations_1.place}}</template>
        <template v-slot:celebrations-1-day-week>{{celebrations_1.date[0]}}</template>
        <template v-slot:celebrations-1-day-num>{{celebrations_1.date[1]}}</template>
        <template v-slot:celebrations-1-month>{{celebrations_1.date[2]}}</template>
        <template v-slot:celebrations-1-year>{{celebrations_1.date[3]}}</template>
  
        <template v-slot:celebrations-2-name>{{celebrations_2.name}}</template>
        <template v-slot:celebrations-2-time>{{celebrations_1.time}}</template>
        <template v-slot:celebrations-2-place>{{celebrations_1.place}}</template>
        <template v-slot:celebrations-2-date>{{`${celebrations_2.date[0]},${celebrations_2.date[3]} ${celebrations_2.date[2]} ${celebrations_2.date[1]}`}}</template>
        <template v-slot:celebrations-2-day-week>{{celebrations_1.date[0]}}</template>
        <template v-slot:celebrations-2-day-num>{{celebrations_1.date[1]}}</template>
        <template v-slot:celebrations-2-month>{{celebrations_1.date[2]}}</template>
        <template v-slot:celebrations-2-year>{{celebrations_1.date[3]}}</template>
  
        <template v-slot:last-name-woman>{{woman.last_name}}</template>
        <template v-slot:last-name-man>{{man.last_name}}</template>
  
        <template v-slot:thoughts-man>{{ thoughts_man }}</template>
        <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>
        
  
        <template v-slot:given-names-woman>{{woman.given_names}}   </template>  <template v-slot:given-names-man>{{man.given_names}}</template>
  
        <template v-slot:email-man>{{man.email}}</template>
        <template v-slot:email-woman>{{woman.email}}</template>
  
        <template v-slot:phone-man>{{man.phone_number_1}}</template>
        <template v-slot:phone-woman>{{woman.phone_number_1}}</template>
  
        <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>    
        </template>



    </Doucesrejouissances> -->
    <!-- <Douxrayons v-if="code_model_=='Douxrayons'" :photo_marrieds="photo_marrieds">
      

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover" style="max-width: 100%;
            height: auto; " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    
    </Douxrayons> -->
    <!-- <Sentimentaux v-if="code_model_=='Sentimentaux'" :photo_marrieds="photo_marrieds">
      

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>
      <template v-slot:photo_marrieds>
        <img :src="photo_marrieds" class="object-fit-cover" style="max-width: 100%;
            height: auto; " />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" class="object-fit-cover" style="max-width: 100%;
        height: auto;" />
      </template>

      <template v-slot:celebrations-1-date>{{ `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}` }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{ celebrations_1.place }}</template>
      <template v-slot:celebrations-1-day-week>{{ celebrations_1.date[0] }}</template>
      <template v-slot:celebrations-1-day-num>{{ celebrations_1.date[1] }}</template>
      <template v-slot:celebrations-1-month>{{ celebrations_1.date[2] }}</template>
      <template v-slot:celebrations-1-year>{{ celebrations_1.date[3] }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{ celebrations_2.place }}</template>
      <template v-slot:celebrations-2-date>{{ `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}` }}</template>
      <template v-slot:celebrations-2-day-week>{{ celebrations_2.date[0] }}</template>
      <template v-slot:celebrations-2-day-num>{{ celebrations_2.date[1] }}</template>
      <template v-slot:celebrations-2-month>{{ celebrations_2.date[2] }}</template>
      <template v-slot:celebrations-2-year>{{ celebrations_2.date[3] }}</template>

      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>


      <template v-slot:given-names-woman>{{ woman.given_names }} </template> <template
        v-slot:given-names-man>{{ man.given_names }}</template>

      <template v-slot:email-man>{{ man.email }}</template>
      <template v-slot:email-woman>{{ woman.email }}</template>

      <template v-slot:phone-man>{{ man.phone_number_1 }}</template>
      <template v-slot:phone-woman>{{ woman.phone_number_1 }}</template>

      <template v-slot:images>
         <div class="masonry-wrapper">
            <div class="masonry">
              <div class="block rounded m-1" v-for="photo in album_photos" :key="photo">
                <div class="image-mask">
                  <img :src="photo" alt="" />
                </div>
                <div class="overlay">
                    <div class="inner-overlay">
                          <p class="description">Beau mariage !</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </template>
    
    </Sentimentaux> -->
    <!-- <Doucesrejouissances> </Doucesrejouissances> -->
    <!-- <Douxrayons v-if="code_model_ === 4">
      <template v-slot:photo_fiance>
        <img :src="photo_fiance" alt="" />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" alt="" />
      </template>

      <template v-slot:photo_fiances>
        <div class="wpo-hero-img-wrap">
          <div v-if="typeof photo_fiances == 'object'" class="row">
            <template v-slot:photo_fiances_1>
              <img :src="photo_fiances[0]" alt="" />
            </template>
            <div
              class="col col-lg-4 col-md-4 col-sm-6 col-12"
              data-v-56dddac8=""
              v-for="photo in photo_fiances"
            >
              <div
                class="wpo-hero-img zoomIn"
                data--duration="1200ms"
                data-v-56dddac8=""
              >
                <img :src="photo" alt="" data-v-56dddac8="" />
              </div>
            </div>
            <div
                class="col col-lg-4 col-md-4 col-sm-6 col-12"
                style="overflow: hidden"
                v-for="photo in photo_fiances"
              >
                <div
                  class="wpo-hero-img zoomIn"
                  data--duration="1700ms"
                >
                  <img :src="photo" alt="" />
                </div>
              </div>
          </div>
          <div v-else>
            <div class="col col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="wpo-hero-img fadeInLeftSlow" data--duration="1700ms">
                <img :src="photo_fiances" alt="" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:given-names-woman>{{ woman.given_names }} </template>
      <template v-slot:given-names-man>{{ man.given_names }}</template>
      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>

      <template v-slot:celebrations-1-date>{{
        `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}`
      }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{
        celebrations_1.place
      }}</template>
      <template v-slot:celebrations-1-day-week>{{
        celebrations_1.date[0]
      }}</template>
      <template v-slot:celebrations-1-day-num>{{
        celebrations_1.date[1]
      }}</template>
      <template v-slot:celebrations-1-month>{{
        celebrations_1.date[2]
      }}</template>
      <template v-slot:celebrations-1-year>{{
        celebrations_1.date[3]
      }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{
        celebrations_2.place
      }}</template>
      <template v-slot:celebrations-2-date>{{
        `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}`
      }}</template>
      <template v-slot:celebrations-2-day-week>{{
        celebrations_2.date[0]
      }}</template>
      <template v-slot:celebrations-2-day-num>{{
        celebrations_2.date[1]
      }}</template>
      <template v-slot:celebrations-2-month>{{
        celebrations_2.date[2]
      }}</template>
      <template v-slot:celebrations-2-year>{{
        celebrations_2.date[3]
      }}</template>

      <template v-slot:images>
        <ImagesGrid
          :cols="3"
          :images="album_photos"
          :image-style="{ width: '300px', marginBottom: '10px' }"
          :is-responsive="true"
          col-spaces="20px"
          object-fit="cover"
          @onImageClick="onImageClick"
          @onImageMouseover="onImageMouseover"
          @onImageMouseout="onImageMouseout"
        />
      </template>

      <template v-slot:footer-jdj>
        <div class="wpo-site-footer text-center">
          <div class="container">
            <div class="row">
              <div class="col-12">
  
              </div>
              <div class="col-12">
                <div class="copyright">
                  <p>
                    © Copyright 2022 | <a href="index.html">Joursdejoie</a> |
                    All right reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Douxrayons> -->

    <CustomerNuptialNotebookGuestsCreate
      v-if="isMaking === 'false'"
      :eventId="eventId"
    />

    <Romance v-if="code_model_ === 4">
      <template v-slot:photo_fiance>
        <img :src="photo_fiance" alt="" />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" alt="" />
      </template>

      <template v-slot:our_first_meet_date>
        {{
          story === null ? null : this.realDateStory(story.our_first_meet_date)
        }}
      </template>
      <template v-slot:our_first_meet_content>
        {{ story === null ? null : story.our_first_meet_content }}
      </template>
      <template v-slot:our_first_date_content>
        {{ story === null ? null : story.our_first_date_content }}
      </template>
      <template v-slot:our_first_date_date>
        {{
          story === null ? null : this.realDateStory(story.our_first_date_date)
        }}
      </template>
      <template v-slot:she_said_yes_date>
        {{
          story === null ? null : this.realDateStory(story.she_said_yes_date)
        }}
      </template>
      <template v-slot:she_said_yes_content>
        {{ story === null ? null : story.she_said_yes_content }}
      </template>

      <template v-slot:photo_fiances>
        <div class="wpo-hero-img-wrap">
          <div v-if="typeof photo_fiances == 'object'" class="row">
            <div
              class="col col-lg-4 col-md-4 col-sm-6 col-12"
              data-v-56dddac8=""
              v-for="photo in photo_fiances"
            >
              <div
                class="wpo-hero-img zoomIn"
                data--duration="1200ms"
                data-v-56dddac8=""
              >
                <img :src="photo" alt="" data-v-56dddac8="" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="col col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="wpo-hero-img fadeInLeftSlow" data--duration="1700ms">
                <img :src="photo_fiances" alt="" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:given-names-woman>{{ woman.given_names }} </template>
      <template v-slot:given-names-man>{{ man.given_names }}</template>
      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>

      <template v-slot:photo_celebrations_1>
        <img :src="photo_celebrations_1" alt="" />
      </template>
      <template v-slot:photo_celebrations_2>
        <img :src="photo_celebrations_2" alt="" />
      </template>
      <template v-slot:photo_celebrations_3>
        <img :src="photo_celebrations_3" alt="" />
      </template>

      <template v-slot:celebrations-1-date>{{
        `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}`
      }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{
        celebrations_1.place
      }}</template>
      <template v-slot:celebrations-1-day-week>{{
        celebrations_1.date[0]
      }}</template>
      <template v-slot:celebrations-1-day-num>{{
        celebrations_1.date[1]
      }}</template>
      <template v-slot:celebrations-1-month>{{
        celebrations_1.date[2]
      }}</template>
      <template v-slot:celebrations-1-year>{{
        celebrations_1.date[3]
      }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{
        celebrations_2.place
      }}</template>
      <template v-slot:celebrations-2-date>{{
        `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}`
      }}</template>
      <template v-slot:celebrations-2-day-week>{{
        celebrations_2.date[0]
      }}</template>
      <template v-slot:celebrations-2-day-num>{{
        celebrations_2.date[1]
      }}</template>
      <template v-slot:celebrations-2-month>{{
        celebrations_2.date[2]
      }}</template>
      <template v-slot:celebrations-2-year>{{
        celebrations_2.date[3]
      }}</template>

      <template v-slot:celebrations-3-name>{{ celebrations_3.name }}</template>
      <template v-slot:celebrations-3-time>{{ celebrations_3.time }}</template>
      <template v-slot:celebrations-3-place>{{
        celebrations_3.place
      }}</template>
      <template v-slot:celebrations-3-date>{{
        `${celebrations_3.date[0]},${celebrations_3.date[3]}
              ${celebrations_3.date[2]} ${celebrations_3.date[1]}`
      }}</template>
      <template v-slot:celebrations-3-day-week>{{
        celebrations_3.date[0]
      }}</template>
      <template v-slot:celebrations-3-day-num>{{
        celebrations_3.date[1]
      }}</template>
      <template v-slot:celebrations-3-month>{{
        celebrations_3.date[2]
      }}</template>
      <template v-slot:celebrations-3-year>{{
        celebrations_3.date[3]
      }}</template>
      <template v-slot:our_first_date_photo>
        <img :src="photo_story_our_first_date_photo" alt="" />
      </template>
      <template v-slot:she_said_yes_photo>
        <img :src="photo_story_she_said_yes_photo" alt="" />
      </template>
      <template v-slot:our_first_meet_photo>
        <img :src="photo_story_our_first_meet_photo" alt="" />
      </template>

      <template v-slot:images>
        <div class="main">
          <div v-for="(item, index) in album_photos" class="article">
            <img :src="item.src" alt="" />
          </div>
        </div>
      </template>

      <template v-slot:></template>
    </Romance>

    <Sentimentaux v-if="code_model_ === 5">
      <template v-slot:photo_celebrations_1>
        <img :src="photo_celebrations_1" alt="" />
      </template>
      <template v-slot:photo_celebrations_2>
        <img :src="photo_celebrations_2" alt="" />
      </template>
      <template v-slot:photo_celebrations_3>
        <img :src="photo_celebrations_3" alt="" />
      </template>

      <template v-slot:photo_fiance>
        <img :src="photo_fiance" alt="" />
      </template>
      <template v-slot:photo_fiancee>
        <img :src="photo_fiancee" alt="" />
      </template>

      <template v-slot:our_first_meet_content>
        {{ story === null ? null : story.our_first_meet_content }}
      </template>
      <template v-slot:our_first_date_content>
        {{ story === null ? null : story.our_first_date_content }}
      </template>
      <template v-slot:our_first_date_date>
        {{
          story === null ? null : this.realDateStory(story.our_first_date_date)
        }}
      </template>
      <template v-slot:she_said_yes_date>
        {{
          story === null ? null : this.realDateStory(story.she_said_yes_date)
        }}
      </template>
      <template v-slot:she_said_yes_content>
        {{ story === null ? null : story.she_said_yes_content }}
      </template>

      <template v-slot:photo_fiances>
        <div class="wpo-hero-img-wrap">
          <div v-if="typeof photo_fiances == 'object'" class="row">
            <div
              class="col col-lg-4 col-md-4 col-sm-6 col-12"
              data-v-56dddac8=""
              v-for="photo in photo_fiances"
            >
              <div
                class="wpo-hero-img zoomIn"
                data--duration="1200ms"
                data-v-56dddac8=""
              >
                <img :src="photo" alt="" data-v-56dddac8="" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="col col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="wpo-hero-img fadeInLeftSlow" data--duration="1700ms">
                <img :src="photo_fiances" alt="" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:given-names-woman>{{ woman.given_names }} </template>
      <template v-slot:given-names-man>{{ man.given_names }}</template>
      <template v-slot:last-name-woman>{{ woman.last_name }}</template>
      <template v-slot:last-name-man>{{ man.last_name }}</template>

      <template v-slot:thoughts-man>{{ thoughts_man }}</template>
      <template v-slot:thoughts-woman>{{ thoughts_woman }}</template>

      <template v-slot:celebrations-1-date>{{
        `${celebrations_1.date[0]},${celebrations_1.date[3]}
              ${celebrations_1.date[2]} ${celebrations_1.date[1]}`
      }}</template>
      <template v-slot:celebrations-1-name>{{ celebrations_1.name }}</template>
      <template v-slot:celebrations-1-time>{{ celebrations_1.time }}</template>
      <template v-slot:celebrations-1-place>{{
        celebrations_1.place
      }}</template>
      <template v-slot:celebrations-1-day-week>{{
        celebrations_1.date[0]
      }}</template>
      <template v-slot:celebrations-1-day-num>{{
        celebrations_1.date[1]
      }}</template>
      <template v-slot:celebrations-1-month>{{
        celebrations_1.date[2]
      }}</template>
      <template v-slot:celebrations-1-year>{{
        celebrations_1.date[3]
      }}</template>

      <template v-slot:celebrations-2-name>{{ celebrations_2.name }}</template>
      <template v-slot:celebrations-2-time>{{ celebrations_2.time }}</template>
      <template v-slot:celebrations-2-place>{{
        celebrations_2.place
      }}</template>
      <template v-slot:celebrations-2-date>{{
        `${celebrations_2.date[0]},${celebrations_2.date[3]}
              ${celebrations_2.date[2]} ${celebrations_2.date[1]}`
      }}</template>
      <template v-slot:celebrations-2-day-week>{{
        celebrations_2.date[0]
      }}</template>
      <template v-slot:celebrations-2-day-num>{{
        celebrations_2.date[1]
      }}</template>
      <template v-slot:celebrations-2-month>{{
        celebrations_2.date[2]
      }}</template>
      <template v-slot:celebrations-2-year>{{
        celebrations_2.date[3]
      }}</template>

      <template v-slot:celebrations-3-name>{{ celebrations_3.name }}</template>
      <template v-slot:celebrations-3-time>{{ celebrations_3.time }}</template>
      <template v-slot:celebrations-3-place>{{
        celebrations_3.place
      }}</template>
      <template v-slot:celebrations-3-date>{{
        `${celebrations_3.date[0]},${celebrations_3.date[3]}
              ${celebrations_3.date[2]} ${celebrations_3.date[1]}`
      }}</template>
      <template v-slot:celebrations-3-day-week>{{
        celebrations_3.date[0]
      }}</template>
      <template v-slot:celebrations-3-day-num>{{
        celebrations_3.date[1]
      }}</template>
      <template v-slot:celebrations-3-month>{{
        celebrations_3.date[2]
      }}</template>
      <template v-slot:celebrations-3-year>{{
        celebrations_3.date[3]
      }}</template>

      <template v-slot:images>
        <div class="main">
          <div v-for="(item, index) in album_photos" class="article">
            <img :src="item.src" alt="" />
          </div>
        </div>
      </template>

      <template v-slot:our_first_date_photo>
        <img :src="photo_story_our_first_date_photo" alt="" />
      </template>
      <template v-slot:she_said_yes_photo>
        <img :src="photo_story_she_said_yes_photo" alt="" />
      </template>
      <template v-slot:our_first_meet_photo>
        <img :src="photo_story_our_first_meet_photo" alt="" />
      </template>
    </Sentimentaux>

    <v-paginator
      resource_url="api/animals"
      @update="updateResource"
    ></v-paginator>
  </div>
</template>

<script>
import {
  NUPTIAL_NOTEBOOK,
  AUTH,
  API_PREFIX,
  API_VERSION,
  JOYCARD, //
  IMG_API_URL,
} from "@/config";

import axios from "axios";
import store from "@/store";
import "vue-final-modal/style.css";

import HeaderTemplateComponent from "@/components/communication/HeaderTemplateComponent.vue";
import { VueMasonryPlugin } from "vue-masonry";
import CustomerNuptialNotebookGuestsCreate from "@/views/nuptial-notebook/guests/create.vue";

// import Rythmedelamour from "@/views/templates/Rythmedelamour/index.vue";
// import Rayondemiel from "@/views/templates/Rayondemiel/index.vue";
// import Tendres from "@/views/templates/Tendres/index.vue";
import Romance from "@/views/templates/Romance/index.vue";
// import Cantiquedescantiques from "@/views/templates/Cantiquedescantiques/index.vue";
// import Clairdelune from "@/views/templates/Clairdelune/index.vue";
// import Doucesrejouissances from "@/views/templates/Doucesrejouissances/index.vue";
// import Douxrayons from "@/views/templates/Douxrayons/index.vue";
import Sentimentaux from "@/views/templates/Sentimentaux/index.vue";

export default {
  name: "SitewebViewerComponent",
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data: function () {
    return {
      items: [
        {
          title: "First",
          description: "The first item.",
        },
        {
          title: "Second",
          description: "The second item.",
        },
      ],
      user_id: null,
      data: null,
      user: JSON.parse(store.state.user),
      code_model_: "",
      photo_fiance: "",
      photo_fiancee: "",
      photo_fiances: [],
      man: "",
      woman: "",
      photo_story_our_first_meet_photo: "",
      photo_story_our_first_date_photo: "",
      photo_story_she_said_yes_photo: "",
      photo_celebrations_1: "",
      photo_celebrations_2: "",
      photo_celebrations_3: "",
      story: {
        our_first_meet_date: "",
        our_first_date_date: "",
        she_said_yes_date: "",
        our_first_meet_content: "",
        our_first_date_content: "",
        she_said_yes_content: "",
      },
      celebrations_1: {
        name: "",
        date: [],
        place: "",
      },
      celebrations_2: {
        name: "",
        date: [],
        place: "",
        time: "",
      },
      eventId: null,
      celebrations_3: {
        name: "",
        date: [],
        place: "",
        time: "",
      },
      album_photos: [],
      items: [0, 1, 2, 3, 4],
      dateComponents: {},
      thoughts_man: null,
      thoughts_woman: null,
      images: [],
      dialog: null,
    };
  },
  components: {
    // Rythmedelamour,
    // Rayondemiel,
    CustomerNuptialNotebookGuestsCreate,
    HeaderTemplateComponent,
    // Swiper,
    // SwiperSlide,
    // VWave,
    // Tendres,
    Romance,
    // Cantiquedescantiques,
    // Clairdelune,
    // Doucesrejouissances,
    // Douxrayons,
    Sentimentaux,
  },
  beforeMount() {
    
    if (this.isMaking === "false") {
      this.getCustomerInfos();
      this.dataWhenSitewebisViewing();
    } else {
      console.log('CARACTERISTIC USER TEMPLATE IN SITE WEB VIEWER',this.caracteristic_user_template)
      this.dataWhenSitewebIsMaking();
    }
    console.log("IS MAKING");
  },
  props: [
    "name",
    "viewer",
    "isMaking",
    "modelTemplate",
    "caracteristic_user_template",
    "configTemplate",
    "nav",
  ],
  beforeUnmount() {
    // store.commit('setCelebrations')
    // store.commit('setCodeModel')
    // store.commit('setThoughtsHusband');
    // store.commit('setThoughtsWife')
    // store.commit('setNbrPhotos');
    console.log('CARACTERISTIC_USER_TEMPLATE WHEN UNMOUNTING SITEWEB VIEWER',this.caracteristic_user_template)
    this.$emit("Unmount");
  },
  mounted() {
    console.log('CARACTERISTIC_USER_TEMPLATE WHEN MOUNTED SITEWEB VIEWER',this.caracteristic_user_template)
    this.$emit('save-carac',this.caracteristic_user_template)
  },
  methods: {
    openDialog() {
      if (this.dialog) {
        this.dialog.show();
      }
    },
    assignDialogRef(dialog) {
      this.dialog = dialog;
    },
    removeDuplicateObjects: function (arr) {
      const seen = new Set(); // Set to store unique object references
      const uniqueArray = [];

      for (const obj of arr) {
        const key = JSON.stringify(obj); // Create a unique key based on object properties

        if (!seen.has(key)) {
          seen.add(key);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    },
    realDateStory: function (obj) {
      let calendar_month = [
        "Janvier",
        "Fevrier",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
      ];
      return obj === null
        ? ""
        : `${calendar_month[obj.month - 1]} ${obj["year"]}`;
    },
    getCustomerInfos: function () {
      let app = this;
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "customers/" +
            app.$store.state.customer_id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          app.eventId = response.data.id;
          // app.marrieds = response.data.event.marrieds;
          // app.dates = response.data.event.dates_places;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dataWhenSitewebIsMaking: function () {
      let app = this;
      app.code_model_ = parseInt(this.modelTemplate.id_model_templates);
      app.thoughts_man = this.caracteristic_user_template.thoughts_husband;
      app.thoughts_woman = this.caracteristic_user_template.thoughts_wife;
      app.photo_fiance =
        this.caracteristic_user_template.photo_fiance === ""
          ? ""
          : URL.createObjectURL(this.caracteristic_user_template.photo_fiance);
      app.photo_fiancee =
        this.caracteristic_user_template.photo_fiancee === ""
          ? ""
          : URL.createObjectURL(this.caracteristic_user_template.photo_fiancee);
      app.photo_celebrations_1 =
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_1"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_celebrations.find(
                (element) => element.name === "photo_celebrations_1"
              ).value
            );
      app.photo_celebrations_2 =
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_2"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_celebrations.find(
                (element) => element.name === "photo_celebrations_2"
              ).value
            );
      app.photo_celebrations_3 =
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_3"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_celebrations.find(
                (element) => element.name === "photo_celebrations_3"
              ).value
            );

      app.photo_story_our_first_meet_photo =
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_our_first_meet_photo"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_story.find(
                (element) => element.name === "photo_story_our_first_meet_photo"
              ).value
            );
      app.photo_story_our_first_date_photo =
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_our_first_date_photo"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_story.find(
                (element) => element.name === "photo_story_our_first_date_photo"
              ).value
            );
      app.photo_story_she_said_yes_photo =
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_she_said_yes_photo"
        ) === undefined
          ? ""
          : URL.createObjectURL(
              this.caracteristic_user_template.photo_story.find(
                (element) => element.name === "photo_story_she_said_yes_photo"
              ).value
            );

      let multiple_photo_fiances = this.configTemplate.find(
        (element) => element.code === "multiple_photo_fiances"
      ).value;
      multiple_photo_fiances = JSON.parse(multiple_photo_fiances);

      if (multiple_photo_fiances === true) {
        let response = false;
        let index = 1;
        app.photo_fiances = [];
        while (response != true) {
          if (
            this.caracteristic_user_template.photo_fiances.find(
              (element) => element.name === "photo_fiances_" + index
            ) === undefined
          ) {
            response = true;
          } else {
            app.photo_fiances.push(
              URL.createObjectURL(
                this.caracteristic_user_template.photo_fiances.find(
                  (element) => element.name === "photo_fiances_" + index
                ).value
              )
            );
          }
          index++;
        }
      } else {
        app.photo_fiances =
          this.caracteristic_user_template.photo_fiances.length === 0
            ? ""
            : URL.createObjectURL(
                this.caracteristic_user_template.photo_fiances
              );
      }
      app.story =
        this.caracteristic_user_template.story === null
          ? null
          : this.caracteristic_user_template.story;
      // // //CELEBRATIONS*********************************************************************
      let celebrations =
        app.caracteristic_user_template.celebrations != undefined
          ? app.caracteristic_user_template.celebrations
          : null;

      app.celebrations_1.name =
        celebrations != null ? celebrations[0].name : null;
      app.celebrations_1.date =
        celebrations != null
          ? this.getInfosDateEvent(celebrations[0].date)
          : null;
      app.celebrations_1.time =
        celebrations != null ? celebrations[0].time : null;
      app.celebrations_1.place =
        celebrations != null ? celebrations[0].location : null;
      app.celebrations_2.name =
        celebrations != null ? celebrations[1].name : null;
      app.celebrations_2.date =
        celebrations != null
          ? this.getInfosDateEvent(celebrations[1].date)
          : null;
      app.celebrations_2.time =
        celebrations != null ? celebrations[1].time : null;
      app.celebrations_2.place =
        celebrations != null ? celebrations[1].location : null;
      app.celebrations_3.name =
        celebrations != null ? celebrations[2].name : null;
      app.celebrations_3.date =
        celebrations != null
          ? this.getInfosDateEvent(celebrations[2].date)
          : null;
      app.celebrations_3.time =
        celebrations != null ? celebrations[2].time : null;
      app.celebrations_3.place =
        celebrations != null ? celebrations[2].location : null;
      // END CELEBRATIONS******************************************************************
      app.man =
        this.caracteristic_user_template.man === null
          ? null
          : this.caracteristic_user_template.man.pivot;
      app.woman =
        this.caracteristic_user_template.woman === null
          ? null
          : this.caracteristic_user_template.woman.pivot;

      let names_album_photos =
        this.caracteristic_user_template.albumphotos.length === 0
          ? null
          : this.generateAlbumNames(
              this.caracteristic_user_template.albumphotos.length
            );
      if (names_album_photos === null) {
      } else {
        for (var i = 0; i < names_album_photos.length; i++) {
          if (
            names_album_photos[i] ===
            this.caracteristic_user_template.albumphotos[i].name
          ) {
            app.album_photos.push({
              id: i,
              src: URL.createObjectURL(
                this.caracteristic_user_template.albumphotos.find(
                  (element) =>
                    names_album_photos[i] === element.name &&
                    element.value != undefined
                ).value
              ),
            });
          } else {
          }
        }
      }
      // console.log('ALBUMPHOTOS ',app.album_photos)

      // console.log("VIEWER ", this.viewer);
      console.log('CARACTERISTIC USER TEMPLATE IN SITE WEB VIEWER AT THE END OF Datawhensitewebismaking',this.caracteristic_user_template)
    },
    dataWhenSitewebisViewing: function () {
      let app = this;

      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            JOYCARD.FINDURLSITEWEB +
            this.$route.params.url_site_web
        )
        .then((response) => {
          if (typeof response.data === "object") {
            this.display = true;
          }
          app.code_model_ = parseInt(response.data.id_model_templates);
          axios
            .get(
              NUPTIAL_NOTEBOOK.TEST_URL +
                API_PREFIX +
                API_VERSION +
                JOYCARD.FINDCARACBYIDUSERANDIDTEMPLATE +
                response.data.id_user +
                "/" +
                response.data.id_model_templates
            )
            .then((response) => {
              app.data = response.data;

              app.thoughts_man = this.data.find(
                (element) => element.name === "thoughts_man"
              ).value;
              app.thoughts_woman = this.data.find(
                (element) => element.name === "thoughts_woman"
              ).value;
              let celebrations = JSON.parse(
                this.data.find((element) => element.name === "celebrations")
                  .value
              );
              app.celebrations_1.name = celebrations[0].name;
              app.celebrations_1.date = this.getInfosDateEvent(
                celebrations[0].date
              );
              app.celebrations_1.time = celebrations[0].time;
              app.celebrations_1.place = celebrations[0].location;
              app.celebrations_2.name = celebrations[1].name;
              app.celebrations_2.date = this.getInfosDateEvent(
                celebrations[1].date
              );
              app.celebrations_2.time = celebrations[1].time;
              app.celebrations_2.place = celebrations[1].location;

              app.celebrations_3.name = celebrations[2].name;
              app.celebrations_3.date = this.getInfosDateEvent(
                celebrations[2].date
              );
              app.celebrations_3.time = celebrations[2].time;
              app.celebrations_3.place = celebrations[2].location;
              app.man = JSON.parse(
                this.data.find((element) => element.name === "man").value
              );
              app.woman = JSON.parse(
                this.data.find((element) => element.name === "woman").value
              );

              app.story = JSON.parse(
                this.data.find((element) => element.name === "story").value
              );

              app.photo_celebrations_2 =
                this.data.find(
                  (element) => element.name === "photo_celebration_2"
                ).value === undefined
                  ? ""
                  : IMG_API_URL +
                    this.data.find(
                      (element) => element.name === "photo_celebration_2"
                    ).value;

              app.photo_celebrations_1 =
                this.data.find(
                  (element) => element.name === "photo_celebration_1"
                ).value === undefined
                  ? ""
                  : IMG_API_URL +
                    this.data.find(
                      (element) => element.name === "photo_celebration_1"
                    ).value;

              app.photo_celebrations_3 =
                this.data.find(
                  (element) => element.name === "photo_celebration_3"
                ).value === undefined
                  ? ""
                  : IMG_API_URL +
                    this.data.find(
                      (element) => element.name === "photo_celebration_3"
                    ).value;

              app.photo_story_our_first_date_photo =
                IMG_API_URL +
                this.data.find(
                  (element) =>
                    element.name === "photo_story_our_first_date_photo"
                ).value;

              app.photo_story_our_first_meet_photo =
                IMG_API_URL +
                this.data.find(
                  (element) =>
                    element.name === "photo_story_our_first_meet_photo"
                ).value;

              app.photo_story_she_said_yes_photo =
                IMG_API_URL +
                this.data.find(
                  (element) => element.name === "photo_story_she_said_yes_photo"
                ).value;

              app.photo_fiance =
                IMG_API_URL +
                this.data.find((element) => element.name === "photo_fiance")
                  .value;
              console.log(
                "DATA FIND PHOTO FIANCE ",
                this.data.find((element) => element.name === "photo_fiance")
                  .value
              );
              app.photo_fiancee =
                IMG_API_URL +
                this.data.find((element) => element.name === "photo_fiancee")
                  .value;

              let names_album_photos = this.generateAlbumNamesWebViewing(
                parseInt(
                  this.data.find(
                    (element) => element.name === "nbr_album_photo"
                  ).value
                )
              );
              for (var i = 0; i < names_album_photos.length; i++) {
                try {
                  app.album_photos.push({
                    id: i,
                    src:
                      IMG_API_URL +
                      this.data.find(
                        (element) =>
                          element.name ===
                          (i === names_album_photos.length - 1
                            ? names_album_photos[i]
                            : names_album_photos[i + 1])
                      ).value,
                  });
                } catch (e) {
                  console.log(e);
                }
              }

              if (
                this.data.find(
                  (element) => element.name === "nbr_photo_fiances"
                ) != undefined
              ) {
                if (
                  parseInt(
                    this.data.find(
                      (element) => element.name === "nbr_photo_fiances"
                    ).value
                  ) > 0
                ) {
                  let response = false;
                  let index = 1;
                  while (response != true) {
                    if (
                      this.data.find(
                        (element) => element.name === "photo_fiances_" + index
                      ) === undefined
                    ) {
                      response = true;
                    } else {
                      app.photo_fiances.push(
                        IMG_API_URL +
                          this.data.find(
                            (element) =>
                              element.name === "photo_fiances_" + index
                          ).value
                      );
                    }
                    index++;
                  }
                } else {
                  app.photo_fiances =
                    IMG_API_URL +
                    this.data.find(
                      (element) => element.name === "photo_fiances"
                    ).value;
                }
              }
              // console.log("PHOTO FIANCES ", app.photo_fiances);
              // console.log("ALBUM PHOTO ", app.multiple_photo_fiances);
            });
        })
        .catch((error) => {
          app.display = false;
          console.log(error);
        });
    },
    getInfosDateEvent: function (dateString) {
      const joursSemaine = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      const date = new Date(dateString);
      const jourSemaine = joursSemaine[date.getDay()];
      const jourNombre = date.getDate();
      const mois = date.toLocaleString("default", { month: "long" }); // Nom du mois
      const annee = date.getFullYear();

      const resultat = [jourSemaine, jourNombre, mois, annee];
      return resultat;
    },
    getMarrieds: function (eventId) {
      this.isLoading = true;
      axios
        .get(
          NUPTIAL_NOTEBOOK.TEST_URL +
            API_PREFIX +
            API_VERSION +
            "marrieds-by-event/" +
            eventId,
          {
            headers: {
              Authorization: "Bearer " + store.state.token,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.woman = response.data[0];
          this.man = response.data[1];
        })
        .catch((error) => {
          console.log("error", error.response.data);
          this.isLoading = false;
        });
    },
    getCustomerInfo: function () {
      return new Promise((resolve, reject) => {
        let app = this;
        app.isLoading = true;
        this.user_id = app.user.id;
        axios
          .get(
            NUPTIAL_NOTEBOOK.TEST_URL +
              API_PREFIX +
              API_VERSION +
              "customers-by-user/" +
              app.user.id,
            {
              headers: {
                Authorization: "Bearer " + store.state.token,
              },
            }
          )
          .then((response) => {
            this.customer = response.data;
            // if (!this.customer.user.email_verified_at) {
            //   router.push('/inscription-a-terminer/'+2);
            // }
            this.isLoading = false;
            resolve(this.customer);
          })
          .catch((error) => {
            console.log("Error", error);
            this.isLoading = false;
            reject(error);
          });
      });
    },
    clickOnPre: function () {
      this.$emit("return-to-page-choice");
    },
    clickOnSui: function () {
      let app = this;
      let photos = store.getters.getImg;
      let user_id = app.user.id;
      // //Store id_model
      let id_model = app.code_model_;
      let lorem = new FormData();
      let ipsum = new FormData();
      // lorem.append('id_user',user_id)

      lorem.append("story", JSON.stringify(app.story));
      lorem.append(
        "photo_story_our_first_meet_photo",
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_our_first_meet_photo"
        ).value
      );
      lorem.append(
        "photo_story_our_first_date_photo",
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_our_first_date_photo"
        ).value
      );
      lorem.append(
        "photo_story_she_said_yes_photo",
        this.caracteristic_user_template.photo_story.find(
          (element) => element.name === "photo_story_she_said_yes_photo"
        ).value
      );

      lorem.append(
        "nbr_celebrations",
        parseInt(
          this.configTemplate.find(
            (element) => element.code === "nbr_celebrations"
          ).value
        )
      );
      lorem.append(
        "photo_celebration_1",
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_1"
        ).value
      );
      lorem.append(
        "photo_celebration_2",
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_2"
        ).value
      );
      lorem.append(
        "photo_celebration_3",
        this.caracteristic_user_template.photo_celebrations.find(
          (element) => element.name === "photo_celebrations_3"
        ).value
      );

      lorem.append(
        "celebrations",
        JSON.stringify(this.caracteristic_user_template.celebrations)
      );
      lorem.append("id_model_template", id_model);
      lorem.append("id_user", user_id);
      lorem.append("thoughts_woman", app.thoughts_woman);
      lorem.append("thoughts_man", app.thoughts_man);
      lorem.append("man", JSON.stringify(app.man));
      lorem.append("woman", JSON.stringify(app.woman));
      lorem.append("story", JSON.stringify(app.story));

      lorem.append(
        "url_site_web",
        `${app.woman.given_names}et${app.man.given_names}-${id_model}`.toLowerCase()
      );
      // // Chargement des photos et des fichiers dans FormData
      lorem.append(
        "photo_fiancee",
        this.caracteristic_user_template.photo_fiancee
      );
      lorem.append(
        "photo_fiance",
        this.caracteristic_user_template.photo_fiance
      );
      // console.log("PHOTO FIANCES ", app.photo_fiances);
      if (typeof app.photo_fiances == "object") {
        ipsum.append("nbr_photo_fiances", app.photo_fiances.length);

        this.caracteristic_user_template.photo_fiances.forEach(
          (element, index) => {
            console.log("ELEMENT", element);
            if (element.name.includes("photo_fiances_") === true) {
              ipsum.append(
                "photo_fiances_" + (index + 1),
                this.caracteristic_user_template.photo_fiances.find(
                  (element) => element.name === `photo_fiances_${index + 1}`
                ).value
              );
            } else {
            }
          }
        );
      } else {
        lorem.append("nbr_photo_fiances", "0");
        lorem.append(
          "photo_fiances",
          this.caracteristic_user_template.photo_fiances
        );
      }

      lorem.append("given_name_woman", app.woman.given_names);
      lorem.append("given_name_man", app.man.given_names);

      let nbr = this.caracteristic_user_template.albumphotos.length;
      ipsum.append("nbr_album_photo", nbr);
      ipsum.append("id_model_template", id_model);
      ipsum.append("id_user", user_id);
      ipsum.append(
        "url_site_web",
        `${app.woman.given_names}et${app.man.given_names}-${id_model}`.toLowerCase()
      );

      let url_site_web =
        `${app.woman.given_names}et${app.man.given_names}-${id_model}`.toLowerCase();
      nbr--;
      while (nbr > 0) {
        ipsum.append(
          `albumphoto_${nbr}`,
          this.caracteristic_user_template.albumphotos.find(
            (element) => element.name === `albumphoto_${nbr}`
          ).value
        );
        nbr--;
      }
      this.$emit("go-to-channel-choice", {
        lorem,
        ipsum,
        url_site_web,
        category:this.caracteristic_user_template.category,
        category_id: this.caracteristic_user_template.category.id,
      });
      // axios
      //   .post(JOYCARD.POSTCARACTERISTICTEMPLATE, ipsum, {
      //     "content-type": "multipart/form-data",
      //   })
      //   .then((res) => {
          // console.log("Reponses :", res);
      //   })
      //   .catch((err) => {
          // console.log(err);
      //   });

      // axios
      //   .post(JOYCARD.POSTCARACTERISTICTEMPLATE, lorem, {
      //     "content-type": "multipart/form-data",
      //   })
      //   .then((res) => {
          // console.log("Reponses :", res);
      //   })
      //   .catch((err) => {
          // console.log(err);
      //   });

      this.$emit("go-to-target-choice");
    },
    getDateComponents: function (dateString) {
      const [day, month, year] = dateString.split("-").map(Number);

      return {
        day: day,
        month: month,
        year: year,
      };
    },
    formatDateToLiteral: function (dateStr) {
      const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [year, month, day] = dateStr.split("-");
      const formattedDate = `Le ${parseInt(day, 10)} ${
        months[parseInt(month, 10) - 1]
      } ${year}`;

      return formattedDate;
    },
    generateAlbumNames: function (maxNumber) {
      const prefix = "albumphoto_";
      const albumNames = [];

      for (let i = 1; i <= maxNumber; i++) {
        const albumName = prefix + i;
        albumNames.push(albumName);
      }

      return albumNames;
    },
    generateAlbumNamesWebViewing: function (maxNumber) {
      const prefix = "albumphoto_";
      const albumNames = [];

      for (let i = 1; i <= maxNumber; i++) {
        const albumName = prefix + i;
        albumNames.push(albumName);
      }

      return albumNames;
    },
    getDayOfWeek: function (dateString) {
      const [day, month, year] = dateString.split("-").map(Number);

      // Créer un objet Date en utilisant l'année, le mois (0-indexé) et le jour
      const date = new Date(year, month - 1, day);

      // Tableau des noms de jours de la semaine
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      // Récupérer le numéro du jour de la semaine (0 pour Dimanche, 1 pour Lundi, etc.)
      const dayOfWeekIndex = date.getDay();

      // Utiliser le numéro pour obtenir le nom du jour de la semaine correspondant
      const dayOfWeek = daysOfWeek[dayOfWeekIndex];

      return dayOfWeek;
    },
    formatDateToCustomArray: function (dateStr) {
      const daysOfWeek = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

      const [year, month, day] = dateStr.split("-");
      const dateObj = new Date(`${year}-${month}-${day}`);

      const formattedArray = [
        daysOfWeek[dateObj.getUTCDay()],
        day,
        months[parseInt(month, 10) - 1],
        year,
      ];

      return formattedArray;
    },
  },
};
</script>
<style scoped>
.main {
  columns: 450px;
  column-gap: 20px;
}
.article {
  break-inside: avoid-column;
  margin-bottom: 1rem;
}
</style>
