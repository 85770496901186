<template>
  <div class="flex flex-col w-full" v-if="comments">
    <div class="flex py-4 w-full" v-for="(comment, key) in comments" :key="key">
      <comment-item-component @comment-deleted="onDelete" :comment="comment"></comment-item-component>
    </div>
    <div class="flex" v-if="pagination && comments">
      <pagination-component :pagination="pagination" @paginate="onChangePage"></pagination-component>
    </div>
  </div>
</template>

<script>
import CommentItemComponent from "@/views/wedding-provider/components/comment/comment-item";
export default {
  name: "comment-list-component",
  components: {
    CommentItemComponent,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    }
  },
  computed: {
    pagination() {
      if(!this.comments) {
        return null;
      }
      return {
        currentPage: this.comments.current_page,
        from: this.comments.from,
        to: this.comments.to,
        total: this.comments.total,
        lastPage: this.comments.last_page,
        perPage: this.comments.per_page,
      }
    }
  },
  methods: {
    onChangePage(currentPage) {
      this.$emit('comment-list-paginate', currentPage);
    },
    onDelete(id) {
      this.$emit('comment-item-deleted', id);
    }
  }
}
</script>
